import { Component, ViewChild, AfterViewChecked, AfterContentChecked, OnDestroy, AfterViewInit } from '@angular/core';
import { CalendarComponent } from '@app/_layers/calendar/calendar.component';
import * as moment from 'moment';
import { Moment } from 'moment';
import { BpijService } from '@app/_services/bpij.service';
import { GestipService } from '@app/_services/gestip.service';
import { BpijBenefitsTableComponent } from './bpij-benefits-table/bpij-benefits-table.component';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CalendarService } from '@app/_layers/calendar/calendar.service';
import { MatCheckboxChange } from '@angular/material';
import { PrestijDataService } from './services/prestij-data.service';
import { NirSirenValidationHelper } from '@app/_core/helpers';
import { GestipOperationsTableComponent } from './gestip-operations-table/gestip-operations-table.component';

export interface SearchCachedField {
  type: any,
  nirOrSiren: string,
  from: Moment,
  to: Moment
}

@Component({
  selector: 'app-prestij-data',
  templateUrl: './prestij-data.component.html',
  styleUrls: ['./prestij-data.component.scss'],
  providers: [CalendarService, PrestijDataService]
})

export class PrestijDataComponent implements AfterViewInit, AfterViewChecked, AfterContentChecked, OnDestroy {
  @ViewChild('fromCalendar', { static: false }) fromCalendar: CalendarComponent;
  @ViewChild('toCalendar', { static: false }) toCalendar: CalendarComponent;
  @ViewChild('bpijBenefitsTable', { static: false }) bpijBenefitsTable: BpijBenefitsTableComponent;
  @ViewChild('gestipOperationsTable', { static: false }) gestipOperationsTable: GestipOperationsTableComponent;

  public TODAY = CalendarComponent.TODAY;
  public YESTERDAY = CalendarComponent.YESTERDAY;
  readonly NIR = NirSirenValidationHelper.NIR;
  readonly SIREN = NirSirenValidationHelper.SIREN;
  readonly ALL = NirSirenValidationHelper.ALL;

  private pageSize: number = 10;
  private pageNumber: number = 1;
  public bpijSearchOptions = [
    NirSirenValidationHelper.NIR,
    NirSirenValidationHelper.SIREN_OR_SIRET,
    NirSirenValidationHelper.ALL
  ];
  public searchOptions = [
    NirSirenValidationHelper.NIR,
    NirSirenValidationHelper.SIREN
  ];;
  public type = this.bpijSearchOptions[0];

  public bpijSelected = true;
  public gestipSelected = false;
  private bpijCachedField: SearchCachedField = { type: null, nirOrSiren: null, from: null, to: null };
  private gestipCachedField: SearchCachedField = { type: null, nirOrSiren: null, from: null, to: null };
  private currentCachedField: SearchCachedField = { type: null, nirOrSiren: null, from: null, to: null };

  public typeAllSelected = false;
  public nirOrSirenNumber: string;
  public nirOrSirenError: string;
  public displayTechnicalData = false;
  public errFrom;
  public errTo;
  public exportBtnEnabled = false;
  public hideHelp = false;
  public hasResults = true;
  public hasErrors = false;

  private destroyed: Subject<boolean> = new Subject<boolean>();

  constructor(private bpijService: BpijService, private gestipService: GestipService, public dateRange: CalendarService, private prestijDataService: PrestijDataService) {
  }

  ngAfterViewInit() {
    this.fromCalendar.dateChange.pipe(takeUntil(this.destroyed), debounceTime(200)).subscribe(() => {
      if (this.typeAllSelected) {
        const from = moment(this.fromCalendar.date).add(14, "days");

        if (this.fromCalendar.date && this.toCalendar.date > from)
          this.toCalendar.date = moment(from.clone());
      }
      this.loadData();
    });
    this.toCalendar.dateChange.pipe(takeUntil(this.destroyed), debounceTime(200)).subscribe(() => {
      if (this.typeAllSelected) {
        const to = moment(this.toCalendar.date).subtract(14, "days");

        if (this.toCalendar.date && this.fromCalendar.date < to)
          this.fromCalendar.date = moment(to.clone());
      }
      this.loadData();
    });
  }

  ngAfterContentChecked() {
    if (!this.fromCalendar) { return };
    this.errFrom = this.fromCalendar.error;
    this.errTo = this.toCalendar.error;
  }

  ngAfterViewChecked() {
    this.dateRange.setCalendars(this.fromCalendar, this.toCalendar);
  }

  onDisplayTechnicalDataChecked(event: MatCheckboxChange) {
    this.displayTechnicalData = event.checked;
    this.loadData();
  }

  onNirOrSirenChange(event) {
    if (NirSirenValidationHelper.check(this.nirOrSirenNumber, this.type) == null) {
      this.loadData();
    }
  }

  onTypeChange(event) {
    this.type = event;
    this.clearNumber();
    this.hasErrors = false;

    if (this.isTypeAll(this.type) && this.bpijSelected) {
      this.typeAllSelected = true;
      this.setSearchCacheFields(this.bpijCachedField, this.type, null, null, null);

      this.toCalendar.date = moment(CalendarComponent.YESTERDAY);
      this.fromCalendar.date = moment(CalendarComponent.YESTERDAY).subtract(7, "days");
      this.dateRange.setDefaultCalendarBoundariesToTodayMinusOne();

      this.loadData();
      return;
    }
    else {
      this.typeAllSelected = false;
      this.dateRange.setDefaultCalendarBoundaries();
    }

    this.nirOrSirenNumber = this.isNir() ? NirSirenValidationHelper.formatNir(this.nirOrSirenNumber) : NirSirenValidationHelper.formatSiret(this.nirOrSirenNumber);
    if (this.nirOrSirenNumber.length > 0) {
      this.nirOrSirenError = NirSirenValidationHelper.check(this.nirOrSirenNumber, this.type);
    }
    if (this.isNir()) {
      this.loadData();
      return;
    }

    const from = this.fromCalendar.date;
    const to = this.toCalendar.date;
    if (from && !to)
      this.toCalendar.date = moment(this.TODAY);
    else if (!from && to)
      this.fromCalendar.date = to.clone().add(-7, "days");
    else if (!from && !to) {
      this.fromCalendar.date = moment(this.TODAY).clone().add(-7, "days");
      this.toCalendar.date = moment(this.TODAY);
    }
    else
      this.loadData();
  }

  onBlur(event) {
    var input = (<HTMLInputElement>event.target).value;
    this.nirOrSirenError = NirSirenValidationHelper.check(input, this.type);
  }

  onGestipClick() {
    this.bpijSelected = false;
    this.gestipSelected = true;
    this.pageNumber = 1;
    this.pageSize = 10;
    this.gestipOperationsTable.paginator.firstPage();

    this.searchOptions = [NirSirenValidationHelper.NIR, NirSirenValidationHelper.SIREN];
    if (this.type === NirSirenValidationHelper.SIREN_OR_SIRET)
      this.type = NirSirenValidationHelper.SIREN;
    this.hideHelp = this.exportBtnEnabled = false;
    this.clearNumber();

    this.hasErrors = false;
    if (this.typeAllSelected) {
      this.typeAllSelected = false;
      this.resetGestipType();
      this.dateRange.setDefaultCalendarBoundaries();
    }
    this.loadData();
  }

  onBpijClick() {
    this.bpijSelected = true;
    this.gestipSelected = false;
    this.pageNumber = 1;
    this.pageSize = 10;
    this.bpijBenefitsTable.paginator.firstPage();

    this.searchOptions = this.bpijSearchOptions;

    const defaultType = this.type === NirSirenValidationHelper.SIREN ? NirSirenValidationHelper.SIREN_OR_SIRET : this.searchOptions[0];
    this.type = this.bpijCachedField.type ? this.bpijCachedField.type : defaultType;
    this.typeAllSelected = this.isTypeAll(this.type);
    this.typeAllSelected ? this.dateRange.setDefaultCalendarBoundariesToTodayMinusOne() : this.dateRange.setDefaultCalendarBoundaries();

    this.clearNumber();
    this.loadData();
  }

  onExportClick() {
    this.currentCachedField = this.bpijSelected ? this.bpijCachedField : this.gestipCachedField;

    if (this.typeAllSelected || this.isTypeAll(this.currentCachedField.type)) {
      this.prestijDataService.exportBpijBenefitsFromReceiptDates(null, null, this.bpijCachedField.from, this.bpijCachedField.to);
      return;
    }

    if (this.bpijSelected) {
      this.prestijDataService.exportBpijBenefits(this.getNirValue(this.bpijCachedField.nirOrSiren), this.getSirenValue(this.bpijCachedField.nirOrSiren), this.bpijCachedField.from, this.bpijCachedField.to);
    } else if (this.gestipSelected) {
      this.prestijDataService.exportGestipOperations(this.getNirValue(this.gestipCachedField.nirOrSiren), this.getSirenValue(this.gestipCachedField.nirOrSiren), this.gestipCachedField.from, this.gestipCachedField.to);
    }
  }

  onPageIndexChange(index: number) {
    this.pageNumber = index;
    this.loadData();
  }

  onPageSizeChange(pageSize: number) {
    this.pageSize = pageSize;
    this.loadData();
  }

  isNir(): boolean {
    return this.type == NirSirenValidationHelper.NIR;
  }

  private isTypeAll(type: any): boolean {
    return type == NirSirenValidationHelper.ALL;
  }

  private isSiretOrSiren(): boolean {
    return this.type == NirSirenValidationHelper.SIREN || this.type == NirSirenValidationHelper.SIREN_OR_SIRET;
  }

  getMask(type: any): (d: string) => any {
    return NirSirenValidationHelper.getMask(type, this.gestipSelected);
  }

  clearNumber() {
    this.nirOrSirenNumber = '';
    this.nirOrSirenError = '';
  }

  private loadData() {
    this.currentCachedField = this.bpijSelected ? this.bpijCachedField : this.gestipCachedField;

    const isNirOrSirenEmpty = this.nirOrSirenNumber == '' || this.nirOrSirenNumber == null;

    if (this.isTypeAll(this.type) && !this.gestipSelected && isNirOrSirenEmpty) {
      if (!this.fromCalendar.date || !this.toCalendar.date)
        return;

      this.loadDataTableBpijForTypeAll(null, null);
      return;
    }

    if ((!this.currentCachedField.nirOrSiren && isNirOrSirenEmpty) || this.nirOrSirenError) {
      return;
    }

    if (this.isSiretOrSiren() && (!this.fromCalendar.date || !this.toCalendar.date || isNirOrSirenEmpty)) {
      return;
    }

    if (this.isNir() && isNirOrSirenEmpty) {
      return;
    }

    this.exportBtnEnabled = false;

    const nirOrSiren = isNirOrSirenEmpty ?
      this.currentCachedField.nirOrSiren
      : this.nirOrSirenNumber;

    if (!nirOrSiren) return;

    const nirValue = this.getNirValue(nirOrSiren);
    const sirenValue = this.getSirenValue(nirOrSiren);

    const hasNirOrSirenValue = !!nirValue || !!sirenValue;
    if (this.bpijSelected && hasNirOrSirenValue) {
      this.loadDataTableBpij(nirValue, sirenValue);
    } else if (this.gestipSelected && hasNirOrSirenValue) {
      this.loadDataTableGestip(nirValue, sirenValue);
    }
  }

  private loadDataTableBpij(nir: string, siren: string): void {
    const from = this.fromCalendar.date ? this.fromCalendar.date.clone() : this.fromCalendar.date;
    const to = this.toCalendar.date ? this.toCalendar.date.clone() : this.toCalendar.date;

    this.bpijService.getDailyBenefits(nir, siren, this.pageSize, this.pageNumber, from, to)
      .subscribe(
        data => {
          this.hasResults = data.payload.length !== 0; // show error msg

          if (this.hasResults) {
            this.setSearchCacheFields(
              this.bpijCachedField,
              this.type,
              !!nir ? nir : siren,
              this.fromCalendar.date,
              this.toCalendar.date
            );
          }
          else {
            this.resetCachedField(this.bpijCachedField);
          }
          this.exportBtnEnabled = data.metadata.hits > 0;
          this.hideHelp = true;
          this.bpijBenefitsTable.setData(data, this.displayTechnicalData);
        },
        error => {
          this.hasErrors = true;
        }
      );
  }

  private loadDataTableBpijForTypeAll(nir: string, siren: string): void {
    this.bpijService.getDailyBenefitsFromReceiptDates(nir, siren, this.pageSize, this.pageNumber, this.fromCalendar.date.clone(), this.toCalendar.date.clone())
      .subscribe(
        data => {
          this.hasResults = data.payload.length !== 0; // show error msg

          if (this.hasResults) {
            this.setSearchCacheFields(
              this.bpijCachedField,
              this.type,
              !!nir ? nir : siren,
              this.fromCalendar.date,
              this.toCalendar.date
            );
          }
          else {
            this.resetCachedField(this.bpijCachedField);
          }
          this.exportBtnEnabled = data.metadata.hits > 0;
          this.hideHelp = true;
          this.bpijBenefitsTable.setData(data, this.displayTechnicalData);
        },
        error => {
          this.hasErrors = true;
        }
      );
  }

  private loadDataTableGestip(nir: string, siren: string) {
    const from = this.fromCalendar.date ? this.fromCalendar.date.clone() : this.fromCalendar.date;
    const to = this.toCalendar.date ? this.toCalendar.date.clone() : this.toCalendar.date;
    this.gestipService.getGestipOperations(nir, siren, this.pageSize, this.pageNumber, from, to)
      .subscribe(
        data => {
          this.hasResults = data.payload.length !== 0; // show error msg
          if (this.hasResults) {
            this.setSearchCacheFields(
              this.gestipCachedField,
              this.type,
              !!nir ? nir : siren,
              this.fromCalendar.date,
              this.toCalendar.date
            );
          }
          else {
            this.resetCachedField(this.gestipCachedField);
          }
          this.exportBtnEnabled = data.metadata.hits > 0;
          this.hideHelp = true;
          this.gestipOperationsTable.setData(data, this.displayTechnicalData);
        },
        error => {
          this.hasErrors = true;
        }
      );
  }

  private getNirValue(nirOrSiren: string): string {
    if (!this.currentCachedField.type)
      return this.isNir() ? this.getNirOrSirenFormatedValue(nirOrSiren) : null;
    else {
      return this.currentCachedField.type === NirSirenValidationHelper.NIR ? this.getNirOrSirenFormatedValue(nirOrSiren) : null;
    }
  }

  private getSirenValue(nirOrSiren: string): string {
    if (!this.currentCachedField.type)
      return this.isNir() ? null : this.getNirOrSirenFormatedValue(nirOrSiren);
    else {
      return this.currentCachedField.type === NirSirenValidationHelper.NIR ? null : this.getNirOrSirenFormatedValue(nirOrSiren);
    }
  }

  private getNirOrSirenFormatedValue(nirOrSiren: string): string {
    return nirOrSiren.replace(/\s/g, '');
  }

  private setSearchCacheFields(searchCachedField: SearchCachedField, type: any, nirOrSiren: string, from: Moment, to: Moment) {
    searchCachedField.type = type;
    searchCachedField.nirOrSiren = nirOrSiren;
    searchCachedField.from = from;
    searchCachedField.to = to;
  }

  private resetCachedField(searchCachedField: SearchCachedField) {
    this.setSearchCacheFields(searchCachedField, null, null, null, null);
  }

  private resetGestipType(): void {
    this.type = this.gestipCachedField.type ? this.gestipCachedField.type : this.searchOptions[0];
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
