export class HeadElementViewData {
  private static readonly TIME_OPTIONS = { hour: '2-digit', minute: '2-digit' };
  public id: string;
  public head: string;
  public dataFilter?: [];
  public filter?: boolean;
  public class: string | ((d: any) => string);
  public format: (d: any) => string;

  public constructor(init?: Partial<HeadElementViewData>) {
    (<any>Object).assign(this, init);
  }

  label(data: any): string {
    if (typeof (data) == "string" && data && data.match(/^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i))
      data = new Date(data);

    if (this.format) return this.format(data);

    if (data instanceof Date) {
      return this.id === "receiptDate" ? data.toLocaleDateString() + " " + data.toLocaleTimeString("fr-FR", HeadElementViewData.TIME_OPTIONS) : data.toLocaleDateString("fr-FR");
    }

    if (typeof data === "boolean") return data ? "Oui" : "Non";

    return data ? data.toString() : data;
  }

  getClass(data: any) {
    if (typeof this.class == "string") return this.class;
    return this.class ? this.class(data) : "";
  }
}
