import DWH = Models.DataWarehouse;

export abstract class StatisticsAverageDeliveryTimesViewModel {
    periodStartDate: string;
    periodEndDate: string;
    periodGranularity: DWH.PeriodGranularity;

    constructor(model: DWH.EdiFlowsAverageDeliveryTimesModel) {
        this.periodStartDate = model.periodStartDate;
        this.periodEndDate = model.periodEndDate;
        this.periodGranularity = model.periodGranularity;
    }
}