import { StatisticsAverageDeliveryTimesViewModel } from "./statistics-average-delivery-times-view-model";
import DWH = Models.DataWarehouse;

export class StatisticsCrAverageDeliveryTimesViewModel extends StatisticsAverageDeliveryTimesViewModel {
    crFlowsAverageDeliveryTimeInHours: number;
    crFlowsDeliveryTimeAverages: DWH.CrFlowsDeliveryTimeAverage[];

    constructor(model: DWH.EdiFlowsAverageDeliveryTimesModel) {
        super(model);

        this.crFlowsAverageDeliveryTimeInHours = model.globalDeliveryTimeAverages.crFlowsAverageDeliveryTimeInHours;
        this.crFlowsDeliveryTimeAverages = model.crFlowsDeliveryTimeAverages === null ? [] : model.crFlowsDeliveryTimeAverages;
    }
}