import { Component } from '@angular/core';

@Component({
    selector: 'app-administration-metier',
    templateUrl: './administration-metier.component.html',
    styleUrls: ['./administration-metier.component.scss']
})
/** administration-metier component*/
export class AdministrationMetierComponent {
    /** administration-metier ctor */
    constructor() {

    }
}