import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import { CalendarComponent } from '@app/_layers/calendar/calendar.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class CalendarService {
  fromCalendar: CalendarComponent;
  toCalendar: CalendarComponent;

  private destroyed: Subject<boolean> = new Subject<boolean>();


  constructor() {
  }

  setCalendars(from: CalendarComponent, to: CalendarComponent) {
    if (this.fromCalendar != null)
      return; //calendars already set
    this.fromCalendar = from;
    this.toCalendar = to;
    this.fromCalendar.dateChange.pipe(takeUntil(this.destroyed)).subscribe(this.dateFromChange.bind(this));
    this.toCalendar.dateChange.pipe(takeUntil(this.destroyed)).subscribe(this.dateToChange.bind(this));
    this.fromCalendar.errorChange.pipe(takeUntil(this.destroyed)).subscribe(this.checkDateFrom.bind(this));
    this.toCalendar.errorChange.pipe(takeUntil(this.destroyed)).subscribe(this.checkDateTo.bind(this));
    //remove min/max limits for the calendar
    this.setDefaultCalendarBoundaries();
  }

  setDefaultCalendarBoundaries() {
    this.fromCalendar.setCalendarBounds(null, CalendarComponent.TODAY);
    this.toCalendar.setCalendarBounds(null, CalendarComponent.TODAY);
  }

  setDefaultCalendarBoundariesToTodayMinusOne() {
    this.fromCalendar.setCalendarBounds(null, CalendarComponent.YESTERDAY);
    this.toCalendar.setCalendarBounds(null, CalendarComponent.YESTERDAY);
  }

  dateFromChange(date: Moment) {
    this.toCalendar.minDate = date;
    if (date && !this.toCalendar.date) {
      this.toCalendar.date = CalendarComponent.TODAY;
    }
  }

  dateToChange(date: Moment) {
    this.fromCalendar.maxDate = date ? date : CalendarComponent.TODAY;
    if (date && !this.fromCalendar.date) {
      this.fromCalendar.date = date;
    }
  }

  checkDateFrom(error: string) {
    if (error == CalendarComponent.DATE_OVER_MAX) {
      this.toCalendar.date = this.fromCalendar.date;
    }
  }

  checkDateTo(error: string) {
    if (error == CalendarComponent.DATE_UNDER_MIN) {
      this.fromCalendar.date = this.toCalendar.date;
    }
    if (error == CalendarComponent.DATE_OVER_MAX) {
      this.toCalendar.date = this.toCalendar.maxCalendarDate;
    }
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
