
import { Moment } from 'moment';

export class Period {

    beginDate: Moment;
    endDate: Moment;
  
    constructor(beginDate: Moment, endDate: Moment) {
      this.beginDate = beginDate;
      this.endDate = endDate;
    }
  }