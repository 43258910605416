import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Period } from '@app/_core/view-models/datawarehouse/edi-flow/view-models-from-views/period';
import { GranularityType } from '@app/_core/view-models/datawarehouse/edi-flow/view-models-from-views/granularity-type';

@Injectable({
  providedIn: 'root'
})
export class PeriodByGranularityService {

  readonly thirtyDays = moment.duration(30, 'days');
  readonly twelveMonths = moment.duration(1, 'years');

  getDefaultPeriodByGranularity(granularity: GranularityType): Period {
    let defaultPeriod: Period;
    if (granularity === GranularityType.Days) {
      defaultPeriod = new Period(moment().startOf("days").subtract(30, 'days'), moment().startOf("days").subtract(1, 'days'));
    } else if (granularity === GranularityType.Months) {
      defaultPeriod = new Period(moment().startOf('month').subtract(11, 'month'), moment().startOf('month'));
    }
    return defaultPeriod;
  }

  getPreviousPeriodByGranularity(granularity: GranularityType, currentPeriod: Period): Period {
    let previousPeriod: Period;
    let period = new Period(currentPeriod.beginDate.clone(), currentPeriod.endDate.clone());
    if (granularity === GranularityType.Days) {
      previousPeriod = new Period(period.beginDate.subtract(this.thirtyDays), period.endDate.subtract(this.thirtyDays));
    } else if (granularity === GranularityType.Months) {
      previousPeriod = new Period(period.beginDate.subtract(this.twelveMonths), period.endDate.subtract(this.twelveMonths));
    }
    return previousPeriod;
  }

  getNextPeriodByGranularity(granularity: GranularityType, currentPeriod: Period): Period {
    let nextPeriod: Period;
    let period = new Period(currentPeriod.beginDate.clone(), currentPeriod.endDate.clone());
    if (granularity === GranularityType.Days) {
      nextPeriod = new Period(period.beginDate.add(this.thirtyDays), period.endDate.add(this.thirtyDays));
    } else if (granularity === GranularityType.Months) {
      nextPeriod = new Period(period.beginDate.add(this.twelveMonths), period.endDate.add(this.twelveMonths));
    }
    return nextPeriod;
  }

  /**
   * Pour savoir si le bouton flèche à droite doit être grisé ou pas
   */
  isCurrentPeriodEqualDefaultPeriod(granularity: GranularityType, currentPeriod: Period): boolean {
    let defaultPeriod = this.getDefaultPeriodByGranularity(granularity);
    const formatPeriod: string = (granularity === GranularityType.Days ? "DD/MM/YYYY" : "MMMM YYYY");
    return currentPeriod.beginDate.format(formatPeriod) === defaultPeriod.beginDate.format(formatPeriod)
      && currentPeriod.endDate.format(formatPeriod) === defaultPeriod.endDate.format(formatPeriod) ? true : false;
  }

  getPeriodAfterSwitchGranularity(granularity: GranularityType, currentPeriodDays: Period, currentPeriodMonths: Period): Period {
    return granularity === GranularityType.Days ? currentPeriodDays : currentPeriodMonths;
  }

}
