import { Component } from '@angular/core';

@Component({
    selector: 'app-contrats',
    templateUrl: './contrats.component.html',
    styleUrls: ['./contrats.component.scss']
})
/** contrats component*/
export class ContratsComponent {
    /** contrats ctor */
    constructor() {

    }
}