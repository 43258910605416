import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-fiche-client',
  templateUrl: './fiche-client.component.html'
})
export class FicheClientComponent {
  public client: Customer[];

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    http.get<Customer[]>(baseUrl + 'ficheclient').subscribe(result => {
      this.client = result;
    }, error => console.error(error));
  }
}

interface Customer {
  lastName: string;
  prenom: string;
  dateNaissance: string;
}
