import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit, Output, EventEmitter, Renderer2, OnDestroy } from '@angular/core';
import { merge, of as observableOf, Subject } from 'rxjs';
import { catchError, map, startWith, filter, switchMap, takeUntil } from 'rxjs/operators';
import { MdbTablePaginationComponent, MdbTableDirective, BaseChartDirective, MdbTableSortDirective } from 'angular-bootstrap-md';
import { Router, ActivatedRoute, RouterEvent, NavigationEnd } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FluxEdiService, AuthenticationService } from '@app/_services';
import Edi = Models.Edi;
import HeadElement = Models.HeadElement;
import * as Chart from 'chart.js';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-non-conformite',
  templateUrl: './non-conformite.component.html',
  styleUrls: ['./non-conformite.component.scss']
})
/** non-conformite component*/
export class NonConformiteComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MdbTableDirective, { static: false }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: false }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableSortDirective, { static: false }) sort: MdbTableSortDirective;
  @ViewChild('tableFlux', { static: false }) tableFlux: ElementRef;
  @ViewChild('graphFlux', { static: false }) graphFlux: ElementRef;
  @ViewChild('dateReception', { static: false }) dateReception: ElementRef;
  @ViewChild('chartNoArl', { static: false }) chartNoArl: ElementRef;
  @ViewChild('chartArlRejected', { static: false }) chartArlRejected: ElementRef;
  @ViewChild('chartNoCr', { static: false }) chartNoCr: ElementRef;
  @ViewChild('chartOpeGestip', { static: false }) chartOpeGestip: BaseChartDirective;
  @ViewChild('helpFlux', { static: false }) helpFlux: ElementRef;
  @ViewChild(BaseChartDirective, { static: false }) private chart;

  public pipe = new DatePipe('fr-FR'); // Use your own locale
  modalArlInfoContent: Edi.Diagnostic;
  modalCrInfoContent: Edi.OperationEdiGestip;
  modalMaskContent: Edi.Masquage;
  modalMaskingInfoContent: any;
  savedDataTable: string;
  currentDataTable = [];
  noFilterDataTable = [];
  filteredDataTable = [];
  dataNoArl: Edi.GestipLight[];
  dataNoCr: Edi.GestipLight[];
  dataNoArlMasked: Edi.GestipLight[];
  dataNoCrMasked: Edi.GestipLight[];
  dataArlRejected: Edi.GestipLight[];
  dataArlRejectedMasked: Edi.GestipLight[];
  dataCrRejected: Edi.OperationEdiGestip[];
  dataCrRejectedMasked: Edi.OperationEdiGestip[];

  maskInfo = new FormControl('');
  sameId = new FormControl(false);

  noArl = 'NOARL';
  noCr = 'NOCR';
  arlRejected = 'ARLREJECTED';
  crRejected = 'CRREJECTED';
  maskedNoArl = 'MASKED_NOARL';
  maskedNoCr = 'MASKED_NOCR';
  maskedCrRejected = 'MASKED_CRREJECTED';
  maskedArlRejected = 'MASKED_ARLREJECTED';
  filterDestinataireElem = 'Tous';
  sortingElement = '';
  sortingOrder = '';
  dateFormater = 'dd/MM/yyyy HH:mm';
  submitedText = '';

  @Output() dataNoArlChange = new EventEmitter<number>();
  @Output() dataArlRejectedChange = new EventEmitter<number>();
  @Output() dataNoCrChange = new EventEmitter<number>();
  @Output() dataCrRejectedChange = new EventEmitter<number>();

  nbNoArl = 0;
  nbArlRejected = 0;
  nbNoCr = 0;
  nbCrRejected = 0;
  previousTableItems = [];
  maxVisibleItems = 10;

  tableDisplayed = false;
  tableHide = true;
  graphHide = false;
  helpHide = false;
  maskedButtonHide = true;
  filter = false;
  hasfilterDestinataire = false;
  tableNoArlDisplayed = false;
  tableArlRejectedDisplayed = false;
  tableNoCrDisplayed = false;
  tableCrRejectedDisplayed = false;
  tableMasked = true;
  tableMaskedNoArlDisplayed = false;
  tableMaskedArlRejectedDisplayed = false;
  tableMaskedNoCrDisplayed = false;
  tableMaskedCrRejectedDisplayed = false;
  toggle = false;

  cardNoArlSelected = false;
  cardArlRejectedSelected = false;
  cardNoCrSelected = false;
  cardCrRejectedSelected = false;

  headElements: HeadElement[];

  public chartType = 'bar';

  public chartDatasetsNoArl = [
    { data: [0, 0, 0, 0, 0, 0, 0], label: '' }
  ];

  public chartDatasetsArlRejected: any[] = [
    { data: [0, 0, 0, 0, 0, 0, 0], label: '' }
  ];

  public chartDatasetsNoCr: any[] = [
    { data: [0, 0, 0, 0, 0, 0, 0], label: '' }
  ];

  public chartDatasetsCrRejected: any[] = [
    { data: [0, 0, 0, 0, 0, 0, 0], label: '' }
  ];

  public chartLabels: Array<any> = ['...', 'J-5', 'J-4', 'J-3', 'J-2', 'J-1', 'Auj'];

  public chartColorsRed: any[] = [
    {
      backgroundColor: 'rgba(233, 60, 60, 1)',
      borderColor: 'rgba(233, 60, 60, 1)',
      borderWidth: 0,
    }
  ];

  public chartColorsOrange: any[] = [
    {
      backgroundColor: 'rgba(232, 152, 6, 1)',
      borderColor: 'rgba(232, 152, 6, 1)',
      borderWidth: 0,
    }
  ];

  public chartColorsGrey: any[] = [
    {
      backgroundColor: 'rgba(232, 232, 232, 1)',
      borderColor: 'rgba(232, 232, 232, 1)',
      borderWidth: 0,
    }
  ];

  public chartColorsNoArl: any;
  public chartColorsArlRejected: any;
  public chartColorsNoCr: any;
  public chartColorsCrRejected: any;
  public chartLabelsColor: 'rgba(232, 232, 232, 1)';
  public maxFiles = 10;

  public custom: any = this.getPositionChartTooltip();

  public chartOptions: any = {
    responsive: true,
    scales: {
      yAxes: [{
        display: false,
        gridLines: {
          display: false
        },
        ticks: {
          beginAtZero: true,
        }
      }],
      xAxes: [{
        gridLines: {
          display: false
        },
        ticks: {
          fontColor: 'black', // couleur des jours
        },
      }]
    },
    tooltips: {
      position: 'custom',
      enabled: true,
      caretSize: 0,
      custom: function (tooltip) {
        if (!tooltip) { return; }
        // disable displaying the color box;
        tooltip.displayColors = false;
        tooltip.xPadding = 13;
      },
      callbacks: {
        // use label callback to return the desired label
        label: function (tooltipItem) {
          return tooltipItem.yLabel;
        },
        // remove title
        title: function (tooltipItem) {
          return;
        }
      }
    },
    hover: { mode: null },
  };

  sectionParam = '';
  filterParam = {};

  public destroyed = new Subject<any>();

  /** non-conformite ctor */
  constructor(public fluxediService: FluxEdiService,
    private authenticationService: AuthenticationService,
    private router: Router, private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private modalService: NgbModal,
    public datepipe: DatePipe,
    private renderer: Renderer2) {
    this.headElements = [
      { id: 'fileName', head: 'Nom fichier ZIP', dataFilter: [], filter: true, sortable: false },
      { id: 'dateReception', head: 'Date réception fichier', dataFilter: [], filter: false, sortable: false }
    ];
  }

  ngOnInit() {
    const queryModule = this.route.snapshot.params['module'];
    const nbNoCrSaved = this.nbNoCr;
    const nbNoArlSaved = this.nbNoArl;
    const nbArlRejectSaved = this.nbArlRejected;
    const nbCrRejectSaved = this.nbCrRejected;
    // Query params
    if (queryModule === 'non-conformite-prestij') {
      if (this.route.snapshot.params['section']) {
        this.sectionParam = (this.route.snapshot.params['section'] as string).toUpperCase();
        this.filterParam = this.route.snapshot.params['filters'];
      }
    }

    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd), takeUntil(this.destroyed)
    ).subscribe(() => {
      this.loadAllData();
      this.nbNoArl = nbNoArlSaved;
      this.nbNoCr = nbNoCrSaved;
      this.nbArlRejected = nbArlRejectSaved;
      this.nbCrRejected = nbCrRejectSaved;
    });

    this.chartLabels = ['...', 'J-5', 'J-4', 'J-3', 'J-2', 'J-1', 'Auj'];

    if (queryModule === 'non-conformite-prestij') {
      this.chartColorsNoArl = this.chartColorsNoCr = this.chartColorsCrRejected = this.chartColorsArlRejected = this.chartColorsGrey;
    } else {
      this.chartColorsNoArl = this.chartColorsNoCr = this.chartColorsOrange;
      this.chartColorsCrRejected = this.chartColorsArlRejected = this.chartColorsRed;
    }
  }

  ngAfterViewInit(): void {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.maxVisibleItems);
    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.mdbTablePagination.ofKeyword = 'sur';
    this.cdRef.detectChanges();

    // Chargement des données
    this.loadAllData();
  }

  getClass() {
    if (this.tableNoArlDisplayed || this.tableMaskedNoArlDisplayed) {
      return 'no-arl';
    }
    if (this.tableArlRejectedDisplayed || this.tableMaskedArlRejectedDisplayed) {
      return 'arl-rejected';
    }
    if (this.tableNoCrDisplayed || this.tableMaskedNoCrDisplayed) {
      return 'no-cr';
    }
    if (this.tableCrRejectedDisplayed || this.tableMaskedCrRejectedDisplayed) {
      return 'cr-rejected';
    }
  }

  getDateColor(date: Date) {
    const dateToCompare = new Date(date);
    const today = new Date();
    const targetDateWarning = new Date();
    const targetDateTooLate = new Date();
    targetDateWarning.setHours(0, 0, 0, 0);
    targetDateWarning.setDate(today.getDate() - 1);
    targetDateTooLate.setHours(0, 0, 0, 0);
    targetDateTooLate.setDate(today.getDate() - 2);
    dateToCompare.setHours(0, 0, 0, 0);
    dateToCompare.setDate(dateToCompare.getDate());
    const dateToCheck = Date.parse(dateToCompare.toDateString());
    const dateMinusTwo = Date.parse(targetDateWarning.toDateString());
    const dateMinusFour = Date.parse(targetDateTooLate.toDateString());

    if ((dateToCheck > dateMinusFour) && (dateToCheck > dateMinusTwo)) {
      return 'black';
    } else if ((dateToCheck <= dateMinusTwo) && (dateToCheck > dateMinusFour)) {
      return 'orange';
    } else {
      return 'red';
    }
  }

  public chartClicked(e: any): void {
    const dataAtEvent = this.chart.getPointDataAtEvent(e);
  }

  public chartHovered(e: any): void { }

  onSelectFlux(id: string) {
    this.router.navigate(['/edi/' + id]);
  }

  onArlRejectedClick() {
    this.toggle = false;
    this.sortingElement = '';
    this.cardArlRejectedSelected = !this.cardArlRejectedSelected;
    if (this.cardNoArlSelected || this.cardNoCrSelected || this.cardCrRejectedSelected) {
      this.cardNoArlSelected = this.cardNoCrSelected = this.cardCrRejectedSelected = false;
    }

    this.filter = false;
    this.tableMasked = false;
    this.helpHide = true;
    this.tableFlux.nativeElement.style.marginTop = '25px';
    if (!this.tableDisplayed) {
      this.tableArlRejectedDisplayed = true;
      this.tableDisplayed = true;
      this.setDataTable(this.arlRejected);
      this.tableHide = false;
      this.maskedButtonHide = false;
    } else {
      if (this.tableArlRejectedDisplayed) {
        this.tableArlRejectedDisplayed = false;
        this.tableDisplayed = false;
        this.tableHide = true;
        this.helpHide = false;
        this.maskedButtonHide = true;
      } else {
        this.tableArlRejectedDisplayed = true;
        this.tableDisplayed = true;
        this.tableNoArlDisplayed = false;
        this.tableNoCrDisplayed = false;
        this.tableCrRejectedDisplayed = false;

        this.setDataTable(this.arlRejected);
        this.tableHide = false;
        this.maskedButtonHide = false;
      }
    }
    if (this.tableDisplayed) {
      this.chartColorsNoArl = this.chartColorsArlRejected = this.chartColorsNoCr
        = this.chartColorsCrRejected = this.chartColorsGrey;
    } else {
      this.chartColorsNoArl = this.chartColorsNoCr = this.chartColorsOrange;
      this.chartColorsCrRejected = this.chartColorsArlRejected = this.chartColorsRed;
    }
    this.mdbTablePagination.firstPage();
  }

  onNoArlClick() {
    this.toggle = false;
    this.sortingElement = '';
    this.cardNoArlSelected = !this.cardNoArlSelected;
    if (this.cardArlRejectedSelected || this.cardNoCrSelected || this.cardCrRejectedSelected) {
      this.cardArlRejectedSelected = this.cardNoCrSelected = this.cardCrRejectedSelected = false;
    }
    this.filter = false;
    this.tableMasked = false;
    this.helpHide = true;
    this.tableFlux.nativeElement.style.marginTop = '25px';
    if (!this.tableDisplayed) {
      this.tableNoArlDisplayed = true;
      this.tableDisplayed = true;
      this.setDataTable(this.noArl);
      this.tableHide = false;
      this.maskedButtonHide = false;
    } else {
      if (this.tableNoArlDisplayed) {
        this.tableNoArlDisplayed = false;
        this.tableDisplayed = false;
        this.tableHide = true;
        this.helpHide = false;
        this.maskedButtonHide = true;
      } else {
        this.tableNoArlDisplayed = true;
        this.tableDisplayed = true;
        this.tableArlRejectedDisplayed = false;
        this.tableCrRejectedDisplayed = false;
        this.tableNoCrDisplayed = false;

        this.setDataTable(this.noArl);
        this.tableHide = false;
        this.maskedButtonHide = false;
      }
    }
    if (this.tableDisplayed) {
      this.chartColorsNoArl = this.chartColorsArlRejected = this.chartColorsNoCr
        = this.chartColorsCrRejected = this.chartColorsGrey;
    } else {
      this.chartColorsNoArl = this.chartColorsNoCr = this.chartColorsOrange;
      this.chartColorsCrRejected = this.chartColorsArlRejected = this.chartColorsRed;
    }
    this.mdbTablePagination.firstPage();
  }

  onNoCrClick() {
    this.toggle = false;
    this.sortingElement = '';
    this.cardNoCrSelected = !this.cardNoCrSelected;
    if (this.cardNoArlSelected || this.cardArlRejectedSelected || this.cardCrRejectedSelected) {
      this.cardNoArlSelected = this.cardArlRejectedSelected = this.cardCrRejectedSelected = false;
    }
    this.filter = false;
    this.tableMasked = false;
    this.helpHide = true;
    this.tableFlux.nativeElement.style.marginTop = '25px';
    if (!this.tableDisplayed) {
      this.tableNoCrDisplayed = true;
      this.tableDisplayed = true;
      this.setDataTable(this.noCr);
      this.tableHide = false;
      this.maskedButtonHide = false;
    } else {
      if (this.tableNoCrDisplayed) {
        this.tableNoCrDisplayed = false;
        this.tableDisplayed = false;
        this.tableHide = true;
        this.helpHide = false;
        this.maskedButtonHide = true;
      } else {
        this.tableNoCrDisplayed = true;
        this.tableDisplayed = true;
        this.tableArlRejectedDisplayed = false;
        this.tableCrRejectedDisplayed = false;
        this.tableNoArlDisplayed = false;

        this.setDataTable(this.noCr);
        this.tableHide = false;
        this.maskedButtonHide = false;
      }
    }
    if (this.tableDisplayed) {
      this.chartColorsNoArl = this.chartColorsArlRejected = this.chartColorsNoCr
        = this.chartColorsCrRejected = this.chartColorsGrey;
    } else {
      this.chartColorsNoArl = this.chartColorsNoCr = this.chartColorsOrange;
      this.chartColorsCrRejected = this.chartColorsArlRejected = this.chartColorsRed;
    }
    this.mdbTablePagination.firstPage();
  }

  onCrRejectedClick() {
    this.toggle = false;
    this.sortingElement = '';
    this.cardCrRejectedSelected = !this.cardCrRejectedSelected;
    if (this.cardArlRejectedSelected || this.cardNoArlSelected || this.cardNoCrSelected) {
      this.cardArlRejectedSelected = this.cardNoArlSelected = this.cardNoCrSelected = false;
    }
    this.filter = false;
    this.tableMasked = false;
    this.helpHide = true;
    this.tableFlux.nativeElement.style.marginTop = '25px';
    if (!this.tableDisplayed) {
      this.tableCrRejectedDisplayed = true;
      this.tableDisplayed = true;
      this.setDataTable(this.crRejected);
      this.tableHide = false;
      this.maskedButtonHide = false;
    } else {
      if (this.tableCrRejectedDisplayed) {
        this.tableCrRejectedDisplayed = false;
        this.tableDisplayed = false;
        this.tableHide = true;
        this.helpHide = false;
        this.maskedButtonHide = true;
      } else {
        this.tableCrRejectedDisplayed = true;
        this.tableDisplayed = true;
        this.tableArlRejectedDisplayed = false;
        this.tableNoCrDisplayed = false;
        this.tableNoArlDisplayed = false;

        this.setDataTable(this.crRejected);
        this.tableHide = false;
        this.maskedButtonHide = false;
      }
    }
    if (this.tableDisplayed) {
      this.chartColorsNoArl = this.chartColorsArlRejected = this.chartColorsNoCr
        = this.chartColorsCrRejected = this.chartColorsGrey;
    } else {
      this.chartColorsNoArl = this.chartColorsNoCr = this.chartColorsOrange;
      this.chartColorsCrRejected = this.chartColorsArlRejected = this.chartColorsRed;
    }
    this.mdbTablePagination.firstPage();
  }

  maskData(data: any, maskNote: string, sameId: boolean) {
    const currentUser = this.authenticationService.currentUserValue;
    const masquage: Edi.Masquage = {
      dateMasquage: new Date(),
      gestipId: 'id' in data ? data.id : '',
      utilisateurId: currentUser.id,
      utilisateurPrenom: currentUser.firstName,
      utilisateurNom: currentUser.lastName,
      note: maskNote
    };

    if (this.tableArlRejectedDisplayed) {
      merge()
        .pipe(
          startWith({}),
          switchMap(() => {
            return this.fluxediService.setStateOfGestipArlEnErreurTraite(data.identification, masquage);
          }),
          map(result => {
            return result;
          }),
          catchError(() => {
            return observableOf([]);
          })
        ).subscribe(() => {
          this.loadDataTableArlRejected();
        });
    } else if (this.tableCrRejectedDisplayed) {
      merge()
        .pipe(
          startWith({}),
          switchMap(() => {
            if (sameId) {
              return this.fluxediService.setStateOfOperationGestipCrAbsentOrCrInvalideTraiteFromGestipId(data.identificationGestip, masquage);
            } else {
              return this.fluxediService.setStateOfOperationGestipCrAbsentOrCrInvalideTraite(data.id, masquage);
            }
          }),
          map(result => {
            return result;
          }),
          catchError(() => {
            return observableOf([]);
          })
        ).subscribe(() => {
          this.loadDataTableCrRejected();
        });
    } else if (this.tableNoArlDisplayed) {
      merge()
        .pipe(
          startWith({}),
          switchMap(() => {
            return this.fluxediService.setStateOfGestipArlAbsentTraite(data.identification, masquage);
          }),
          map(result => {
            return result;
          }),
          catchError(() => {
            return observableOf([]);
          })
        ).subscribe(() => {
          this.loadDataTableNoArl();
        });
    } else if (this.tableNoCrDisplayed) {
      merge()
        .pipe(
          startWith({}),
          switchMap(() => {
            return this.fluxediService.setStateOfGestipCrAbsentTraite(data.identification, masquage);
          }),
          map(result => {
            return result;
          }),
          catchError(() => {
            return observableOf([]);
          })
        ).subscribe(() => {
          this.loadDataTableNoCr();
        });
    }
    if (this.mdbTablePagination.firstItemIndex === this.mdbTablePagination.lastItemIndex) {
      this.mdbTablePagination.previousPage();
    }
  }

  onUnmaskDataClick(data: any) {
    if (this.tableMaskedArlRejectedDisplayed) {
      merge()
        .pipe(
          startWith({}),
          switchMap(() => {
            return this.fluxediService.setStateOfGestipArlEnErreurTraite(data.identification, null);
          }),
          map(returnedData => {
            return returnedData;
          }),
          catchError(() => {
            return observableOf([]);
          })
        ).subscribe(() => {
          this.dataArlRejectedChange.emit(++this.nbArlRejected);
          this.loadDataTableArlRejectedMasked();
        });
    } else if (this.tableMaskedCrRejectedDisplayed) {
      merge()
        .pipe(
          startWith({}),
          switchMap(() => {
            return this.fluxediService.setStateOfOperationGestipCrAbsentOrCrInvalideTraite(data.id, null);
          }),
          map(returnedData => {
            return returnedData;
          }),
          catchError(() => {
            return observableOf([]);
          })
        ).subscribe(() => {
          this.dataCrRejectedChange.emit(++this.nbCrRejected);
          this.loadDataTableCrRejectedMasked();
        });
    } else if (this.tableMaskedNoArlDisplayed) {
      merge()
        .pipe(
          startWith({}),
          switchMap(() => {
            return this.fluxediService.setStateOfGestipArlAbsentTraite(data.identification, null);
          }),
          map(returnedData => {
            return returnedData;
          }),
          catchError(() => {
            return observableOf([]);
          })
        ).subscribe(() => {
          this.dataNoArlChange.emit(++this.nbNoArl);
          this.loadDataTableNoArlMasked();
        });
    } else if (this.tableMaskedNoCrDisplayed) {
      merge()
        .pipe(
          startWith({}),
          switchMap(() => {
            return this.fluxediService.setStateOfGestipCrAbsentTraite(data.identification, null);
          }),
          map(returnedData => {
            return returnedData;
          }),
          catchError(() => {
            return observableOf([]);
          })
        ).subscribe(() => {
          this.dataNoCrChange.emit(++this.nbNoCr);
          this.loadDataTableNoCrMasked();
        });
    }
    if (this.mdbTablePagination.firstItemIndex === this.mdbTablePagination.lastItemIndex) {
      this.mdbTablePagination.previousPage();
    }
  }

  onShowMaskedClick() {
    this.cardNoArlSelected = this.cardArlRejectedSelected = this.cardNoCrSelected = this.cardCrRejectedSelected = this.toggle = false;

    this.sortingElement = '';
    this.tableFlux.nativeElement.style.marginTop = '-2px';
    this.helpHide = this.tableMasked = true;
    this.filter = false;

    if (this.tableNoArlDisplayed) {
      this.tableNoArlDisplayed = false;
      this.savedDataTable = this.noArl;
      this.graphHide = true;
      this.tableMaskedNoArlDisplayed = true;
      this.tableMaskedArlRejectedDisplayed = false;
      this.tableMaskedNoCrDisplayed = false;
      this.tableMaskedCrRejectedDisplayed = false;

      this.tableHide = false;
      this.maskedButtonHide = true;

      this.loadDataTableNoArlMasked();
    }

    if (this.tableArlRejectedDisplayed) {
      this.tableArlRejectedDisplayed = false;
      this.savedDataTable = this.arlRejected;
      this.graphHide = true;
      this.tableMaskedNoArlDisplayed = false;
      this.tableMaskedArlRejectedDisplayed = true;
      this.tableMaskedNoCrDisplayed = false;
      this.tableMaskedCrRejectedDisplayed = false;
      this.tableHide = false;
      this.maskedButtonHide = true;

      this.loadDataTableArlRejectedMasked();
    }

    if (this.tableNoCrDisplayed) {
      this.tableNoCrDisplayed = false;
      this.savedDataTable = this.noCr;
      this.graphHide = true;
      this.tableMaskedNoArlDisplayed = false;
      this.tableMaskedArlRejectedDisplayed = false;
      this.tableMaskedNoCrDisplayed = true;
      this.tableMaskedCrRejectedDisplayed = false;
      this.tableHide = false;
      this.maskedButtonHide = true;

      this.loadDataTableNoCrMasked();
    }

    if (this.tableCrRejectedDisplayed) {
      this.tableCrRejectedDisplayed = false;
      this.savedDataTable = this.crRejected;
      this.graphHide = true;
      this.tableMaskedNoArlDisplayed = false;
      this.tableMaskedArlRejectedDisplayed = false;
      this.tableMaskedNoCrDisplayed = false;
      this.tableMaskedCrRejectedDisplayed = true;
      this.tableHide = false;
      this.maskedButtonHide = true;

      this.loadDataTableCrRejectedMasked();
    }
    this.mdbTablePagination.firstPage();
  }

  onReturnUnmaskedClick() {
    this.tableFlux.nativeElement.style.marginTop = '25px';
    this.helpHide = true;
    this.graphHide = this.tableMasked = false;
    this.tableMaskedArlRejectedDisplayed = this.tableMaskedCrRejectedDisplayed = this.tableMaskedNoArlDisplayed = this.tableMaskedNoCrDisplayed = false;

    this.tableDisplayed = true;
    this.setDataTable(this.savedDataTable);
    if (this.savedDataTable === this.noArl) {
      this.cardNoArlSelected = true;
      this.tableNoArlDisplayed = true;
      this.loadDataTableNoArl();
    } else if (this.savedDataTable === this.arlRejected) {
      this.cardArlRejectedSelected = true;
      this.tableArlRejectedDisplayed = true;
      this.loadDataTableArlRejected();
    } else if (this.savedDataTable === this.noCr) {
      this.cardNoCrSelected = true;
      this.tableNoCrDisplayed = true;
      this.loadDataTableNoCr();
    } else {
      this.cardCrRejectedSelected = true;
      this.tableCrRejectedDisplayed = true;
      this.loadDataTableCrRejected();
    }
    this.tableHide = false;
    this.maskedButtonHide = false;

    if (this.tableDisplayed) {
      this.chartColorsNoArl = this.chartColorsArlRejected = this.chartColorsNoCr
        = this.chartColorsCrRejected = this.chartColorsGrey;
    } else {
      this.chartColorsNoArl = this.chartColorsNoCr = this.chartColorsOrange;
      this.chartColorsCrRejected = this.chartColorsArlRejected = this.chartColorsRed;
    }
    this.mdbTablePagination.firstPage();
  }

  onDisplayModalArlInfoClick(targetModal, data: Edi.GestipLight) {
    this.modalArlInfoContent = data.fluxGestip.diagnostic;
    this.modalService.open(targetModal, {
      centered: true,
    });
  }

  onDisplayModalCrInfoClick(targetModal, data: Edi.OperationEdiGestip) {
    this.modalCrInfoContent = data;
    this.modalService.open(targetModal, {
      centered: true,
    });
  }

  onDisplayModalMaskClick(targetModal, data: Edi.Masquage) {
    this.modalMaskContent = data;
    this.modalService.open(targetModal, {
      centered: true,
      size: 'masking',
    });
  }

  onDisplayModalMaskingClick(targetModal, data: any) {
    this.modalMaskingInfoContent = data;
    this.modalService.open(targetModal, {
      centered: true,
      size: 'masking',
    });

    this.sameId.setValue(false);
    this.maskInfo.patchValue('');
  }

  onSubmitModalInfo(data: any) {
    this.maskData(data, this.maskInfo.value, this.sameId.value);
    this.modalService.dismissAll();
  }

  onFilterClick(filterSelection: string, elemId: string, indexFilter: number) {
    this.filter = true;
    const filteredArray = [];

    if (filterSelection === 'Tous') {
      this.filter = false;
      this.currentDataTable = this.noFilterDataTable;
      this.disabledOff(this.headElements[this.findHeadIndex(elemId)].dataFilter);
      this.newDisabledOn(this.headElements[this.findHeadIndex(elemId)].dataFilter, indexFilter);
    } else {
      if (this.tableNoArlDisplayed || this.tableMaskedNoArlDisplayed) {
        this.currentDataTable = this.noFilterDataTable;
        this.currentDataTable.forEach(element => {
          const partenaire = (element as Edi.GestipLight).fluxGestip.destinataire.libelle;
          if (partenaire === filterSelection) {
            filteredArray.push(element);
          }
        });
        this.currentDataTable = filteredArray;
        this.disabledOff(this.headElements[this.findHeadIndex(elemId)].dataFilter);
        this.newDisabledOn(this.headElements[this.findHeadIndex(elemId)].dataFilter, indexFilter);
      } else if (this.tableArlRejectedDisplayed || this.tableMaskedArlRejectedDisplayed) {
        this.currentDataTable = this.noFilterDataTable;

        this.currentDataTable.forEach(element => {
          const partenaire = (element as Edi.GestipLight).fluxGestip.destinataire.libelle;
          if (partenaire === filterSelection) {
            filteredArray.push(element);
          }
        });
        this.currentDataTable = filteredArray;
        this.disabledOff(this.headElements[this.findHeadIndex(elemId)].dataFilter);
        this.newDisabledOn(this.headElements[this.findHeadIndex(elemId)].dataFilter, indexFilter);
      } else if (this.tableNoCrDisplayed || this.tableMaskedNoCrDisplayed) {
        this.currentDataTable = this.noFilterDataTable;

        this.currentDataTable.forEach(element => {
          const partenaire = (element as Edi.GestipLight).fluxGestip.destinataire.libelle;
          if (partenaire === filterSelection) {
            filteredArray.push(element);
          }
        });
        this.currentDataTable = filteredArray;
        this.disabledOff(this.headElements[this.findHeadIndex(elemId)].dataFilter);
        this.newDisabledOn(this.headElements[this.findHeadIndex(elemId)].dataFilter, indexFilter);
      } else if (this.tableCrRejectedDisplayed || this.tableMaskedCrRejectedDisplayed) {
        this.currentDataTable = this.noFilterDataTable;

        this.currentDataTable.forEach(element => {
          const ope = element as Edi.OperationEdiGestip;
          if (ope.libelleDestinataire === filterSelection || ope.identiteInstitutionPrev === filterSelection) {
            filteredArray.push(element);
          }
        });
        this.currentDataTable = filteredArray;
        this.disabledOff(this.headElements[this.findHeadIndex(elemId)].dataFilter);
        this.newDisabledOn(this.headElements[this.findHeadIndex(elemId)].dataFilter, indexFilter);
        this.mdbTablePagination.firstPage();
      }
    }
  }

  findHeadIndex(elemId: string) {
    const headIndex = this.headElements.findIndex(item => item.id === elemId);
    return headIndex;
  }

  findFilterIndex(headIndex: number, elemId: string) {
    const filterIndex = this.headElements[headIndex].dataFilter.findIndex(item => item.id === elemId);
    return filterIndex;
  }

  disabledOff(data: any[]) {
    data.forEach(element => {
      element.default = false;
    });
  }

  newDisabledOn(data: any[], indexFilter: number) {
    data[indexFilter].default = true;
  }

  disableTooltip(elementRef) {
    const element = elementRef;
    return (element.offsetWidth >= element.scrollWidth);
  }

  setDataTable(ediType: string) {
    switch (ediType) {
      case this.noArl: {
        this.headElements = [
          { id: 'identification', head: 'Id GESTIP', dataFilter: [], filter: false, sortable: false },
          { id: 'institutionPrev', head: 'Destinataire', dataFilter: [], filter: true, sortable: false },
          { id: 'dateReception', head: 'Date réception GESTIP', dataFilter: [], filter: false, sortable: true, toggle: false },
          { id: 'dateReceptionCrToString', head: 'Date réception CR', dataFilter: [], filter: false, sortable: true, toggle: false }
        ];
        this.mdbTable.setDataSource(this.dataNoArl);
        this.currentDataTable = this.mdbTable.getDataSource();
        this.previousTableItems = this.mdbTable.getDataSource();
        this.loadDestinataireFilter(this.currentDataTable);
        break;
      }
      case this.arlRejected: {
        this.headElements = [
          { id: 'identification', head: 'Id GESTIP', dataFilter: [], filter: false, sortable: false },
          { id: 'institutionPrev', head: 'Destinataire', dataFilter: [], filter: true, sortable: false },
          { id: 'dateReception', head: 'Date réception GESTIP', dataFilter: [], filter: false, sortable: true, toggle: false },
          { id: 'fluxGestip.dateReceptionArlToString', head: 'Date réception ARL', dataFilter: [], filter: false, sortable: true, toggle: false },
          { id: 'codeErreur', head: 'Code Erreur', dataFilter: [], filter: false, sortable: true, toggle: false }
        ];
        this.mdbTable.setDataSource(this.dataArlRejected);
        this.currentDataTable = this.mdbTable.getDataSource();
        this.previousTableItems = this.mdbTable.getDataSource();
        this.loadDestinataireFilter(this.currentDataTable);
        break;
      }
      case this.noCr: {
        this.headElements = [
          { id: 'identification', head: 'Id GESTIP', dataFilter: [], filter: false, sortable: false },
          { id: 'institutionPrev', head: 'Destinataire', dataFilter: [], filter: true, sortable: false },
          { id: 'dateReception', head: 'Date réception GESTIP', dataFilter: [], filter: false, sortable: true, toggle: false },
          { id: 'fluxGestip.dateReceptionArlToString', head: 'Date réception ARL', dataFilter: [], filter: false, sortable: true, toggle: false },
        ];
        this.mdbTable.setDataSource(this.dataNoCr);
        this.currentDataTable = this.mdbTable.getDataSource();
        this.previousTableItems = this.mdbTable.getDataSource();
        this.loadDestinataireFilter(this.currentDataTable);
        break;
      }
      case this.crRejected: {
        this.headElements = [
          { id: 'identification', head: 'Id GESTIP', dataFilter: [], filter: false, sortable: false },
          { id: 'operation', head: 'Opération', dataFilter: [], filter: false, sortable: false },
          { id: 'libelleDestinataire', head: 'Destinataire', dataFilter: [], filter: true, sortable: false },
          { id: 'dateReception', head: 'Date réception GESTIP', dataFilter: [], filter: false, sortable: true, toggle: false },
          { id: 'dateReceptionCrToString', head: 'Date réception CR', dataFilter: [], filter: false, sortable: true, toggle: false },
          { id: 'diagnostic.cause', head: 'Code cause', filter: false, dataFilter: [], sortable: true, toggle: false }
        ];
        this.mdbTable.setDataSource(this.dataCrRejected);
        this.currentDataTable = this.mdbTable.getDataSource();
        this.previousTableItems = this.mdbTable.getDataSource();
        this.loadDestinataireCrFilter(this.currentDataTable);
        break;
      }
      case this.maskedNoArl: {
        this.headElements = [
          { id: 'identification', head: 'Id GESTIP', dataFilter: [], filter: false, sortable: false },
          { id: 'institutionPrev', head: 'Destinataire', dataFilter: [], filter: true, sortable: false },
          { id: 'dateReception', head: 'Date réception GESTIP', dataFilter: [], filter: false, sortable: true, toggle: false },
          { id: 'dateReceptionCrToString', head: 'Date réception CR', dataFilter: [], filter: false, sortable: true, toggle: false },
          { id: 'infoMasquage', head: 'Info de masquage', dataFilter: [], filter: false, sortable: false, toggle: false }
        ];
        this.mdbTable.setDataSource(this.dataNoArlMasked);
        this.currentDataTable = this.mdbTable.getDataSource();
        this.previousTableItems = this.mdbTable.getDataSource();
        this.loadDestinataireFilter(this.currentDataTable);
        break;
      }
      case this.maskedArlRejected: {
        this.headElements = [
          { id: 'identification', head: 'Id GESTIP', dataFilter: [], filter: false, sortable: false },
          { id: 'institutionPrev', head: 'Destinataire', dataFilter: [], filter: true, sortable: false },
          { id: 'dateReception', head: 'Date réception GESTIP', dataFilter: [], filter: false, sortable: true, toggle: false },
          { id: 'dateReceptionArlToString', head: 'Date réception ARL', dataFilter: [], filter: false, sortable: true, toggle: false },
          { id: 'codeErreur', head: 'Code Erreur', dataFilter: [], filter: false, sortable: true, toggle: false },
          { id: 'infoMasquage', head: 'Info de masquage', dataFilter: [], filter: false, sortable: false, toggle: false }

        ];
        this.mdbTable.setDataSource(this.dataArlRejectedMasked);
        this.currentDataTable = this.mdbTable.getDataSource();
        this.previousTableItems = this.mdbTable.getDataSource();
        this.loadDestinataireFilter(this.currentDataTable);
        break;
      }
      case this.maskedNoCr: {
        this.headElements = [
          { id: 'identification', head: 'Id GESTIP', dataFilter: [], filter: false, sortable: false },
          { id: 'institutionPrev', head: 'Destinataire', dataFilter: [], filter: true, sortable: false },
          { id: 'dateReception', head: 'Date réception GESTIP', dataFilter: [], filter: false, sortable: true, toggle: false },
          { id: 'dateReceptionArlToString', head: 'Date réception ARL', dataFilter: [], filter: false, sortable: true, toggle: false },
          { id: 'infoMasquage', head: 'Info de masquage', dataFilter: [], filter: false, sortable: false, toggle: false }
        ];
        this.mdbTable.setDataSource(this.dataNoCrMasked);
        this.currentDataTable = this.mdbTable.getDataSource();
        this.previousTableItems = this.mdbTable.getDataSource();
        this.loadDestinataireFilter(this.currentDataTable);
        break;
      }
      case this.maskedCrRejected: {
        this.headElements = [
          { id: 'identification', head: 'Id GESTIP', dataFilter: [], filter: false, sortable: false },
          { id: 'operation', head: 'Opération', dataFilter: [], filter: false, sortable: false },
          { id: 'libelleDestinataire', head: 'Destinataire', dataFilter: [], filter: true, sortable: false },
          { id: 'dateDocumentToString', head: 'Date réception GESTIP', dataFilter: [], filter: false, sortable: true, toggle: false },
          { id: 'dateReceptionCrToString', head: 'Date réception CR', dataFilter: [], filter: false, sortable: true, toggle: false },
          { id: 'diagnostic.cause', head: 'Code Erreur', dataFilter: [], filter: false, sortable: true, toggle: false },
          { id: 'infoMasquage', head: 'Info de masquage', dataFilter: [], filter: false, sortable: false, toggle: false }
        ];
        this.mdbTable.setDataSource(this.dataCrRejectedMasked);
        this.currentDataTable = this.mdbTable.getDataSource();
        this.previousTableItems = this.mdbTable.getDataSource();
        this.loadDestinataireCrFilter(this.currentDataTable);
        break;
      }
    }

    this.noFilterDataTable = this.currentDataTable;
  }

  loadAllData() {
    this.loadDataTableArlRejected();
    this.loadDataTableCrRejected();
    this.loadDataTableNoArl();
    this.loadDataTableNoCr();
  }

  loadDestinataireFilter(dataTable: Edi.GestipLight[]) {
    const partnerList = [];
    dataTable.forEach(item => {
      const partenaire = item.fluxGestip.destinataire.libelle;
      if (!partnerList.includes(partenaire)) {
        partnerList.push({ id: partenaire, default: false });
      }
    });

    // Création de l'array filtre final SANS doublons
    const data = Array.from(new Set(partnerList.map(a => a.id)))
      .map(id => {
        return partnerList.find(a => a.id === id);
      });
    data.push({ id: 'Tous', default: true });
    const index = this.headElements.findIndex(item => item.id === 'institutionPrev');
    this.headElements[index].dataFilter = data;
  }

  loadDestinataireCrFilter(dataTable: Edi.OperationEdiGestip[]) {
    const partnerList = [];
    dataTable.forEach(item => {
      const destinatire = item.libelleDestinataire ? item.libelleDestinataire : item.identiteInstitutionPrev;
      if (!partnerList.includes(destinatire)) {
        partnerList.push({ id: destinatire, default: false });
      }
    });

    // Création de l'array filtre final SANS doublons
    const data = Array.from(new Set(partnerList.map(a => a.id)))
      .map(id => {
        return partnerList.find(a => a.id === id);
      });
    data.push({ id: 'Tous', default: true });
    let index = this.headElements.findIndex(item => item.id === 'libelleDestinataire');
    if (index === -1) {
      index = this.headElements.findIndex(item => item.id === 'identiteInstitutionPrev');
    }
    this.headElements[index].dataFilter = data;
  }

  loadDataTableNoArl() {
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.fluxediService.getAllGestipWithoutArl();
        }),
        map(data => {
          data.forEach((item) => {
            if (item.dateReceptionCr !== null) {
              item.dateReceptionCrToString = this.datepipe.transform(item.dateReceptionCr, this.dateFormater);
            } else {
              item.dateReceptionCrToString = 'sans CR';
            }
          });
          return data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(data => {
        this.dataNoArl = data;
        this.nbNoArl = this.dataNoArl.length;
        this.dataNoArlChange.emit(this.nbNoArl);
        this.initDataTableChart(this.noArl);

        if (this.tableNoArlDisplayed) {
          this.setDataTable(this.noArl);
        }

        if (this.sectionParam === 'NOARL') {
          this.onNoArlClick();
        }
      });
  }

  loadDataTableNoArlMasked() {
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.fluxediService.getAllGestipWithoutArl(true);
        }),
        map(data => {
          data.forEach((item) => {
            if (item.dateReceptionCr !== null) {
              item.dateReceptionCrToString = this.datepipe.transform(item.dateReceptionCr, this.dateFormater);
            } else {
              item.dateReceptionCrToString = 'sans CR';
            }
          });
          return data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(data => {
        this.dataNoArlMasked = data;
        this.setDataTable(this.maskedNoArl);
      });
  }

  loadDataTableNoCr() {
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.fluxediService.getAllGestipWithoutCr();
        }),
        map(data => {
          data.forEach((item) => {
            if (item.fluxGestip.dateReceptionArl !== null) {
              item.fluxGestip.dateReceptionArlToString = this.datepipe.transform(item.fluxGestip.dateReceptionArl, this.dateFormater);
            } else {
              item.fluxGestip.dateReceptionArlToString = 'sans ARL';
            }
          });
          return data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(data => {
        this.dataNoCr = data;
        this.nbNoCr = this.dataNoCr.length;
        this.dataNoCrChange.emit(this.nbNoCr);
        this.initDataTableChart(this.noCr);

        if (this.tableNoCrDisplayed) {
          this.setDataTable(this.noCr);
        }

        if (this.sectionParam === 'NOCR') {
          this.onNoCrClick();
        }
      });
  }

  loadDataTableNoCrMasked() {
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.fluxediService.getAllGestipWithoutCr(true);
        }),
        map(data => {
          data.forEach((item) => {
            if (item.fluxGestip.dateReceptionArl !== null) {
              item.fluxGestip.dateReceptionArlToString = this.datepipe.transform(item.fluxGestip.dateReceptionArl, this.dateFormater);
            } else {
              item.fluxGestip.dateReceptionArlToString = 'sans ARL';
            }
          });
          return data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(data => {
        this.dataNoCrMasked = data;
        this.setDataTable(this.maskedNoCr);
      });
  }

  loadDataTableArlRejected() {
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.fluxediService.getAllGestipWithArlRejected();
        }),
        map(data => {
          data.forEach((item) => {
            if (item.fluxGestip.dateReceptionArl !== null) {
              item.fluxGestip.dateReceptionArlToString = this.datepipe.transform(item.fluxGestip.dateReceptionArl, this.dateFormater);
            } else {
              item.fluxGestip.dateReceptionArlToString = 'sans ARL';
            }
          });
          return data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(data => {
        this.dataArlRejected = data;
        this.nbArlRejected = this.dataArlRejected.length;
        this.dataArlRejectedChange.emit(this.nbArlRejected);
        this.initDataTableChart(this.arlRejected);

        if (this.tableArlRejectedDisplayed) {
          this.setDataTable(this.arlRejected);
        }

        if (this.sectionParam === 'ARLKO') {
          this.onArlRejectedClick();
        }
      });
  }

  loadDataTableArlRejectedMasked() {
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.fluxediService.getAllGestipWithArlRejected(true);
        }),
        map(data => {
          data.forEach((item) => {
            if (item.fluxGestip.dateReceptionArl !== null) {
              item.fluxGestip.dateReceptionArlToString = this.datepipe.transform(item.fluxGestip.dateReceptionArl, this.dateFormater);
            } else {
              item.fluxGestip.dateReceptionArlToString = 'sans ARL';
            }
          });
          return data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(data => {
        this.dataArlRejectedMasked = data;
        this.setDataTable(this.maskedArlRejected);
      });
  }

  loadDataTableCrRejected() {
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.fluxediService.getAllOperationEdiGestipWithoutCrOrWithCrRejected();
        }),
        map(data => {
          data.forEach((item) => {
            if (item.dateReceptionCr !== null) {
              item.dateReceptionCrToString = this.datepipe.transform(item.dateReceptionCr, this.dateFormater);
            } else {
              item.dateReceptionCrToString = 'non reçu';
            }
          });
          return data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(data => {
        this.dataCrRejected = data;
        this.nbCrRejected = this.dataCrRejected.length;
        this.dataCrRejectedChange.emit(this.nbCrRejected);
        this.initDataTableChart(this.crRejected);

        if (this.tableCrRejectedDisplayed) {
          this.setDataTable(this.crRejected);
        }

        if (this.sectionParam === 'CRKO' || this.sectionParam.match('OPERATION')) {
          this.onCrRejectedClick();
        }
      });
  }

  loadDataTableCrRejectedMasked() {
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.fluxediService.getAllOperationEdiGestipWithoutCrOrWithCrRejected(true);
        }),
        map(data => {
          data.forEach((item) => {
            if (item.dateReceptionCr !== null) {
              item.dateReceptionCrToString = this.datepipe.transform(item.dateReceptionCr, this.dateFormater);
            } else {
              item.dateReceptionCrToString = 'non reçu';
            }
          });
          return data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(data => {
        this.dataCrRejectedMasked = data;
        this.setDataTable(this.maskedCrRejected);
      });
  }

  initDataTableChart(dataType: string) {
    var chartTable;

    switch (dataType) {
      case this.noArl:
        chartTable = this.getChartTableFromGestipData(this.dataNoArl);
        this.chartDatasetsNoArl = [{
          data: chartTable,
          label: ''
        }];
        break;
      case this.arlRejected:
        chartTable = this.getChartTableFromGestipData(this.dataArlRejected);
        this.chartDatasetsArlRejected = [{
          data: chartTable,
          label: ''
        }];
        break;
      case this.noCr:
        chartTable = this.getChartTableFromGestipData(this.dataNoCr);
        this.chartDatasetsNoCr = [{
          data: chartTable,
          label: ''
        }];
        break;
      case this.crRejected:
        chartTable = this.getChartTableFromOperationEdiGestipData(this.dataCrRejected);
        this.chartDatasetsCrRejected = [{
          data: chartTable,
          label: ''
        }];
        break;
      default:
        return;
    }
  }

  getChartTableFromGestipData(data: Edi.GestipLight[]): any[] {
    let mon = 0, tue = 0, wed = 0, thu = 0, thr = 0, sat = 0, sun = 0, before = 0;

    const lastWeekDate = new Date();
    const threeMonthBefore = new Date();
    lastWeekDate.setHours(0, 0, 0);
    lastWeekDate.setDate(lastWeekDate.getDate() - 6);
    threeMonthBefore.setHours(0, 0, 0);
    threeMonthBefore.setDate(threeMonthBefore.getDate() - 120);

    data.forEach(item => {   
      const dateReception = new Date(item.dateReception);
      dateReception.setHours(0, 0, 0, 0);
      if (dateReception >= lastWeekDate) {
        const day = dateReception.getDay();
        switch (day) {
          case 0: { sun++; break; }
          case 1: { mon++; break; }
          case 2: { tue++; break; }
          case 3: { wed++; break; }
          case 4: { thu++; break; }
          case 5: { thr++; break; }
          case 6: { sat++; break; }
        }
      } else {
        if (dateReception >= threeMonthBefore) {
          before++;
        }
      }
    });

    let chartTableToReturn = [];
    const dayOfWeek = new Date().getDay();
    switch (dayOfWeek) {
      case 0: { chartTableToReturn = [before, tue, wed, thu, thr, sat, sun]; break; }
      case 1: { chartTableToReturn = [before, wed, thu, thr, sat, sun, mon]; break; }
      case 2: { chartTableToReturn = [before, thu, thr, sat, sun, mon, tue]; break; }
      case 3: { chartTableToReturn = [before, thr, sat, sun, mon, tue, wed]; break; }
      case 4: { chartTableToReturn = [before, sat, sun, mon, tue, wed, thu]; break; }
      case 5: { chartTableToReturn = [before, sun, mon, tue, wed, thu, thr]; break; }
      case 6: { chartTableToReturn = [before, mon, tue, wed, thu, thr, sat]; break; }
    }

    return chartTableToReturn;
  }

  getChartTableFromOperationEdiGestipData(data: Edi.OperationEdiGestip[]): any[] {
    let mon = 0, tue = 0, wed = 0, thu = 0, thr = 0, sat = 0, sun = 0, older = 0;

    const lastWeekDate = new Date();
    const threeMonthBefore = new Date();
    lastWeekDate.setHours(0, 0, 0);
    lastWeekDate.setDate(lastWeekDate.getDate() - 6);
    threeMonthBefore.setHours(0, 0, 0);
    threeMonthBefore.setDate(threeMonthBefore.getDate() - 120);

    data.forEach(operation => {
      const dateReception = new Date(operation.dateReceptionCr);
      dateReception.setHours(0, 0, 0, 0);
      if (dateReception >= lastWeekDate) {
        const dayOfWeek = dateReception.getDay();
        switch (dayOfWeek) {
          case 0: { sun++; break; }
          case 1: { mon++; break; }
          case 2: { tue++; break; }
          case 3: { wed++; break; }
          case 4: { thu++; break; }
          case 5: { thr++; break; }
          case 6: { sat++; break; }
        }
      } else {
        if (dateReception >= threeMonthBefore) {
          older++;
        }
      }
    });

    let chartTableToReturn = [];
    const currentDayOfWeek = new Date().getDay();
    switch (currentDayOfWeek) {
      case 0: { chartTableToReturn = [older, tue, wed, thu, thr, sat, sun]; break; }
      case 1: { chartTableToReturn = [older, wed, thu, thr, sat, sun, mon]; break; }
      case 2: { chartTableToReturn = [older, thu, thr, sat, sun, mon, tue]; break; }
      case 3: { chartTableToReturn = [older, thr, sat, sun, mon, tue, wed]; break; }
      case 4: { chartTableToReturn = [older, sat, sun, mon, tue, wed, thu]; break; }
      case 5: { chartTableToReturn = [older, sun, mon, tue, wed, thu, thr]; break; }
      case 6: { chartTableToReturn = [older, mon, tue, wed, thu, thr, sat]; break; }
    }

    return chartTableToReturn;
  }

  getPaginationDisabled() {
    if (this.currentDataTable.length <= 10) {
      return 'pagination-disabled';
    }
  }

  getPositionChartTooltip() {
    Chart.Tooltip.positioners.custom = function (elements, eventPosition) {
      /** @type {Chart.Tooltip} */
      return {
        x: eventPosition.x,
        y: eventPosition.y
      };
    };
  }

  refreshChart() {
    let higerValue = this.nbNoArl;
    if (this.nbArlRejected > higerValue) { higerValue = this.nbArlRejected; }
    if (this.nbNoCr > higerValue) { higerValue = this.nbNoCr; }
    if (this.nbCrRejected > higerValue) { higerValue = this.nbCrRejected; }

    higerValue = higerValue * 3 / 2;
    this.chartOptions.scales.yAxes[0].ticks.max = higerValue;
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
