import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { MdbTableDirective } from 'angular-bootstrap-md';
import { MatPaginator, PageEvent } from '@angular/material';
import { HeadElementViewData, BpijBenefitsSearchResult, BpijBenefitsTableViewModel } from '@app/_core/view-models';

@Component({
  selector: 'app-bpij-benefits-table',
  templateUrl: './bpij-benefits-table.component.html',
  styleUrls: ['./bpij-benefits-table.component.scss']
})
export class BpijBenefitsTableComponent implements OnInit, AfterViewInit {

  private bpijBenefitsTableViewModel: BpijBenefitsTableViewModel;
  public pageSize = 2;
  public rowLength = 0;
  public headElements: HeadElementViewData[];
  public dataTable: Models.Edi.BpijBenefitViewModel[];
  public dataResult: BpijBenefitsSearchResult = null;
  public displayTechnicalData = false;
  public isHidden = true;

  @ViewChild(MdbTableDirective, { static: false }) mdbTable: MdbTableDirective;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @Output()
  changePageIndexEvent = new EventEmitter<number>();
  @Output()
  changePageSizeEvent = new EventEmitter<number>();

  constructor(private cdRef: ChangeDetectorRef) {
    this.bpijBenefitsTableViewModel = new BpijBenefitsTableViewModel();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  onPaginationChange(event: PageEvent) {
    if (event.pageIndex != (this.dataResult.metadata.pageNumber - 1)) {
      this.changePageIndexEvent.emit(event.pageIndex + 1);
    } else if (event.pageSize != this.dataResult.metadata.pageSize) {
      this.changePageSizeEvent.emit(event.pageSize);
    }
  }

  public setData(dataResult: BpijBenefitsSearchResult, displayTechnicalData: boolean) {
    this.displayTechnicalData = displayTechnicalData;
    this.dataResult = dataResult;
    this.bpijBenefitsTableViewModel.setBpijBenefits(this.dataResult.payload);
    this.setDataTable();
  }

  private setDataTable() {
    this.rowLength = this.dataResult.metadata.hits;
    this.pageSize = this.dataResult.metadata.pageSize;
    this.paginator.length = this.rowLength;
    this.paginator.pageSize = this.pageSize;

    this.headElements = this.bpijBenefitsTableViewModel.getHeader(this.displayTechnicalData);
    this.dataTable = this.bpijBenefitsTableViewModel.dataTable;
  }
}
