import { StatisticsAverageDeliveryTimesViewModel } from "./statistics-average-delivery-times-view-model";
import DWH = Models.DataWarehouse;

export class StatisticsArlAverageDeliveryTimesViewModel extends StatisticsAverageDeliveryTimesViewModel {
    arlFlowsAverageDeliveryTimeInHours: number;
    arlFlowsDeliveryTimeAverages: DWH.ArlFlowsDeliveryTimeAverage[];

    constructor(model: DWH.EdiFlowsAverageDeliveryTimesModel) {
        super(model);

        this.arlFlowsAverageDeliveryTimeInHours = model.globalDeliveryTimeAverages.arlFlowsAverageDeliveryTimeInHours;
        this.arlFlowsDeliveryTimeAverages = model.arlFlowsDeliveryTimeAverages === null ? [] : model.arlFlowsDeliveryTimeAverages;
    }

}