import PrestiPrev = Models.PrestiPrev;

export class AlertSubscriptionFormModel {
  service: PrestiPrev.PrestiPrevService;
  alertTemplates: PrestiPrev.AlertTemplate[];
  frequencies: PrestiPrev.Frequency[];
  availableFrequencies: PrestiPrev.Frequency[];
  byNotification: boolean;
  byEmail: boolean;
  public static serviceToString = "service";
  public static alertTemplatesToString = "alertTemplates";
  public static frequenciesToString = "frequencies";
  public static byNotificationToString = "byNotification";
  public static byEmailToString = "byEmail";

  constructor() {
    this.alertTemplates = [];
    this.byEmail = false;
    this.byNotification = false;
    this.availableFrequencies = [
      { type: PrestiPrev.FrequencyType.periodicaly, label: 'Depuis 1 jour', passingDays: 1 },
      { type: PrestiPrev.FrequencyType.periodicaly, label: 'Depuis 2 jours', passingDays: 2 },
      { type: PrestiPrev.FrequencyType.periodicaly, label: 'Depuis 3 jours', passingDays: 3 },
      { type: PrestiPrev.FrequencyType.periodicaly, label: 'Depuis 4 jours', passingDays: 4 },
      { type: PrestiPrev.FrequencyType.periodicaly, label: 'Depuis 5 jours', passingDays: 5 },
      { type: PrestiPrev.FrequencyType.periodicaly, label: 'Depuis 6 jours', passingDays: 6 },
      { type: PrestiPrev.FrequencyType.periodicaly, label: 'Depuis 7 jours', passingDays: 7 },
      { type: PrestiPrev.FrequencyType.periodicaly, label: 'Depuis 8 jours', passingDays: 8 },
      { type: PrestiPrev.FrequencyType.periodicaly, label: 'Depuis 9 jours', passingDays: 9 },
      { type: PrestiPrev.FrequencyType.periodicaly, label: 'Depuis 10 jours', passingDays: 10 },
      { type: PrestiPrev.FrequencyType.periodicalyOpenDay, label: 'Depuis 1 jour ouvré', passingDays: 1 },
      { type: PrestiPrev.FrequencyType.periodicalyOpenDay, label: 'Depuis 2 jours ouvrés', passingDays: 2 },
      { type: PrestiPrev.FrequencyType.periodicalyOpenDay, label: 'Depuis 3 jours ouvrés', passingDays: 3 },
      { type: PrestiPrev.FrequencyType.periodicalyOpenDay, label: 'Depuis 4 jours ouvrés', passingDays: 4 },
      { type: PrestiPrev.FrequencyType.periodicalyOpenDay, label: 'Depuis 5 jours ouvrés', passingDays: 5 },
      { type: PrestiPrev.FrequencyType.periodicalyOpenDay, label: 'Depuis 6 jours ouvrés', passingDays: 6 },
      { type: PrestiPrev.FrequencyType.periodicalyOpenDay, label: 'Depuis 7 jours ouvrés', passingDays: 7 },
      { type: PrestiPrev.FrequencyType.periodicalyOpenDay, label: 'Depuis 8 jours ouvrés', passingDays: 8 },
      { type: PrestiPrev.FrequencyType.periodicalyOpenDay, label: 'Depuis 9 jours ouvrés', passingDays: 9 },
      { type: PrestiPrev.FrequencyType.periodicalyOpenDay, label: 'Depuis 10 jours ouvrés', passingDays: 10 },
      { type: PrestiPrev.FrequencyType.live, label: 'A chaque évènement', toHour: 99 },
      { type: PrestiPrev.FrequencyType.daily, label: 'Une fois par jour (à 13h)', toHour: 13 }
    ];
  }
}
