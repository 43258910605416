import { StatisticsFlowViewModel } from "./statistics-flow-view-model";
import DWH = Models.DataWarehouse;

export class StatisticsBpijReceivedFlowViewModel extends StatisticsFlowViewModel {
    totalNumberOfReceivedBpijFlows: number;
    bpijFlows: DWH.BpijFlow[];
    
    constructor(model: DWH.PeriodicEdiFlowsStatisticsModel) {
        super(model);

        this.totalNumberOfReceivedBpijFlows = model.globalStatistics.totalNumberOfReceivedBpijFlows;
        this.bpijFlows = model.bpijFlows === null ? [] : model.bpijFlows;
    }
}