import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Moment } from 'moment';
import { Observable } from 'rxjs';
import { GestipOperationsSearchResult } from '@app/_core/view-models/prestij/prestij-data/gestip-operations-table/gestip-operations-search-result';
import { GestipOperationsSearchModel } from '@app/_core/view-models/prestij/prestij-data/gestip-operations-table/gestip-operations-search-model';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class GestipService {

  private partnerId: string;
  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private authenticationService: AuthenticationService) {
    this.partnerId = this.authenticationService.currentUserValue.partnerGuid;
  }

  getGestipOperations(nir: string, siret: string, pageSize: number, pageNumber: number, dateFrom: Moment, dateTo: Moment): Observable<GestipOperationsSearchResult> {
    // Appel du .NetCore API Controller EDI local
    const headerOption = new HttpHeaders({ 'Content-Type': 'application/json' });
    const apiUrl = `api/gestip/partner/${this.partnerId}/search/operations`;

    const params: GestipOperationsSearchModel = {
      pageSize: pageSize,
      pageNumber: pageNumber,
      orderedFields: [
        { fieldName: 'SocietyIdentity', order: 'asc' },
        { fieldName: 'NirEmployee', order: 'asc' },
        { fieldName: 'CoverBeginDate', order: 'asc' }
      ],
      nir: nir,
      siret: siret,
      receiptDateFrom: dateFrom ? dateFrom.toDate() : null,
      receiptDateTo: dateTo ? dateTo.set({ hour: 23, minute: 59, second: 59 }).toDate() : null
    };
    return this.http.post<GestipOperationsSearchResult>(this.baseUrl + apiUrl, JSON.stringify(params), { headers: headerOption });
  }

  exportGestipOperations(nir: string, siret: string, dateFrom: Moment, dateTo: Moment): Observable<Blob> {
    // Appel du .NetCore API Controller EDI local
    const headerOption = new HttpHeaders({ 'Content-Type': 'application/json' });
    const apiUrl = `api/gestip/partner/${this.partnerId}/export/operations`;
    const params: GestipOperationsSearchModel = {
      pageSize: 0,
      pageNumber: 0,
      orderedFields: [
        { fieldName: 'receiptDate', order: 'desc' },
        { fieldName: 'siren', order: 'asc' },
        { fieldName: 'nir', order: 'asc' }
      ],
      nir: nir,
      siret: siret,
      receiptDateFrom: dateFrom ? dateFrom.toDate() : null,
      receiptDateTo: dateTo ? dateTo.set({ hour: 23, minute: 59, second: 59 }).toDate() : null
    };

    return this.http.post<Blob>(this.baseUrl + apiUrl, JSON.stringify(params), { responseType: 'blob' as 'json', headers: headerOption });
  }
}
