import { Injectable } from '@angular/core';
import { StatisticsArlAverageDeliveryTimesViewModel } from '@app/_core/view-models/datawarehouse/edi-flow/view-models-from-models/statistics-arl-average-delivery-times-view-model';
import { StatisticsAverageDeliveryTimesViewModel } from '@app/_core/view-models/datawarehouse/edi-flow/view-models-from-models/statistics-average-delivery-times-view-model';
import { StatisticsCrAverageDeliveryTimesViewModel } from '@app/_core/view-models/datawarehouse/edi-flow/view-models-from-models/statistics-cr-average-delivery-times-view-model';

import { StatisticsArlFlowForSentGestipFlowsViewModel } from '@app/_core/view-models/datawarehouse/edi-flow/view-models-from-models/statistics-arl-flow-for-sent-gestip-flows-view-model';
import { StatisticsArlReceivedFlowViewModel } from '@app/_core/view-models/datawarehouse/edi-flow/view-models-from-models/statistics-arl-received-flow-view-model';
import { StatisticsBpijReceivedFlowViewModel } from '@app/_core/view-models/datawarehouse/edi-flow/view-models-from-models/statistics-bpij-received-flow-view-model';
import { StatisticsCrFlowForSentGestipFlowsViewModel } from '@app/_core/view-models/datawarehouse/edi-flow/view-models-from-models/statistics-cr-flow-for-sent-gestip-flows-view-model';
import { StatisticsCrReceivedFlowViewModel } from '@app/_core/view-models/datawarehouse/edi-flow/view-models-from-models/statistics-cr-received-flow-view-model';
import { StatisticsFlowViewModel } from '@app/_core/view-models/datawarehouse/edi-flow/view-models-from-models/statistics-flow-view-model';
import { StatisticsGestipFlowViewModel } from '@app/_core/view-models/datawarehouse/edi-flow/view-models-from-models/statistics-gestip-flow-view-model';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})

export class DataFormattedService {
  readonly blue: string = "#6D87FE";
  readonly green: string = "#2AC769";
  readonly orange: string = "#F6A609";
  readonly red: string = "#FB4E4E";

  readonly gestip_envoye: string = "GESTIP envoyés";
  readonly bpij_reçus: string = "BPIJ reçus";
  readonly arl_non_reçus: string = "ARL non reçus";
  readonly arl_reçus: string = "ARL reçus";
  readonly cr_non_reçus: string = "CR non reçus";
  readonly cr_reçus: string = "CR reçus";
  readonly rejetes: string = "Rejetés";
  readonly acceptes: string = "Acceptés";

  getSeriesForColumnChart(transceiver: string, defaultTransceiverType: string, statisticsFlowViewModel: StatisticsFlowViewModel[]): any[] {
    let highChartsSeries: any[];
    let dataSerie1: number[] = [];
    let dataSerie2: number[] = [];
    let isGlobalStatistics: Boolean = transceiver === defaultTransceiverType;

    statisticsFlowViewModel.forEach(vm => {
      if (vm instanceof StatisticsGestipFlowViewModel) {
        isGlobalStatistics ? dataSerie1.push(vm.totalNumberOfSentGestipFlows) :
          dataSerie1 = dataSerie1.concat(vm.gestipFlows.filter(f => f.recipient.label === transceiver).map(f => f.numberOfSentFlows));
        highChartsSeries = [{ name: this.gestip_envoye, data: dataSerie1, color: this.blue }];
      }
      else if (vm instanceof StatisticsArlFlowForSentGestipFlowsViewModel) {
        isGlobalStatistics ? dataSerie1.push(vm.numberOfMissingArlFlowsForSentGestipFlows) :
          dataSerie1 = dataSerie1.concat(vm.gestipFlows.filter(f => f.recipient.label === transceiver).map(f => f.numberOfMissingArlFlows));
        isGlobalStatistics ? dataSerie2.push(vm.numberOfReceivedArlFlowsForSentGestipFlows) :
          dataSerie2 = dataSerie2.concat(vm.gestipFlows.filter(f => f.recipient.label === transceiver).map(f => f.numberOfReceivedArlFlows));
        highChartsSeries = [{ name: this.arl_non_reçus, data: dataSerie1, color: this.orange }, { name: this.arl_reçus, data: dataSerie2, color: this.green }];
      } else if (vm instanceof StatisticsCrFlowForSentGestipFlowsViewModel) {
        isGlobalStatistics ? dataSerie1.push(vm.numberOfMissingCrFlowsForSentGestipFlows) :
          dataSerie1 = dataSerie1.concat(vm.gestipFlows.filter(f => f.recipient.label === transceiver).map(f => f.numberOfMissingCrFlows));
        isGlobalStatistics ? dataSerie2.push(vm.numberOfReceivedCrFlowsForSentGestipFlows) :
          dataSerie2 = dataSerie2.concat(vm.gestipFlows.filter(f => f.recipient.label === transceiver).map(f => f.numberOfReceivedCrFlows));
        highChartsSeries = [{ name: this.cr_non_reçus, data: dataSerie1, color: this.orange }, { name: this.cr_reçus, data: dataSerie2, color: this.green }];
      }
      else if (vm instanceof StatisticsArlReceivedFlowViewModel) {
        isGlobalStatistics ? dataSerie1.push(vm.totalNumberOfRejectedArlFlows) :
          dataSerie1 = dataSerie1.concat(vm.arlFlows.filter(f => f.sender.label === transceiver).map(f => f.numberOfRejectedFlows));
        isGlobalStatistics ? dataSerie2.push(vm.totalNumberOfAcceptedArlFlows) :
          dataSerie2 = dataSerie2.concat(vm.arlFlows.filter(f => f.sender.label === transceiver).map(f => f.numberOfAcceptedFlows));
        highChartsSeries = [{ name: this.rejetes, data: dataSerie1, color: this.red }, { name: this.acceptes, data: dataSerie2, color: this.green }];
      }
      else if (vm instanceof StatisticsCrReceivedFlowViewModel) {
        isGlobalStatistics ? dataSerie1.push(vm.totalNumberOfReceivedCrFlows) :
          dataSerie1 = dataSerie1.concat(vm.crFlows.filter(f => f.sender.label === transceiver).map(f => f.numberOfReceivedFlows))
        highChartsSeries = [{ name: this.cr_reçus, data: dataSerie1, color: this.blue }];
      } else if (vm instanceof StatisticsBpijReceivedFlowViewModel) {
        isGlobalStatistics ? dataSerie1.push(vm.totalNumberOfReceivedBpijFlows) :
          dataSerie1 = dataSerie1.concat(vm.bpijFlows.filter(f => f.sender.label === transceiver).map(f => f.numberOfReceivedFlows))
        highChartsSeries = [{ name: this.bpij_reçus, data: dataSerie1, color: this.blue }];
      }
    });

    return highChartsSeries;
  }

  getSeriesForColumnChartForAverageDeliveryTimes(transceiver: string, statisticsAverageDeliveryTimesViewModel: StatisticsAverageDeliveryTimesViewModel[]): any[] {
    let highChartsSeries: any[];
    let serie: any[] = []; // format HC : [{ name: '1h54min', y: 1.9 }, { name: , y: }];
    let isGlobalStatistics: Boolean = (transceiver === "Tout émetteur/destinataire");

    statisticsAverageDeliveryTimesViewModel.forEach(vm => {
      if (vm instanceof StatisticsArlAverageDeliveryTimesViewModel) {
        let averageDeliveryTimeInHours: number = isGlobalStatistics ? vm.arlFlowsAverageDeliveryTimeInHours : vm.arlFlowsDeliveryTimeAverages
          .filter(f => f.recipient.label === transceiver).map(f => f.averageDeliveryTimeInHours)[0];
        let averageDeliveryTimeToString = this.convertNumbertoString(averageDeliveryTimeInHours);
        serie.push({ name: averageDeliveryTimeToString, y: averageDeliveryTimeInHours });
        highChartsSeries = [{ data: serie, color: this.blue }];
      }
      else if (vm instanceof StatisticsCrAverageDeliveryTimesViewModel) {
        let averageDeliveryTimeInHours: number = isGlobalStatistics ? vm.crFlowsAverageDeliveryTimeInHours : vm.crFlowsDeliveryTimeAverages
          .filter(f => f.recipient.label === transceiver).map(f => f.averageDeliveryTimeInHours)[0];
        let averageDeliveryTimeToString = this.convertNumbertoString(averageDeliveryTimeInHours);
        serie.push({ name: averageDeliveryTimeToString, y: averageDeliveryTimeInHours });
        highChartsSeries = [{ data: serie, color: this.blue }];
      }
    });

    return highChartsSeries;
  }

  convertNumbertoString(averageDeliveryTime: number): string {
    let averageTime = moment.utc(moment.duration(averageDeliveryTime, "hours").asMilliseconds()).format("HH:mm");
    let splittedAverageTime = averageTime.split(':');
    return splittedAverageTime[0] + 'h' + splittedAverageTime[1] + 'min';
  }
}
