import { Component, ViewChild, ElementRef } from '@angular/core';
import { CommonVar } from '@app/_core/models';
import { Menu, MenuLinks } from '@app/_core/menu';
import { environment } from '../../environments/environment';
import { RouterLinkActive, ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})

export class NavMenuComponent {
  menuLinks: MenuLinks[];
  toggleDropDown = true;

  height = window.innerHeight;

  public currentMenuSelected: any = null;
  public showCurrentLocation = true;

  constructor(private global: CommonVar, private location: Location, private router: Router) {
    this.menuLinks = new Menu().getMenuLinks(true);
  }

  /* VDE > switch class active si menu selected & desactive le sous menu */
  onMenuSelected(menuLink) {
    if (location.pathname.includes(menuLink.routeurLink)) {
      this.showCurrentLocation = !this.showCurrentLocation;
      //return;
    }

    if (menuLink === this.currentMenuSelected) {
      this.currentMenuSelected = null;
    } else {
      this.currentMenuSelected = menuLink;
    }

    if (menuLink.hasSubMenu) {
      this.toggleDropDown = !this.toggleDropDown;
    }
  }

  isMenuActive(menuLink: MenuLinks) {
    return this.currentMenuSelected === menuLink || (this.showCurrentLocation && location.pathname.includes(menuLink.routeurLink));
  }

  getRouteurLink(menuLink) {
    return [menuLink.routeurLink];
  }
}
