import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import DWH = Models.DataWarehouse;
import { Period } from '../../../../_core/view-models/datawarehouse/edi-flow/view-models-from-views/period';
import { GranularityType } from '../../../../_core/view-models/datawarehouse/edi-flow/view-models-from-views/granularity-type';
import { PeriodByGranularityService } from '../../services/period-by-granularity.service';
import { DataWarehouseService } from '../../../../_services/data-warehouse.service';
import { ManageTransceiverTypeService } from '../../services/manage-transceiver-type.service';
import { DataFormattedService } from '../../services/data-formatted.service';
import { OptionsStatEdiService } from '../../services/options-stat-edi.service';
import { PeriodFormattedService } from '../../services/period-formatted.service';
import { ModelFormattedService } from '../../services/model-formatted.service';
import { OptionType } from '../../../../_core/view-models/datawarehouse/edi-flow/view-models-from-views/option-type';
import { MatRadioChange } from '@angular/material';
import { StatisticsCrAverageDeliveryTimesViewModel } from '../../../../_core/view-models/datawarehouse/edi-flow/view-models-from-models/statistics-cr-average-delivery-times-view-model';
import { PeriodCrReceivedService } from './period-cr-received.service';

@Component({
  selector: 'app-period-cr-received',
  templateUrl: './period-cr-received.component.html',
  styleUrls: ['./period-cr-received.component.scss'],
  providers: [PeriodCrReceivedService]
})
/** period-cr-received component*/
export class PeriodCrReceivedComponent {
  model: DWH.EdiFlowsAverageDeliveryTimesModel[];
  currentVm: StatisticsCrAverageDeliveryTimesViewModel[];
  subscribeModel: Subscription;

  currentPeriod: Period;
  currentPeriodDays: Period;
  currentPeriodMonths: Period;
  navigationPeriodDisabled: boolean;

  optionsChart: Highcharts.Options;
  seriesChart: any[] = [];
  categoriesChart: any[] = [];

  currentGranularity: GranularityType;
  granularities: GranularityType[];

  currentTransceiver: string;
  transceivers: string[];

  readonly defaultTransceiverType: string = "Tout émetteur/destinataire";

  constructor(private periodCrReceivedService: PeriodCrReceivedService,
    private periodByGranularityService: PeriodByGranularityService,
    private dataWarehouseService: DataWarehouseService,
    private manageTransceiverTypeService: ManageTransceiverTypeService,
    private dataFormattedService: DataFormattedService,
    private optionsPeriodCrReceived: OptionsStatEdiService,
    private periodFormattedService: PeriodFormattedService,
    private modelFormattedService: ModelFormattedService) {
  }

  ngOnInit(): void {
    this.currentGranularity = GranularityType.Days;
    this.granularities = [GranularityType.Days, GranularityType.Months];
    this.currentTransceiver = this.defaultTransceiverType;

    this.currentPeriodDays = this.periodByGranularityService.getDefaultPeriodByGranularity(GranularityType.Days);
    this.currentPeriodMonths = this.periodByGranularityService.getDefaultPeriodByGranularity(GranularityType.Months);
    this.currentPeriod = this.currentPeriodDays;
    this.navigationPeriodDisabled = true;

    this.setData(this.currentPeriod);

    this.optionsChart = this.optionsPeriodCrReceived.getColumnChartOptions(OptionType.PERIOD_CR_RECEIVED);
    this.categoriesChart = this.periodFormattedService.getDateRangeFormattedByGranularity(this.currentGranularity, this.currentPeriod);
  }

  onChangeGranularity(newGranularity: MatRadioChange) {
    this.currentGranularity = newGranularity.value;
    this.currentPeriod = this.periodByGranularityService.getPeriodAfterSwitchGranularity(this.currentGranularity, this.currentPeriodDays, this.currentPeriodMonths);
    this.navigationPeriodDisabled = this.periodByGranularityService.isCurrentPeriodEqualDefaultPeriod(this.currentGranularity, this.currentPeriod);

    this.setData(this.currentPeriod);

    this.categoriesChart = this.periodFormattedService.getDateRangeFormattedByGranularity(this.currentGranularity, this.currentPeriod);
  }

  onChangeTransceivers(newTransceivers: any) {
    this.currentTransceiver = newTransceivers;
    this.seriesChart = this.dataFormattedService.getSeriesForColumnChartForAverageDeliveryTimes(this.currentTransceiver, this.currentVm);
  }

  onPreviousPeriod() {
    this.setCurrentPeriod(
      this.periodByGranularityService.getPreviousPeriodByGranularity(GranularityType.Days, this.currentPeriodDays),
      this.periodByGranularityService.getPreviousPeriodByGranularity(GranularityType.Months, this.currentPeriodMonths)
    );
  }

  onNextPeriod() {
    this.setCurrentPeriod(
      this.periodByGranularityService.getNextPeriodByGranularity(GranularityType.Days, this.currentPeriodDays),
      this.periodByGranularityService.getNextPeriodByGranularity(GranularityType.Months, this.currentPeriodMonths)
    )
  }

  private setData(currentPeriod: Period): void {
    this.subscribeModel = this.dataWarehouseService.getModelAverageDeliveryTimes(this.currentGranularity, currentPeriod)
      .subscribe(data => {
        this.model = this.modelFormattedService.getPatchEdiFlowsAverageDeliveryTimesModel(data, this.currentGranularity, currentPeriod);
        this.currentVm = this.periodCrReceivedService.getViewModel(this.model);
        this.transceivers = this.manageTransceiverTypeService.getTransceiverTypeForAverageDeliveryTimes(this.currentVm);
        if (this.transceivers.indexOf(this.currentTransceiver) < 0) {
          this.currentTransceiver = this.transceivers[0];
        }
        this.seriesChart = this.dataFormattedService.getSeriesForColumnChartForAverageDeliveryTimes(this.currentTransceiver, this.currentVm);
      });
  }

  private setCurrentPeriod(daysPeriod: Period, monthPeriod: Period) {
    if (this.currentGranularity === GranularityType.Days) {
      this.currentPeriodDays = daysPeriod;
      this.currentPeriod = this.currentPeriodDays;
    } else {
      this.currentPeriodMonths = monthPeriod;
      this.currentPeriod = this.currentPeriodMonths;
    }
    this.navigationPeriodDisabled = this.periodByGranularityService.isCurrentPeriodEqualDefaultPeriod(this.currentGranularity, this.currentPeriod);

    this.setData(this.currentPeriod);

    this.categoriesChart = this.periodFormattedService.getDateRangeFormattedByGranularity(this.currentGranularity, this.currentPeriod);
  }

  ngOnDestroy() {
    this.subscribeModel.unsubscribe();
  }
}
