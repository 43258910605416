import { Injectable } from '@angular/core';
import { conformToMask } from 'angular2-text-mask';

@Injectable()
export class NirSirenValidationHelper {
  public static readonly SIREN_OR_SIRET = { label: "Par SIREN/SIRET", toString() { return "SIREN"; } };
  public static readonly SIREN = { label: "Par SIREN", toString() { return "SIREN"; } };
  public static readonly NIR = { label: "Par NIR", toString() { return "NIR"; } };
  public static readonly ALL = { label: "Tout", toString() { return "ALL"; } };

  public static readonly NIR_REQUIRED = 'NIR requis';
  public static readonly NIR_INVALID = 'NIR invalide';
  public static readonly SIREN_REQUIRED = 'SIREN/SIRET requis';
  public static readonly SIREN_INVALID = 'SIREN/SIRET invalide';

  public static nirOptionsMask = [/\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d|[A-B]/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/];
  public static nirMask = [/\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d|[AB]/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/];
  public static sirenFullMask = [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/];
  public static sirenSmallMask = [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/];

  constructor() {
  }

  public static isSiren(type: any): boolean {
    if (type == NirSirenValidationHelper.SIREN || type == NirSirenValidationHelper.SIREN_OR_SIRET)
      return true;
    else
      return false;
  }

  public static check(number: string, type: any): string {
    const isSiren = this.isSiren(type);
    if (!number.length)
      return isSiren ? NirSirenValidationHelper.SIREN_REQUIRED : NirSirenValidationHelper.NIR_REQUIRED;

    number = number.replace(/[^\dAB]/g, '');
    if (!isSiren) {
      if (number.length !== 13 && number.length !== 15) {
        return this.NIR_INVALID;
      }
    } else {
      if (number.length !== 9 && number.length !== 14) {
        return this.SIREN_INVALID;
      }
    }

    return null;
  }

  public static getMask(type: any, isOpGestip: boolean): (d: string) => any {
    if (isOpGestip)
      return this.isSiren(type) ? NirSirenValidationHelper.maskSiretGestip : NirSirenValidationHelper.maskNir;
    else
      return this.isSiren(type) ? NirSirenValidationHelper.maskSiretBpij : NirSirenValidationHelper.maskNir;
  }

  public static formatNir(nir: string): string {
    if (nir)
      return conformToMask(nir, NirSirenValidationHelper.maskNirForDataTable, { guide: false }).conformedValue; // format for DataTable
    else
      return '';
  }

  public static formatSiret(siret: string): string {
    if (siret)
      return conformToMask(siret, NirSirenValidationHelper.maskSiretBpij, { guide: false }).conformedValue; // format for DataTable using the same formatting function as bpij input
    else
      return '';
  }


  public static maskNirForDataTable(rawValue: string) {
    rawValue = rawValue.replace(/[^\dAB]/g, '');
    if (rawValue.length <= 13)
      return NirSirenValidationHelper.nirMask;
    else
      return NirSirenValidationHelper.nirOptionsMask;
  }

  public static maskNir(rawValue: string) {
    return NirSirenValidationHelper.nirMask;
  }

  public static maskSiretBpij(rawValue: string) {
    rawValue = rawValue.replace(/[^\dAB]/g, '');
    if (rawValue.length <= 9)
      return NirSirenValidationHelper.sirenSmallMask;
    else
      return NirSirenValidationHelper.sirenFullMask;
  }

  public static maskSiretGestip(rawValue: string) {
    return NirSirenValidationHelper.sirenSmallMask;
  }
}
