import { Injectable } from "@angular/core";
import DWH = Models.DataWarehouse;
import { StatisticsCrReceivedFlowViewModel } from "../../../../_core/view-models/datawarehouse/edi-flow/view-models-from-models/statistics-cr-received-flow-view-model";

@Injectable({
  providedIn: 'root'
})

export class CrReceivedService {
  getViewModel(model: DWH.PeriodicEdiFlowsStatisticsModel[]): StatisticsCrReceivedFlowViewModel[] {
    let vm: StatisticsCrReceivedFlowViewModel[];

    vm = model.map(m => new StatisticsCrReceivedFlowViewModel(m));

    return vm;
  }
}
