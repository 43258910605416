import { Injectable } from '@angular/core';
import { StatisticsFlowViewModel } from '@app/_core/view-models/datawarehouse/edi-flow/view-models-from-models/statistics-flow-view-model';
import { StatisticsGestipArlCrFlowViewModel } from '@app/_core/view-models/datawarehouse/edi-flow/view-models-from-models/statistics-gestip-arl-cr-flow-view-model';
import { StatisticsArlReceivedFlowViewModel } from '@app/_core/view-models/datawarehouse/edi-flow/view-models-from-models/statistics-arl-received-flow-view-model';
import { StatisticsCrReceivedFlowViewModel } from '@app/_core/view-models/datawarehouse/edi-flow/view-models-from-models/statistics-cr-received-flow-view-model';
import { StatisticsBpijReceivedFlowViewModel } from '@app/_core/view-models/datawarehouse/edi-flow/view-models-from-models/statistics-bpij-received-flow-view-model';
import { StatisticsAverageDeliveryTimesViewModel } from '@app/_core/view-models/datawarehouse/edi-flow/view-models-from-models/statistics-average-delivery-times-view-model';
import { StatisticsArlAverageDeliveryTimesViewModel } from '@app/_core/view-models/datawarehouse/edi-flow/view-models-from-models/statistics-arl-average-delivery-times-view-model';
import { StatisticsCrAverageDeliveryTimesViewModel } from '@app/_core/view-models/datawarehouse/edi-flow/view-models-from-models/statistics-cr-average-delivery-times-view-model';

@Injectable({
    providedIn: 'root'
})

export class ManageTransceiverTypeService {

    /**
     * defaultTransceiverType : premier élément de la DDL (liste déroulante) des Transceivers (valeur par défaut)
     * valeurs possibles dans la liste déroulante : PUBLIFLUX, ELCIMAI ou CNAMTS (en plus de la valeur par défaut)
     * vérification qu'il n'y a pas de doublons dans les labels des Transceivers (demande faite par le back-end / API Rest)
     */
    getTransceiverType(statisticsFlowViewModel: StatisticsFlowViewModel[], defaultTransceiverType: string) {
        let transceivers: string[] = [defaultTransceiverType];
        for (let vm of statisticsFlowViewModel) {
            if (vm instanceof StatisticsGestipArlCrFlowViewModel) {
                transceivers = transceivers.concat(vm.gestipFlows.filter(f => transceivers.indexOf(f.recipient.label.toUpperCase()) < 0).map(f => f.recipient.label.toUpperCase()));
            } else if (vm instanceof StatisticsArlReceivedFlowViewModel) {
                transceivers = transceivers.concat(vm.arlFlows.filter(f => transceivers.indexOf(f.sender.label.toUpperCase()) < 0).map(f => f.sender.label.toUpperCase()));
            } else if (vm instanceof StatisticsCrReceivedFlowViewModel) {
                transceivers = transceivers.concat(vm.crFlows.filter(f => transceivers.indexOf(f.sender.label.toUpperCase()) < 0).map(f => f.sender.label.toUpperCase()));
            } else if (vm instanceof StatisticsBpijReceivedFlowViewModel) {
                transceivers = transceivers.concat(vm.bpijFlows.filter(f => transceivers.indexOf(f.sender.label.toUpperCase()) < 0).map(f => f.sender.label.toUpperCase()));
            }
            if (transceivers.length === 4) {
                break;
            }
        }
        return transceivers;
    }

    /**
    * valeurs possibles dans la liste déroulante : PUBLIFLUX, ELCIMAI ou CNAMTS (en plus de la valeur par défaut)
    * vérification qu'il n'y a pas de doublons dans les labels des Transceivers (demande faite par le back-end / API Rest)
    */
    getTransceiverTypeForAverageDeliveryTimes(statisticsAverageDeliveryTimesViewModel: StatisticsAverageDeliveryTimesViewModel[]) {
        let transceivers: string[] = ["Tout émetteur/destinataire"];
        for (let vm of statisticsAverageDeliveryTimesViewModel) {
            if (vm instanceof StatisticsArlAverageDeliveryTimesViewModel) {
                transceivers = transceivers.concat(vm.arlFlowsDeliveryTimeAverages.filter(f => transceivers.indexOf(f.recipient.label.toUpperCase()) < 0).map(f => f.recipient.label.toUpperCase()));
            } else if (vm instanceof StatisticsCrAverageDeliveryTimesViewModel) {
                transceivers = transceivers.concat(vm.crFlowsDeliveryTimeAverages.filter(f => transceivers.indexOf(f.recipient.label.toUpperCase()) < 0).map(f => f.recipient.label.toUpperCase()));
            }
            if (transceivers.length === 4) {
                break;
            }
        }
        return transceivers;
    }

}

