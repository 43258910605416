import { Injectable } from '@angular/core';
import { BpijService } from '@app/_services/bpij.service';
import { GestipService } from '@app/_services/gestip.service';
import { saveAs } from 'file-saver';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class PrestijDataService {
  constructor(private bpijService: BpijService, private gestipService: GestipService) { }

  public exportBpijBenefits(nir: string, siren: string, dateFrom: moment.Moment, dateTo: moment.Moment) {
    this.bpijService.exportDailyBenefits(nir, siren, dateFrom, dateTo)
      .subscribe(blob => {
        saveAs(blob, `export_prestations-bpij_${moment().format('DD-MM-yyyy HH-mm')}.csv`);
      }, error => {
      });
  }

  public exportBpijBenefitsFromReceiptDates(nir: string, siren: string, dateFrom: moment.Moment, dateTo: moment.Moment) {
    this.bpijService.exportBenefitsFromReceiptDates(nir, siren, dateFrom, dateTo)
      .subscribe(blob => {
        saveAs(blob, `export_prestations-bpij_${moment().format('DD-MM-yyyy HH-mm')}.csv`);
      }, error => {
      });
  }

  public exportGestipOperations(nir: string, siret: string, dateFrom: moment.Moment, dateTo: moment.Moment) {
    this.gestipService.exportGestipOperations(nir, siret, dateFrom, dateTo)
      .subscribe(blob => {
        saveAs(blob, `export_operations-gestip_${moment().format('DD-MM-yyyy HH-mm')}.csv`);
      }, error => {
      });
  }
}
