import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef, Input, HostListener, Renderer2, ElementRef } from '@angular/core';
import { MdbTableDirective, MdbTablePaginationComponent } from 'angular-bootstrap-md';
import { merge, Observable, of as observableOf, throwError } from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { FluxEdiService } from '../../../_services';
import Edi = Models.Edi;
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder } from '@angular/forms';
import { RouterLinkWithHref } from '@angular/router';

@Component({
  selector: 'app-modal-table',
  templateUrl: './modal-table.component.html',
  styleUrls: ['./modal-table.component.scss']
})
/** modal-table component*/
export class ModalTableComponent implements OnInit, AfterViewInit {
  @ViewChild(MdbTableDirective, { static: false }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: false }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild('operation', { static: false }) operation: ElementRef;

  @Input() modalData: any;

  completeModalDataTable = new Array<Edi.OperationEdiGestip>();
  filteredModalDataTable = new Array<Edi.OperationEdiGestip>();
  previousModalDataTable = new Array<Edi.OperationEdiGestip>();
  modalHeadElements = [];

  filtres = new Map();

  maxVisibleItems = 10;

  sortingElement = '';
  searchText = '';
  dateFormater = 'dd/MM/yyyy HH:mm';
  filterDestinataireElem = 'Tous';
  filterStatutCrElem = 'Tous';
  notRecieved = 'Non reçu';

  modalTableHide = true;
  hasfilterDestinataire = false;
  hasfilterStatutCr = false;
  canRefresh = false;
  toggle = false;
  notFound = false;

  constructor(public fluxediService: FluxEdiService,
    private cdRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    public datepipe: DatePipe) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.loadModalDataTable(this.modalData);
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.maxVisibleItems);
    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.mdbTablePagination.ofKeyword = 'sur';

    this.filtres.set('search', item => this.searchFilter(item, this.searchText));
    this.cdRef.detectChanges();
  }

  onFilterClick(filter: string, elem: string, indexFilter: number) {
    this.applyFilterGestip(filter, elem, indexFilter);
    this.mdbTablePagination.firstPage();
  }

  getFilterClass(elemId: string) {
    if (elemId === 'destinataire') {
      if (this.hasfilterDestinataire) {
        return 'filtering';
      } else {
        return 'filter';
      }
    }
    if (elemId === 'statutCr') {
      if (this.hasfilterStatutCr) {
        return 'filtering';
      } else {
        return 'filter';
      }
    }
  }

  getPaginationDisabled(length: number): string {
    if (length <= 10) {
      return 'pagination-disabled';
    }
  }

  getCrStatutClass(status: string): string {
    if (status === 'KO') {
      return 'text-error';
    } else if (status === 'OK') {
      return 'text-valid';
    } else if (status === this.notRecieved) {
      return 'text-warning';
    } else {
      return '';
    }
  }

  disabledOff(data: any[]) {
    data.forEach(function (element) {
      if (element.default === true) {
        element.default = false;
      }
    });
  }

  newDisabledOn(data: any[], indexFilter: number) {
    data[indexFilter].default = true;
  }

  findHeadIndex(elemId: string): number {
    const headIndex = this.modalHeadElements.findIndex(item => item.id === elemId);
    return headIndex;
  }

  findFilterIndex(headIndex: number, elemId: string) {
    const filterIndex = this.modalHeadElements[headIndex].dataFilter.findIndex(item => item.id === elemId);
    return filterIndex;
  }

  applyFilterGestip(filter: string, elemId: string, indexFilter: number) {
    this.filteredModalDataTable = this.completeModalDataTable;

    this.applyFilter(filter, elemId, indexFilter);

    if (filter === 'Tous') {
      this.filtres.delete(elemId);
    } else {
      let filterItem;
      if (elemId === 'destinataire') {
        filterItem = item => item.libelleDestinataire === filter;
      } else if (elemId === 'statutCr') {
        filterItem = item => item.diagnostic !== null && item.diagnostic.etat === filter;
      }
      this.filtres.set(elemId, filterItem);
    }

    this.applyFiltersTable();
  }

  applyFilter(filter: string, elemId: string, indexFilter: number) {
    switch (elemId) {
      case 'destinataire': {
        this.filterDestinataireElem = filter;
        this.disabledOff(this.modalHeadElements[this.findHeadIndex(elemId)].dataFilter);
        this.newDisabledOn(this.modalHeadElements[this.findHeadIndex(elemId)].dataFilter, indexFilter);
        this.hasfilterDestinataire = true;
        if (filter === 'Tous') {
          this.hasfilterDestinataire = false;
        }
        break;
      }
      case 'statutCr': {
        this.filterStatutCrElem = filter;
        this.disabledOff(this.modalHeadElements[this.findHeadIndex(elemId)].dataFilter);
        this.newDisabledOn(this.modalHeadElements[this.findHeadIndex(elemId)].dataFilter, indexFilter);
        this.hasfilterStatutCr = true;
        if (filter === 'Tous') {
          this.hasfilterStatutCr = false;
        }
        break;
      }
    }
  }

  searchFilter(item: any, text: string) {
    var fields = [
      item.operation,
      item.entiteOperation.dateDebut,
      item.entiteOperation.dateFin,
      item.entiteOperation.nirSalarie,
      item.institutionPrev,
      item.entiteOperation.identiteEntreprise,
      item.entiteOperation.nom,
      item.entiteOperation.prenom,
      item.entiteOperation.dateDebutRetro,
      item.entiteOperation.typePeentiteOperation
    ].join(" ").toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/, "");

    var searchInput = text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/, "").split(/[\s,]/);
    for (const s of searchInput) {
      if (!fields.includes(s)) {
        return false;
      }
    } 
    return true;
  }



  searchItems() {
    this.applyFiltersTable();
  }

  applyFiltersTable() {
    this.filteredModalDataTable = this.completeModalDataTable.filter(item => {
      for (const filter of this.filtres.values()) {
        if (!filter(item)) {
          return false;
        }
      }
      return true;
    });
    if (this.filteredModalDataTable.length === 0 && this.searchText !== "") {
      this.notFound = true;
      this.modalTableHide = true;
    } else {
      this.notFound = false;
      this.modalTableHide = false;
    }
    this.mdbTable.setDataSource(this.filteredModalDataTable);
  }

  removeContent() {
    this.searchText = '';
    this.applyFiltersTable();
    this.modalTableHide = false;
  }

  setModalDataTable(dataTable: any) {
    // Chargement du tableau
    this.mdbTable.setDataSource(dataTable);
    this.filteredModalDataTable = this.mdbTable.getDataSource();
    this.completeModalDataTable = this.filteredModalDataTable;

    this.canRefresh = false;
    this.modalTableHide = false;
  }

  loadStatutCrFilter(dataTable: any) {
    const statutList = [];
    dataTable.forEach(item => {
      if (item.diagnostic === null) { return; }
      if (item.diagnostic.etat !== this.notRecieved) {
        if (!statutList.includes(item.diagnostic.etat === 'OK' ? 'OK' : 'KO')) {
          statutList.push({ id: item.diagnostic.etat === 'OK' ? 'OK' : 'KO', color: this.getCrStatutClass(item.diagnostic.etat === 'OK' ? 'OK' : 'KO'), default: false });
        }
      } else {
        if (!statutList.includes(item.diagnostic.etat)) {
          statutList.push({ id: item.diagnostic.etat, color: this.getCrStatutClass(item.diagnostic.etat), default: false });
        }
      }
    });

    // Création de l'array filtre final SANS doublons
    const data = Array.from(new Set(statutList.map(a => a.id)))
      .map(id => {
        return statutList.find(a => a.id === id);
      });
    data.push({ id: 'Tous', color: this.getCrStatutClass('Tous'), default: true });
    const index = this.modalHeadElements.findIndex(item => item.id === 'statutCr');
    this.modalHeadElements[index].dataFilter = data;
  }

  loadDestinataireFilter(dataTable: any) {
    const datas = [];
    dataTable.forEach(function (item) {
      if (item.libelleDestinataire === null) {
        if (!datas.includes(item.identiteInstitutionPrev) && item.identiteInstitutionPrev !== null) {
          datas.push({ id: item.identiteInstitutionPrev, default: false });
        }
      } else {
        if (!datas.includes(item.libelleDestinataire)) {
          datas.push({ id: item.libelleDestinataire, default: false });
        }
      }
    });

    // Création de l'array filtre final SANS doublons
    const data = Array.from(new Set(datas.map(a => a.id)))
      .map(id => {
        return datas.find(a => a.id === id);
      });
    data.push({ id: 'Tous', default: true });
    const index = this.modalHeadElements.findIndex(item => item.id === 'destinataire');
    this.modalHeadElements[index].dataFilter = data;
  }

  loadModalDataTable(fluxEdiId: Edi.OperationEdiGestip) {
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.fluxediService.getAllOperationEdiGestipFromFluxEdiGestip(fluxEdiId);
        }),
        map(data => {
          data.forEach((item) => {
            if (item.dateReceptionCr !== null) {
              item.dateReceptionCrToString = this.datepipe.transform(item.dateReceptionCr, this.dateFormater);
            } else {
              item.dateReceptionCrToString = this.notRecieved;
            }

            if (item.diagnostic) {
              if (item.diagnostic.cause === null) {
                item.diagnostic.cause = '';
              }
              if (item.diagnostic.etat === 'R') {
                item.diagnostic.etat = 'KO';
              } else {
                item.diagnostic.etat = 'OK';
              }
            }

            try {
              const opePrev = (item.entiteOperation as Edi.OperationEdiPrevoyance);
              opePrev.dateDebut = this.getLocalDate(opePrev.dateDebut);
              opePrev.dateFin = this.getLocalDate(opePrev.dateFin);
              opePrev.dateDebutRetro = this.getLocalDate(opePrev.dateDebutRetro);
            } catch { }
          });
          return data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(data => {
        this.loadModalHeadElements();
        this.setModalDataTable(data);
        this.loadDestinataireFilter(data);
        this.loadStatutCrFilter(data);
      });
  }

  loadModalHeadElements() {
    this.modalHeadElements = [
      { id: 'identificationGestip', head: 'ID GESTIP', dataFilter: [], filter: false, sortable: true, toggle: false },
      { id: 'destinataire', head: 'Destin.', dataFilter: [], filter: true, sortable: false },
      { id: 'dateReceptionCrToString', head: 'Date de réception CR', dataFilter: [], filter: false, sortable: true, toggle: false },
      { id: 'operationParent', head: 'Parent', dataFilter: [], filter: false, sortable: false },
      { id: 'operation', head: 'Opération', dataFilter: [], filter: false, sortable: false },
      { id: 'statutCr', head: 'Statut CR', dataFilter: [], filter: true, sortable: false },
      { id: 'diagnostic.cause', head: 'Code Cause', dataFilter: [], filter: false, sortable: true, toggle: false },
      { id: 'diagnostic.libelle', head: 'Libéllé cause', dataFilter: [], filter: false, sortable: true, toggle: false },
    ];
  }

  getLocalDate(usDate: string) {
    if (usDate.length < 10) { return usDate; }

    const year = usDate.substr(0, 4);
    const month = usDate.substr(5, 2);
    const day = usDate.substr(8, 2);

    return day + '/' + month + '/' + year;
  }
}
