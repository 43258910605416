import { Directive, ElementRef, Renderer2, HostListener, AfterViewInit } from '@angular/core';


@Directive({
  selector: '[appEllipsifyMe]'
})
export class EllipsifyMeDirective implements AfterViewInit {

  domElement: any;
  constructor(private elementRef: ElementRef) {
    this.domElement = this.elementRef.nativeElement;
  }

  ngAfterViewInit(): void {
    (this.domElement.offsetWidth < this.domElement.scrollWidth) ?
      true : false;
  }
}
