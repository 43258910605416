import { Injectable } from "@angular/core";
import { GranularityType } from "@app/_core/view-models/datawarehouse/edi-flow/view-models-from-views/granularity-type";
import { Period } from "@app/_core/view-models/datawarehouse/edi-flow/view-models-from-views/period";
import DWH = Models.DataWarehouse;
import * as moment from 'moment';
import { Moment } from "moment";

@Injectable({
    providedIn: 'root'
})

export class ModelFormattedService {

    getPatchPeriodicEdiFlowsStatisticsModel(periodicEdiFlowsStatisticsModel: DWH.PeriodicEdiFlowsStatisticsModel[], granularity: GranularityType, period: Period) {
        periodicEdiFlowsStatisticsModel = periodicEdiFlowsStatisticsModel.reverse();

        let dateWithModel: string[] = [];
        let expectedLength = granularity === GranularityType.Days ? 30 : 12;
        if (periodicEdiFlowsStatisticsModel.length !== expectedLength) {

            periodicEdiFlowsStatisticsModel.forEach(model => {
                dateWithModel.push(moment(model.statisticsPeriodStartDate).format("YYYY-MM-DD"));
            });

            let duration = (granularity === GranularityType.Days ? moment.duration(1, 'days') : moment.duration(1, 'month'));
            let currentBeginDate: Moment = period.beginDate.clone().locale('fr');
            let dateWithoutModel: string[] = [];
            while (currentBeginDate.toDate() <= period.endDate.toDate()) {
                if (dateWithModel.includes(currentBeginDate.format("YYYY-MM-DD")) === false) {
                    dateWithoutModel.push(currentBeginDate.format("YYYY-MM-DD"));
                }
                currentBeginDate = currentBeginDate.add(duration);
            }

            this.AddModelWithGlobalStatistics(periodicEdiFlowsStatisticsModel, dateWithoutModel, granularity, period);

            periodicEdiFlowsStatisticsModel = periodicEdiFlowsStatisticsModel.sort((m1, m2) => {
                if (m1.statisticsPeriodStartDate < m2.statisticsPeriodStartDate)
                    return -1;
                if (m1.statisticsPeriodStartDate > m2.statisticsPeriodStartDate)
                    return 1;
                return 0;
            });
        }

        periodicEdiFlowsStatisticsModel.forEach(model => {
            if (model.gestipFlows === null) model.gestipFlows = [];
            if (model.arlFlows === null) model.arlFlows = [];
            if (model.crFlows === null) model.crFlows = [];
            if (model.bpijFlows === null) model.bpijFlows = [];
        });

        this.setGestipFlows(periodicEdiFlowsStatisticsModel);

        this.setArlFlows(periodicEdiFlowsStatisticsModel);

        this.setCrFlows(periodicEdiFlowsStatisticsModel);

        this.setBpijFlows(periodicEdiFlowsStatisticsModel);

        return periodicEdiFlowsStatisticsModel;
    }

    private AddModelWithGlobalStatistics(periodicEdiFlowsStatisticsModel: DWH.PeriodicEdiFlowsStatisticsModel[], dateWithoutModel: string[], granularity: GranularityType, period: Period) {
        dateWithoutModel.forEach(beginDate => {
            let endDate: string = (granularity === GranularityType.Days ? beginDate : moment(beginDate).add(1, 'month').format("YYYY-MM-DD"));
            let periodGranularity = granularity === GranularityType.Days ? 0 : 1;
            periodicEdiFlowsStatisticsModel.push({
                statisticsPeriodStartDate: beginDate,
                statisticsPeriodEndDate: endDate,
                periodGranularity: periodGranularity,
                globalStatistics: {
                    totalNumberOfSentGestipFlows: null,
                    numberOfReceivedCrFlowsForSentGestipFlows: null,
                    numberOfMissingCrFlowsForSentGestipFlows: null,
                    numberOfReceivedArlFlowsForSentGestipFlows: null,
                    numberOfMissingArlFlowsForSentGestipFlows: null,
                    totalNumberOfReceivedCrFlows: null,
                    totalNumberOfReceivedArlFlows: null,
                    totalNumberOfAcceptedArlFlows: null,
                    totalNumberOfRejectedArlFlows: null,
                    totalNumberOfReceivedBpijFlows: null
                },
                gestipFlows: [],
                arlFlows: [],
                crFlows: [],
                bpijFlows: []
            })
        });
    }

    private setGestipFlows(periodicEdiFlowsStatisticsModel: DWH.PeriodicEdiFlowsStatisticsModel[]) {
        if (periodicEdiFlowsStatisticsModel.some(f => (f.gestipFlows.filter(f => f.recipient.label.trim().toUpperCase() === "PUBLIFLUX")).length !== 0)) {
            periodicEdiFlowsStatisticsModel.forEach(vm => {
                if (vm.gestipFlows.filter(f => f.recipient.label.trim().toUpperCase() === "PUBLIFLUX").length === 0) {
                    vm.gestipFlows.push(this.getGestipFlowsTransceiver("PUBLIFLUX"));
                }
            });
        }
        if (periodicEdiFlowsStatisticsModel.some(f => (f.gestipFlows.filter(f => f.recipient.label.trim().toUpperCase() === "ELCIMAI")).length !== 0)) {
            periodicEdiFlowsStatisticsModel.forEach(vm => {
                if (vm.gestipFlows.filter(f => f.recipient.label.trim().toUpperCase() === "ELCIMAI").length === 0) {
                    vm.gestipFlows.push(this.getGestipFlowsTransceiver("ELCIMAI"));
                }
            });
        }
        if (periodicEdiFlowsStatisticsModel.some(f => (f.gestipFlows.filter(f => f.recipient.label.trim().toUpperCase() === "CNAMTS")).length !== 0)) {
            periodicEdiFlowsStatisticsModel.forEach(vm => {
                if (vm.gestipFlows.filter(f => f.recipient.label.trim().toUpperCase() === "CNAMTS").length === 0) {
                    vm.gestipFlows.push(this.getGestipFlowsTransceiver("CNAMTS"));
                }
            });
        }

    }

    private getGestipFlowsTransceiver(transceiver: string): DWH.GestipFlow {
        return {
            recipient: {
                identificationCode: transceiver,
                label: transceiver
            },
            numberOfSentFlows: null,
            numberOfReceivedArlFlows: null,
            numberOfMissingArlFlows: null,
            numberOfReceivedCrFlows: null,
            numberOfMissingCrFlows: null
        }
    }

    private setArlFlows(periodicEdiFlowsStatisticsModel: DWH.PeriodicEdiFlowsStatisticsModel[]) {
        if (periodicEdiFlowsStatisticsModel.some(f => (f.arlFlows.filter(f => f.sender.label.trim().toUpperCase() === "PUBLIFLUX")).length !== 0)) {
            periodicEdiFlowsStatisticsModel.forEach(vm => {
                if (vm.arlFlows.filter(f => f.sender.label.trim().toUpperCase() === "PUBLIFLUX").length === 0) {
                    vm.arlFlows.push(this.getArlFlowsTransceiver("PUBLIFLUX"));
                }
            });
        }
        if (periodicEdiFlowsStatisticsModel.some(f => (f.arlFlows.filter(f => f.sender.label.trim().toUpperCase() === "ELCIMAI")).length !== 0)) {
            periodicEdiFlowsStatisticsModel.forEach(vm => {
                if (vm.arlFlows.filter(f => f.sender.label.trim().toUpperCase() === "ELCIMAI").length === 0) {
                    vm.arlFlows.push(this.getArlFlowsTransceiver("ELCIMAI"));
                }
            });
        }
        if (periodicEdiFlowsStatisticsModel.some(f => (f.arlFlows.filter(f => f.sender.label.trim().toUpperCase() === "CNAMTS")).length !== 0)) {
            periodicEdiFlowsStatisticsModel.forEach(vm => {
                if (vm.arlFlows.filter(f => f.sender.label.trim().toUpperCase() === "CNAMTS").length === 0) {
                    vm.arlFlows.push(this.getArlFlowsTransceiver("CNAMTS"));
                }
            });
        }
    }

    private getArlFlowsTransceiver(transceiver: string): DWH.ArlFlow {
        return {
            sender: {
                identificationCode: transceiver,
                label: transceiver
            },
            numberOfReceivedFlows: null,
            numberOfAcceptedFlows: null,
            numberOfRejectedFlows: null
        }
    }

    private setCrFlows(periodicEdiFlowsStatisticsModel: DWH.PeriodicEdiFlowsStatisticsModel[]) {
        if (periodicEdiFlowsStatisticsModel.some(f => (f.crFlows.filter(f => f.sender.label.trim().toUpperCase() === "PUBLIFLUX")).length !== 0)) {
            periodicEdiFlowsStatisticsModel.forEach(vm => {
                if (vm.crFlows.filter(f => f.sender.label.trim().toUpperCase() === "PUBLIFLUX").length === 0) {
                    vm.crFlows.push(this.getCrFlowsTransceiver("PUBLIFLUX"));
                }
            });
        }
        if (periodicEdiFlowsStatisticsModel.some(f => (f.crFlows.filter(f => f.sender.label.trim().toUpperCase() === "ELCIMAI")).length !== 0)) {
            periodicEdiFlowsStatisticsModel.forEach(vm => {
                if (vm.crFlows.filter(f => f.sender.label.trim().toUpperCase() === "ELCIMAI").length === 0) {
                    vm.crFlows.push(this.getCrFlowsTransceiver("ELCIMAI"));
                }
            });
        }
        if (periodicEdiFlowsStatisticsModel.some(f => (f.crFlows.filter(f => f.sender.label.trim().toUpperCase() === "CNAMTS")).length !== 0)) {
            periodicEdiFlowsStatisticsModel.forEach(vm => {
                if (vm.crFlows.filter(f => f.sender.label.trim().toUpperCase() === "CNAMTS").length === 0) {
                    vm.crFlows.push(this.getCrFlowsTransceiver("CNAMTS"));
                }
            });
        }
    }

    private getCrFlowsTransceiver(transceiver: string): DWH.CrFlow {
        return {
            sender: {
                identificationCode: transceiver,
                label: transceiver
            },
            numberOfReceivedFlows: null
        }
    }

    private setBpijFlows(periodicEdiFlowsStatisticsModel: DWH.PeriodicEdiFlowsStatisticsModel[]) {
        if (periodicEdiFlowsStatisticsModel.some(f => (f.bpijFlows.filter(f => f.sender.label.trim().toUpperCase() === "PUBLIFLUX")).length !== 0)) {
            periodicEdiFlowsStatisticsModel.forEach(vm => {
                if (vm.bpijFlows.filter(f => f.sender.label.trim().toUpperCase() === "PUBLIFLUX").length === 0) {
                    vm.bpijFlows.push(this.getBpijFlowsTransceiver("PUBLIFLUX"));
                }
            });
        }
        if (periodicEdiFlowsStatisticsModel.some(f => (f.bpijFlows.filter(f => f.sender.label.trim().toUpperCase() === "ELCIMAI")).length !== 0)) {
            periodicEdiFlowsStatisticsModel.forEach(vm => {
                if (vm.bpijFlows.filter(f => f.sender.label.trim().toUpperCase() === "ELCIMAI").length === 0) {
                    vm.bpijFlows.push(this.getBpijFlowsTransceiver("ELCIMAI"));
                }
            });
        }
        if (periodicEdiFlowsStatisticsModel.some(f => (f.bpijFlows.filter(f => f.sender.label.trim().toUpperCase() === "CNAMTS")).length !== 0)) {
            periodicEdiFlowsStatisticsModel.forEach(vm => {
                if (vm.bpijFlows.filter(f => f.sender.label.trim().toUpperCase() === "CNAMTS").length === 0) {
                    vm.bpijFlows.push(this.getBpijFlowsTransceiver("CNAMTS"));
                }
            });
        }
    }

    private getBpijFlowsTransceiver(transceiver: string): DWH.BpijFlow {
        return {
            sender: {
                identificationCode: transceiver,
                label: transceiver
            },
            numberOfReceivedFlows: null
        }
    }

    getPatchEdiFlowsAverageDeliveryTimesModel(ediFlowsAverageDeliveryTimesModel: DWH.EdiFlowsAverageDeliveryTimesModel[], granularity: GranularityType, period: Period) {
      ediFlowsAverageDeliveryTimesModel = ediFlowsAverageDeliveryTimesModel.reverse();
      
        let dateWithModel: string[] = [];
        let expectedLength = granularity === GranularityType.Days ? 30 : 12;
        if (ediFlowsAverageDeliveryTimesModel.length !== expectedLength) {

            ediFlowsAverageDeliveryTimesModel.forEach(model => {
              dateWithModel.push(moment(model.periodStartDate).format("YYYY-MM-DD"));
              if (model.globalDeliveryTimeAverages == null)
                model.globalDeliveryTimeAverages = { crFlowsAverageDeliveryTimeInHours: null, arlFlowsAverageDeliveryTimeInHours: null };
            });

            let duration = (granularity === GranularityType.Days ? moment.duration(1, 'days') : moment.duration(1, 'month'));
            let currentBeginDate: Moment = period.beginDate.clone().locale('fr');
            let dateWithoutModel: string[] = [];
            while (currentBeginDate.toDate() <= period.endDate.toDate()) {
                if (dateWithModel.includes(currentBeginDate.format("YYYY-MM-DD")) === false) {
                    dateWithoutModel.push(currentBeginDate.format("YYYY-MM-DD"));
                }
                currentBeginDate = currentBeginDate.add(duration);
            }

            this.AddModelWithGlobalDeliveryTimeAverages(ediFlowsAverageDeliveryTimesModel, dateWithoutModel, granularity, period);

            ediFlowsAverageDeliveryTimesModel = ediFlowsAverageDeliveryTimesModel.sort((m1, m2) => {
                if (m1.periodStartDate < m2.periodStartDate)
                    return -1;
                if (m1.periodStartDate > m2.periodStartDate)
                    return 1;
                return 0;
            });
        }

        ediFlowsAverageDeliveryTimesModel.forEach(model => {
            if (model.arlFlowsDeliveryTimeAverages === null) model.arlFlowsDeliveryTimeAverages = [];
            if (model.crFlowsDeliveryTimeAverages === null) model.crFlowsDeliveryTimeAverages = [];
        });


        this.setArlFlowsDeliveryTimeAverage(ediFlowsAverageDeliveryTimesModel);

        this.setCrFlowsDeliveryTimeAverage(ediFlowsAverageDeliveryTimesModel);

        return ediFlowsAverageDeliveryTimesModel;
    }

    private AddModelWithGlobalDeliveryTimeAverages(ediFlowsAverageDeliveryTimesModel: DWH.EdiFlowsAverageDeliveryTimesModel[], dateWithoutModel: string[], granularity: GranularityType, period: Period) {
        dateWithoutModel.forEach(beginDate => {
            let endDate: string = (granularity === GranularityType.Days ? beginDate : moment(beginDate).add(1, 'month').format("YYYY-MM-DD"));
            let periodGranularity = granularity === GranularityType.Days ? 0 : 1;
            ediFlowsAverageDeliveryTimesModel.push({
                periodStartDate: beginDate,
                periodEndDate: endDate,
                periodGranularity: periodGranularity,
                globalDeliveryTimeAverages: {
                    crFlowsAverageDeliveryTimeInHours: null,
                    arlFlowsAverageDeliveryTimeInHours: null
                },
                arlFlowsDeliveryTimeAverages: [],
                crFlowsDeliveryTimeAverages: []
            })
        });
    }

    private setArlFlowsDeliveryTimeAverage(ediFlowsAverageDeliveryTimesModel: DWH.EdiFlowsAverageDeliveryTimesModel[]) {
        if (ediFlowsAverageDeliveryTimesModel.some(f => (f.arlFlowsDeliveryTimeAverages.filter(f => f.recipient.label.trim().toUpperCase() === "PUBLIFLUX")).length !== 0)) {
            ediFlowsAverageDeliveryTimesModel.forEach(vm => {
                if (vm.arlFlowsDeliveryTimeAverages.filter(f => f.recipient.label.trim().toUpperCase() === "PUBLIFLUX").length === 0) {
                    vm.arlFlowsDeliveryTimeAverages.push(<DWH.ArlFlowsDeliveryTimeAverage>this.getArlOrCrFlowsDeliveryTimeAverageTransceiver("PUBLIFLUX"));
                }
            });
        }
        if (ediFlowsAverageDeliveryTimesModel.some(f => (f.arlFlowsDeliveryTimeAverages.filter(f => f.recipient.label.trim().toUpperCase() === "ELCIMAI")).length !== 0)) {
            ediFlowsAverageDeliveryTimesModel.forEach(vm => {
                if (vm.arlFlowsDeliveryTimeAverages.filter(f => f.recipient.label.trim().toUpperCase() === "ELCIMAI").length === 0) {
                    vm.arlFlowsDeliveryTimeAverages.push(<DWH.ArlFlowsDeliveryTimeAverage>this.getArlOrCrFlowsDeliveryTimeAverageTransceiver("ELCIMAI"));
                }
            });
        }
        if (ediFlowsAverageDeliveryTimesModel.some(f => (f.arlFlowsDeliveryTimeAverages.filter(f => f.recipient.label.trim().toUpperCase() === "CNAMTS")).length !== 0)) {
            ediFlowsAverageDeliveryTimesModel.forEach(vm => {
                if (vm.arlFlowsDeliveryTimeAverages.filter(f => f.recipient.label.trim().toUpperCase() === "CNAMTS").length === 0) {
                    vm.arlFlowsDeliveryTimeAverages.push(<DWH.ArlFlowsDeliveryTimeAverage>this.getArlOrCrFlowsDeliveryTimeAverageTransceiver("CNAMTS"));
                }
            });
    }
    }

    private setCrFlowsDeliveryTimeAverage(ediFlowsAverageDeliveryTimesModel: DWH.EdiFlowsAverageDeliveryTimesModel[]) {
        if (ediFlowsAverageDeliveryTimesModel.some(f => (f.crFlowsDeliveryTimeAverages.filter(f => f.recipient.label.trim().toUpperCase() === "PUBLIFLUX")).length !== 0)) {
            ediFlowsAverageDeliveryTimesModel.forEach(vm => {
                if (vm.crFlowsDeliveryTimeAverages.filter(f => f.recipient.label.trim().toUpperCase() === "PUBLIFLUX").length === 0) {
                    vm.crFlowsDeliveryTimeAverages.push(<DWH.CrFlowsDeliveryTimeAverage>this.getArlOrCrFlowsDeliveryTimeAverageTransceiver("PUBLIFLUX"));
                }
            });
        }
        if (ediFlowsAverageDeliveryTimesModel.some(f => (f.crFlowsDeliveryTimeAverages.filter(f => f.recipient.label.trim().toUpperCase() === "ELCIMAI")).length !== 0)) {
            ediFlowsAverageDeliveryTimesModel.forEach(vm => {
                if (vm.crFlowsDeliveryTimeAverages.filter(f => f.recipient.label.trim().toUpperCase() === "ELCIMAI").length === 0) {
                    vm.crFlowsDeliveryTimeAverages.push(<DWH.CrFlowsDeliveryTimeAverage>this.getArlOrCrFlowsDeliveryTimeAverageTransceiver("ELCIMAI"));
                }
            });
        }
        if (ediFlowsAverageDeliveryTimesModel.some(f => (f.crFlowsDeliveryTimeAverages.filter(f => f.recipient.label.trim().toUpperCase() === "CNAMTS")).length !== 0)) {
            ediFlowsAverageDeliveryTimesModel.forEach(vm => {
                if (vm.crFlowsDeliveryTimeAverages.filter(f => f.recipient.label.trim().toUpperCase() === "CNAMTS").length === 0) {
                    vm.crFlowsDeliveryTimeAverages.push(<DWH.CrFlowsDeliveryTimeAverage>this.getArlOrCrFlowsDeliveryTimeAverageTransceiver("CNAMTS"));
                }
            });
        }
    }

    private getArlOrCrFlowsDeliveryTimeAverageTransceiver(transceiver: string): any {
        return {
            recipient: {
                identificationCode: transceiver,
                label: transceiver
            },
            averageDeliveryTimeInHours: null
        }
    }


}
