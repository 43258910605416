import { Injectable } from "@angular/core";
import DWH = Models.DataWarehouse;
import { StatisticsArlAverageDeliveryTimesViewModel } from "../../../../_core/view-models/datawarehouse/edi-flow/view-models-from-models/statistics-arl-average-delivery-times-view-model";

@Injectable({
  providedIn: 'root'
})

export class PeriodArlReceivedService {
  getViewModel(model: DWH.EdiFlowsAverageDeliveryTimesModel[]): StatisticsArlAverageDeliveryTimesViewModel[] {
    let vm: StatisticsArlAverageDeliveryTimesViewModel[];

    vm = model.map(m => new StatisticsArlAverageDeliveryTimesViewModel(m));

    return vm;
  }
}
