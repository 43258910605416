import { Component } from '@angular/core';

@Component({
    selector: 'app-administration-prestiprev',
    templateUrl: './administration-prestiprev.component.html',
    styleUrls: ['./administration-prestiprev.component.scss']
})
/** administration-prestiprev component*/
export class AdministrationPrestiprevComponent {
    /** administration-prestiprev ctor */
    constructor() {

    }
}