import { StatisticsFlowViewModel } from "./statistics-flow-view-model";
import DWH = Models.DataWarehouse;

export class StatisticsArlReceivedFlowViewModel extends StatisticsFlowViewModel {
    totalNumberOfAcceptedArlFlows: number;
    totalNumberOfRejectedArlFlows: number;
    arlFlows: DWH.ArlFlow[];
    
    constructor(model: DWH.PeriodicEdiFlowsStatisticsModel) {
        super(model);

        this.totalNumberOfAcceptedArlFlows = model.globalStatistics.totalNumberOfAcceptedArlFlows;
        this.totalNumberOfRejectedArlFlows = model.globalStatistics.totalNumberOfRejectedArlFlows;
        this.arlFlows = model.arlFlows === null ? [] : model.arlFlows;
    }
}