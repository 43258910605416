import { Injectable, Inject } from '@angular/core';
import DWH = Models.DataWarehouse;
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GranularityType } from '@app/_core/view-models/datawarehouse/edi-flow/view-models-from-views/granularity-type';
import { Period } from '@app/_core/view-models/datawarehouse/edi-flow/view-models-from-views/period';
import { AuthenticationService } from './authentication.service';
import { merge, Observable, of } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataWarehouseService {

  private partnerId: string;
  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private authenticationService: AuthenticationService) {
    this.partnerId = this.authenticationService.currentUserValue.partnerGuid;
  }

  getPeriodicEdiFlowsStatistics(granularityType: GranularityType, period: Period): Observable<DWH.PeriodicEdiFlowsStatisticsModel[]> {
    const headerOption = new HttpHeaders({ 'Content-Type': 'application/json' });
    let granularity: string = (granularityType === GranularityType.Days ? "daily" : "monthly");
    const apiUrl = 'api/edi/partner/' + this.partnerId + '/statistics/' + granularity + '/from/' + period.beginDate.format("YYYY-MM-DD") + '/to/' + period.endDate.format("YYYY-MM-DD");

    return this.http.get<DWH.PeriodicEdiFlowsStatisticsModel[]>(this.baseUrl + apiUrl, { headers: headerOption });
  }

  getModel(granularityType: GranularityType, currentPeriod: Period): Observable<DWH.PeriodicEdiFlowsStatisticsModel[]> { 
    let result = merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.getPeriodicEdiFlowsStatistics(granularityType, this.changePeriodIfGranularityMonth(granularityType, currentPeriod));
        }),
        map(data => {
          return data;
        }),
        catchError(() => {
          return of([]);
        })
      );
      return result;
  }

  getEdiFlowsAverageDeliveryTimes(granularityType: GranularityType, period: Period): Observable<DWH.EdiFlowsAverageDeliveryTimesModel[]> {
    const headerOption = new HttpHeaders({ 'Content-Type': 'application/json' });
    let granularity: number = (granularityType === GranularityType.Days ? 0 : 1);
    const apiUrl = 'api/edi/partner/' + this.partnerId + '/edi-flows-average-delivery-times/granularity/' + granularity + '/from/' + period.beginDate.format("YYYY-MM-DD") + '/to/' + period.endDate.format("YYYY-MM-DD");

    return this.http.get<DWH.EdiFlowsAverageDeliveryTimesModel[]>(this.baseUrl + apiUrl, { headers: headerOption });
  }

  getModelAverageDeliveryTimes(granularityType: GranularityType, currentPeriod: Period): Observable<DWH.EdiFlowsAverageDeliveryTimesModel[]> {
    let result = merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.getEdiFlowsAverageDeliveryTimes(granularityType, this.changePeriodIfGranularityMonth(granularityType, currentPeriod));
        }),
        map(data => {
          return data;
        }),
        catchError(() => {
          return of([]);
        })
      );
      return result;
  }

  private changePeriodIfGranularityMonth(granularityType: GranularityType, currentPeriod: Period) {
    if (granularityType !== GranularityType.Months) {

      return currentPeriod;

    }
    return new Period(currentPeriod.beginDate.clone(), currentPeriod.endDate.clone().add(1, "month"));

  }

}
