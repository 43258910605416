import DWH = Models.DataWarehouse;

export abstract class StatisticsFlowViewModel {
    statisticsPeriodStartDate: string;
    statisticsPeriodEndDate: string;
    periodGranularity: DWH.PeriodGranularity;
    
    constructor(model: DWH.PeriodicEdiFlowsStatisticsModel) {
        this.statisticsPeriodStartDate = model.statisticsPeriodStartDate;
        this.statisticsPeriodEndDate = model.statisticsPeriodEndDate;
        this.periodGranularity = model.periodGranularity;
    }
}