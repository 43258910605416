import { StatisticsFlowViewModel } from "./statistics-flow-view-model";
import DWH = Models.DataWarehouse;

export class StatisticsCrReceivedFlowViewModel extends StatisticsFlowViewModel {
    totalNumberOfReceivedCrFlows: number;
    crFlows: DWH.CrFlow[];
    
    constructor(model: DWH.PeriodicEdiFlowsStatisticsModel) {
        super(model);

        this.totalNumberOfReceivedCrFlows = model.globalStatistics.totalNumberOfReceivedCrFlows;
        this.crFlows = model.crFlows === null ? [] : model.crFlows;
    }
}