import { Component, Input, EventEmitter, Output } from '@angular/core';
import { AuthenticationService, SharedEventsService } from '@app/_services';
import { Routes } from '@app/_core/routes';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
/** header-menu component*/
export class HeaderMenuComponent {
  @Input() searchbarDisplayed = false;
  @Input() notify = false;
  @Output() notificationReseted = new EventEmitter();
  user: Models.AppUserViewModel;
  public hover = false;
  public homeRoute = Routes.Accueil;
  public notificationsRoute = Routes.Notifications;
  currentUserName = '';
  currentEmailAddress = '';
  /** header-menu ctor */
  constructor(private authenticationService: AuthenticationService, private sharedEvents: SharedEventsService) {
    this.sharedEvents.logedIn.subscribe(() => {
      this.initDisplayedUser();
    });
    this.initDisplayedUser();
  }

  initDisplayedUser() {
    this.user = this.authenticationService.currentUserValue;
    if (this.user !== null) {
      this.currentUserName = this.user.firstName + ' ' + this.user.lastName;
      this.currentEmailAddress = this.user.emailAddress;
    }
  }

  onDisconnectClick() {
    this.authenticationService.logout();
  }

  onNotificationClick() {
    this.notify = false;
    this.notificationReseted.emit();
  }
}
