import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BpijBenefitsSearchResult } from '@app/_core/view-models';
import { BpijBenefitsSearchModel } from '@app/_core/view-models';
import { AuthenticationService } from './authentication.service';
import * as moment from 'moment';

@Injectable()
export class BpijService {
  private partnerId: string;
  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private authenticationService: AuthenticationService) {
    this.partnerId = this.authenticationService.currentUserValue.partnerGuid;
  }

  getDailyBenefits(nir: string, siret: string, pageSize: number, pageNumber: number, dateFrom: moment.Moment, dateTo: moment.Moment): Observable<BpijBenefitsSearchResult> {
    // Appel du .NetCore API Controller EDI local
    const headerOption = new HttpHeaders({ 'Content-Type': 'application/json' });
    const apiUrl = `api/bpij/partner/${this.partnerId}/search/benefits`;
    const params: BpijBenefitsSearchModel = {
      pageSize: pageSize,
      pageNumber: pageNumber,
      orderedFields: [
        { fieldName: 'DeclaredSiret', order: 'asc' },
        { fieldName: 'Lastname', order: 'asc' },
        { fieldName: 'Firstname', order: 'asc' },
        { fieldName: 'Nir', order: 'asc' },
        { fieldName: 'ReceiptDate', order: 'asc' },
        { fieldName: 'RoBenefitStartingDate', order: 'asc' },
        { fieldName: 'RoBenefitGrossAmount', order: 'desc' },
        { fieldName: 'InsuranceNatureCode', order: 'asc' },
        { fieldName: 'InsuranceNature', order: 'asc' },
      ],
      nir: nir,
      siret: siret,
      benefitDateFrom: dateFrom ? dateFrom.toDate() : null,
      benefitDateTo: dateTo ? dateTo.set({ hour: 23, minute: 59, second: 59 }).toDate() : null
    };

    return this.http.post<BpijBenefitsSearchResult>(this.baseUrl + apiUrl, JSON.stringify(params), { headers: headerOption });
  }

  getDailyBenefitsFromReceiptDates(nir: string, siret: string, pageSize: number, pageNumber: number, dateFrom: moment.Moment, dateTo: moment.Moment): Observable<BpijBenefitsSearchResult> {
    // Appel du .NetCore API Controller EDI local
    const headerOption = new HttpHeaders({ 'Content-Type': 'application/json' });
    const apiUrl = `api/bpij/partner/${this.partnerId}/search/benefits`;

    const params: BpijBenefitsSearchModel = {
      pageSize: pageSize,
      pageNumber: pageNumber,
      orderedFields: [
        { fieldName: 'DeclaredSiret', order: 'asc' },
        { fieldName: 'Lastname', order: 'asc' },
        { fieldName: 'Firstname', order: 'asc' },
        { fieldName: 'Nir', order: 'asc' },
        { fieldName: 'ReceiptDate', order: 'asc' },
        { fieldName: 'RoBenefitStartingDate', order: 'asc' },
        { fieldName: 'RoBenefitGrossAmount', order: 'desc' },
        { fieldName: 'InsuranceNatureCode', order: 'asc' },
        { fieldName: 'InsuranceNature', order: 'asc' },
      ],
      nir: nir,
      siret: siret,
      benefitDateFrom: null,
      benefitDateTo: null,
      receiptDateFrom: dateFrom ? dateFrom.toDate() : null,
      receiptDateTo: dateTo ? dateTo.set({ hour: 23, minute: 59, second: 59 }).toDate() : null
    };

    return this.http.post<BpijBenefitsSearchResult>(this.baseUrl + apiUrl, JSON.stringify(params), { headers: headerOption });
  }

  getAllBenefits(pageSize: number, pageNumber: number): Observable<BpijBenefitsSearchResult> {
    return this.getDailyBenefits(
      null,
      null,
      pageSize,
      pageNumber,
      null,
      null
    )
  }

  exportDailyBenefits(nir: string, siret: string, dateFrom: moment.Moment, dateTo: moment.Moment): Observable<Blob> {
    // Appel du .NetCore API Controller EDI local
    const headerOption = new HttpHeaders({ 'Content-Type': 'application/json' });
    const apiUrl = `api/bpij/partner/${this.partnerId}/export/benefits`;
    const params: BpijBenefitsSearchModel = {
      pageSize: 0,
      pageNumber: 0,
      orderedFields: [
        { fieldName: 'receiptDate', order: 'desc' },
        { fieldName: 'siret', order: 'asc' },
        { fieldName: 'nir', order: 'asc' }
      ],
      nir: nir,
      siret: siret,
      benefitDateFrom: dateFrom ? dateFrom.toDate() : null,
      benefitDateTo: dateTo ? dateTo.set({ hour: 23, minute: 59, second: 59 }).toDate() : null
    };

    return this.http.post<Blob>(this.baseUrl + apiUrl, JSON.stringify(params), { responseType: 'blob' as 'json', headers: headerOption });
  }

  exportBenefitsFromReceiptDates(nir: string, siret: string, dateFrom: moment.Moment, dateTo: moment.Moment): Observable<Blob> {
    // Appel du .NetCore API Controller EDI local
    const headerOption = new HttpHeaders({ 'Content-Type': 'application/json' });
    const apiUrl = `api/bpij/partner/${this.partnerId}/export/benefits`;
    const params: BpijBenefitsSearchModel = {
      pageSize: 0,
      pageNumber: 0,
      orderedFields: [
        { fieldName: 'receiptDate', order: 'desc' },
        { fieldName: 'siret', order: 'asc' },
        { fieldName: 'nir', order: 'asc' }
      ],
      nir: nir,
      siret: siret,
      benefitDateFrom: null,
      benefitDateTo: null,
      receiptDateFrom: dateFrom ? dateFrom.toDate() : null,
      receiptDateTo: dateTo ? dateTo.set({ hour: 23, minute: 59, second: 59 }).toDate() : null
    };

    return this.http.post<Blob>(this.baseUrl + apiUrl, JSON.stringify(params), { responseType: 'blob' as 'json', headers: headerOption });
  }
}
