import { StatisticsGestipArlCrFlowViewModel } from "./statistics-gestip-arl-cr-flow-view-model";
import DWH = Models.DataWarehouse;

export class StatisticsGestipFlowViewModel extends StatisticsGestipArlCrFlowViewModel {
    totalNumberOfSentGestipFlows: number;
    
    constructor(model: DWH.PeriodicEdiFlowsStatisticsModel) {
        super(model);
        this.totalNumberOfSentGestipFlows = model.globalStatistics.totalNumberOfSentGestipFlows;
    }
}