import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Routes } from '@app/_core/routes';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<Models.AppUserViewModel>;
  public currentUser: Observable<Models.AppUserViewModel>;

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<Models.AppUserViewModel>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): Models.AppUserViewModel {
    return this.currentUserSubject.value;
  }

  clearLocalStorage() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

  login(username: string, password: string): Observable<Models.AppUserViewModel> {
    const url = this.baseUrl + 'api/login/authenticate?username=' + username + '&password=' + password;
    return this.http.get<Models.AppUserViewModel>(url)
      .pipe(map(user => {
        //if (user === null) {
        //  const msg = 'Identifiant ou mot de passe incorrect !';
        //  return throwError({ error: { msg } });
        //}
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }));
  }

  isAlive(userToken: string){
    const url = this.baseUrl + 'api/login/isAlive?userToken=' + userToken;
    return this.http.get<boolean>(url);
  }

  logout() {
    // remove user from local storage to log user out
    this.clearLocalStorage();

    const url = this.baseUrl + 'api/login/logout';
    this.http.get<boolean>(url).subscribe(resp => { this.router.navigate([Routes.Login]) });
  }
}
