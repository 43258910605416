export class ColumnData {
  index: number;
  indexName: string;
  columnName: string;
  isDefaultColumn?: boolean;
  propertyOfModel?: any;
  css?: string;

  constructor(){}
}
