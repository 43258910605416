import { StatisticsGestipArlCrFlowViewModel } from "./statistics-gestip-arl-cr-flow-view-model";
import DWH = Models.DataWarehouse;

export class StatisticsArlFlowForSentGestipFlowsViewModel extends StatisticsGestipArlCrFlowViewModel {
    numberOfReceivedArlFlowsForSentGestipFlows: number;
    numberOfMissingArlFlowsForSentGestipFlows: number;

    constructor(model: DWH.PeriodicEdiFlowsStatisticsModel) {
        super(model);

        this.numberOfReceivedArlFlowsForSentGestipFlows = model.globalStatistics.numberOfReceivedArlFlowsForSentGestipFlows;
        this.numberOfMissingArlFlowsForSentGestipFlows = model.globalStatistics.numberOfMissingArlFlowsForSentGestipFlows;
    }
}