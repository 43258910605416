import { Component, Input } from '@angular/core';
import { CommonVar } from '@app/_core/models';
import { promise } from 'protractor';
//import { AuthorizeService } from '../../api-authorization/authorize.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  @Input() textIntro: string = 'Connectez-vous pour poursuivre..';
  today = new Promise(
    (resolve, reject) => {
      const date = new Date();
      setTimeout(() => {
          resolve(date);
        }, 2000
      );
    }
  )

  constructor(private global: CommonVar) {

  }
}
