import { Injectable } from "@angular/core";
import { FlowType } from "@app/_core/view-models/datawarehouse/edi-flow/view-models-from-views/flow-type";
import { StatisticsGestipArlCrFlowViewModel } from "@app/_core/view-models/datawarehouse/edi-flow/view-models-from-models/statistics-gestip-arl-cr-flow-view-model";
import { StatisticsGestipFlowViewModel } from '@app/_core/view-models/datawarehouse/edi-flow/view-models-from-models/statistics-gestip-flow-view-model';
import { StatisticsArlFlowForSentGestipFlowsViewModel } from '@app/_core/view-models/datawarehouse/edi-flow/view-models-from-models/statistics-arl-flow-for-sent-gestip-flows-view-model';
import { StatisticsCrFlowForSentGestipFlowsViewModel } from '@app/_core/view-models/datawarehouse/edi-flow/view-models-from-models/statistics-cr-flow-for-sent-gestip-flows-view-model';
import DWH = Models.DataWarehouse;

@Injectable({
    providedIn: 'root'
})

export class GestipSentArlCrService {

    getViewModelByFlowType(flowType: FlowType, model: DWH.PeriodicEdiFlowsStatisticsModel[]): StatisticsGestipArlCrFlowViewModel[] {
        let vm: StatisticsGestipArlCrFlowViewModel[];
        switch (flowType) {
            case FlowType.GestipSent:
                vm = model.map(m => new StatisticsGestipFlowViewModel(m));
                //vm = this.getPatchArrayFlows(vm);
                break;
            case FlowType.Arl:
                vm = model.map(m => new StatisticsArlFlowForSentGestipFlowsViewModel(m));
                break;
            case FlowType.Cr:
                vm = model.map(m => new StatisticsCrFlowForSentGestipFlowsViewModel(m));
                break;
        }
        return vm;
    }

    getPatchArrayFlows(statisticsFlowViewModel: StatisticsGestipArlCrFlowViewModel[]) {

        /*
        let truc: boolean = false;
        for (let vm of statisticsFlowViewModel) {
            if (vm.gestipFlows.length !== 0 && vm.gestipFlows.filter(f => f.recipient.label === "PUBLIFLUX").length !== 0) {
                truc = true;
                break;
            }
        }
        for (let vm of statisticsFlowViewModel) {
            if (truc) {
                if (vm.gestipFlows.length === 0 || vm.gestipFlows.filter(f => f.recipient.label !== "PUBLIFLUX").length === 0) {
                    vm.gestipFlows.push({
                        recipient: {
                            identificationCode: "1",
                            label: "PUBLIFLUX"
                        },
                        numberOfSentFlows: 0,
                        numberOfReceivedArlFlows: 0,
                        numberOfMissingArlFlows: 0,
                        numberOfReceivedCrFlows: 0,
                        numberOfMissingCrFlows: 0
                    });
                }
            }
        }
        let truc2: boolean = false;
        for (let vm of statisticsFlowViewModel) {
            if (vm.gestipFlows.length !== 0 && vm.gestipFlows.filter(f => f.recipient.label === "ELCIMAI").length !== 0) {
                truc2 = true;
                break;
            }
        }
        for (let vm of statisticsFlowViewModel) {
            if (truc2) {
                if (vm.gestipFlows.length === 0 || vm.gestipFlows.filter(f => f.recipient.label !== "ELCIMAI").length === 0) {
                    vm.gestipFlows.push({
                        recipient: {
                            identificationCode: "2",
                            label: "ELCIMAI"
                        },
                        numberOfSentFlows: 0,
                        numberOfReceivedArlFlows: 0,
                        numberOfMissingArlFlows: 0,
                        numberOfReceivedCrFlows: 0,
                        numberOfMissingCrFlows: 0
                    });
                }
            }
        }
        let truc3: boolean = false;
        for (let vm of statisticsFlowViewModel) {
            if (vm.gestipFlows.length !== 0 && vm.gestipFlows.filter(f => f.recipient.label === "CNAMTS").length !== 0) {
                truc3 = true;
                break;
            }
        }
        for (let vm of statisticsFlowViewModel) {
            if (truc3) {
                if (vm.gestipFlows.length === 0 || vm.gestipFlows.filter(f => f.recipient.label !== "CNAMTS").length === 0) {
                    vm.gestipFlows.push({
                        recipient: {
                            identificationCode: "3",
                            label: "CNAMTS"
                        },
                        numberOfSentFlows: 0,
                        numberOfReceivedArlFlows: 0,
                        numberOfMissingArlFlows: 0,
                        numberOfReceivedCrFlows: 0,
                        numberOfMissingCrFlows: 0
                    });
                }
            }
        }
        */


   // TODO : pb sur l'axe des abscisses : ya un 30 à la fin !!!
        if (statisticsFlowViewModel.some(f => (f.gestipFlows.filter(f => f.recipient.label === "PUBLIFLUX")).length !== 0)) {
            statisticsFlowViewModel.forEach(vm => {
                if (vm.gestipFlows.filter(f => f.recipient.label !== "PUBLIFLUX").length === 0) {
                    vm.gestipFlows.push({
                        recipient: {
                            identificationCode: "PUBLIFLUX",
                            label: "PUBLIFLUX"
                        },
                        numberOfSentFlows: null,
                        numberOfReceivedArlFlows: null,
                        numberOfMissingArlFlows: null,
                        numberOfReceivedCrFlows: null,
                        numberOfMissingCrFlows: null
                    });
                }
            });
        }
        if (statisticsFlowViewModel.some(f => (f.gestipFlows.filter(f => f.recipient.label === "ELCIMAI")).length !== 0)) {
            statisticsFlowViewModel.forEach(vm => {
                if (vm.gestipFlows.filter(f => f.recipient.label !== "ELCIMAI").length === 0) {
                    vm.gestipFlows.push({
                        recipient: {
                            identificationCode: "ELCIMAI",
                            label: "ELCIMAI"
                        },
                        numberOfSentFlows: null,
                        numberOfReceivedArlFlows: null,
                        numberOfMissingArlFlows: null,
                        numberOfReceivedCrFlows: null,
                        numberOfMissingCrFlows: null
                    });
                }
            });
        }
        if (statisticsFlowViewModel.some(f => (f.gestipFlows.filter(f => f.recipient.label === "CNAMTS")).length !== 0)) {
            statisticsFlowViewModel.forEach(vm => {
                if (vm.gestipFlows.filter(f => f.recipient.label !== "CNAMTS").length === 0) {
                    vm.gestipFlows.push({
                        recipient: {
                            identificationCode: "CNAMTS",
                            label: "CNAMTS"
                        },
                        numberOfSentFlows: null,
                        numberOfReceivedArlFlows: null,
                        numberOfMissingArlFlows: null,
                        numberOfReceivedCrFlows: null,
                        numberOfMissingCrFlows: null
                    });
                }
            });
        }
        return statisticsFlowViewModel;
    }
}
