import { StatisticsGestipArlCrFlowViewModel } from "./statistics-gestip-arl-cr-flow-view-model";
import DWH = Models.DataWarehouse;

export class StatisticsCrFlowForSentGestipFlowsViewModel extends StatisticsGestipArlCrFlowViewModel {
    numberOfReceivedCrFlowsForSentGestipFlows: number;
    numberOfMissingCrFlowsForSentGestipFlows: number;

    constructor(model: DWH.PeriodicEdiFlowsStatisticsModel) {
        super(model);

        this.numberOfReceivedCrFlowsForSentGestipFlows = model.globalStatistics.numberOfReceivedCrFlowsForSentGestipFlows;
        this.numberOfMissingCrFlowsForSentGestipFlows = model.globalStatistics.numberOfMissingCrFlowsForSentGestipFlows;
    }
}