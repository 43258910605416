import { Component, OnInit } from '@angular/core';
import { Routes } from '@app/_core/routes';
import { FluxEdiService } from '@app/_services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-flux-edi',
  templateUrl: './flux-edi.component.html',
  styleUrls: ['./flux-edi.component.scss']
})
export class FluxEdiComponent implements OnInit {

  public card01 = {
    source: '../assets/images/schemafluxedi_surveillance.jpg'
  };
  public card02 = {
    source: '../assets/images/schemafluxedi_surveillance.jpg'
  };

  constructor(private fluxediService: FluxEdiService, private router: Router) {
    
  }

  ngOnInit(): void {
  }

  onSelectFlux(id: string) {
    this.router.navigate(['/edi/'+id]);
  }

  getRouteurLink(linkTitle: string) {
    let route = "";
    switch (linkTitle) {
      case "surveillance": {
        route = Routes.FluxEdiSurveillance;
        break;
      }
      default: {
        route = Routes.Accueil;
        break;
      }
    }

    return [route];
  }
}
