import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import Edi = Models.Edi;

@Injectable()
export class FluxEdiService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
  }

/* Exemple de code d'appel Http */

  //SDU: Exemple d'appel asynchrone
  //--------------------------------
  //get(id: string): any {
  //  this.http.get<FluxEdi>(this.baseUrl + 'api/edi/getFromId?id='+id).subscribe(result => {
  //    return result;
  //  }, error => { return null });
  //}

  //SDU: Exemple d'appel asynchrone avec Promise
  //------------------------------------------
  //get(id: string): any {
  //  return new Promise(
  //    (resolve, reject) => {
  //      this.http.get<FluxEdiGestip>(this.baseUrl + 'api/edi/getFromId?id=' + id).subscribe(result => {
  //        this.fluxEdiGestip = result;
  //        resolve(true);
  //      }, error => {
  //        reject(true);
  //      });
  //    }
  //  );
  //}

  getAllSendedFluxEdiFromDate(dateFrom: string, dateTo: string){
    const apiUrl = 'api/edi/GetAllSendedFluxEdiFromDate';
    return this.http.get<Edi.EdiFileInfo[]>(this.baseUrl + apiUrl.concat('?dateFrom=' + dateFrom, '&dateTo=' + dateTo));
  }

  getAllGestipUnsended(): Observable<Edi.FluxEdiGestipViewModel[]> {
    return this.http.get<Edi.FluxEdiGestipViewModel[]>(this.baseUrl + 'api/edi/getUnsendedFluxediGestip');
  }

  getSendedFluxEdiGestipFromDate(dateFrom: string, dateTo: string): Observable<Edi.FluxEdiGestipViewModel[]> {
    const apiUrl = 'api/edi/GetSendedFluxEdiGestipFromDate';
    return this.http.get<Edi.FluxEdiGestipViewModel[]>(this.baseUrl + apiUrl.concat('?dateFrom=' + dateFrom, '&dateTo=' + dateTo));
  }

  getReceivedFluxEdiBpij(dateFrom: string, dateTo: string): Observable<Edi.FluxEdiBpijViewModel[]> {
    const apiUrl = 'api/edi/GetReceivedFluxEdiBpijFromDate';
    return this.http.get<Edi.FluxEdiBpijViewModel[]>(this.baseUrl + apiUrl.concat('?dateFrom=' + dateFrom, '&dateTo=' + dateTo));
  }

  getAllGestipInBal(): Observable<Edi.EdiFileInfo[]> {
    return this.http.get<Edi.EdiFileInfo[]>(this.baseUrl + 'api/edi/getAllGestipInBal');
  }

  getAllArlInBal(): Observable<Edi.EdiFileInfo[]> {
    return this.http.get<Edi.EdiFileInfo[]>(this.baseUrl + 'api/edi/getAllArlInBal');
  }

  getAllCrInBal(): Observable<Edi.EdiFileInfo[]> {
    return this.http.get<Edi.EdiFileInfo[]>(this.baseUrl + 'api/edi/getAllCrInBal');
  }

  getAllBpijInBal(): Observable<Edi.EdiFileInfo[]> {
    return this.http.get<Edi.EdiFileInfo[]>(this.baseUrl + 'api/edi/getAllBpijInBal');
  }

  getAllGestipWithoutArl(maskedOnly = false, getLastWeekOnly = false): Observable<Edi.GestipLight[]> {
    const apiUrl = 'api/edi/GetAllGestipWithoutArl';
    return this.http.get<Edi.GestipLight[]>(this.baseUrl + apiUrl.concat('?maskedOnly=' + maskedOnly, '&getLastWeekOnly=' + getLastWeekOnly));
  }

  getAllGestipWithArlRejected(maskedOnly = false, getLastWeekOnly = false): Observable<Edi.GestipLight[]> {
    const apiUrl = 'api/edi/GetAllGestipWithArlRejected';
    return this.http.get<Edi.GestipLight[]>(this.baseUrl + apiUrl.concat('?maskedOnly=' + maskedOnly, '&getLastWeekOnly=' + getLastWeekOnly));
  }

  getAllGestipWithoutCr(maskedOnly = false, getLastWeekOnly = false): Observable<Edi.GestipLight[]> {
    const apiUrl = 'api/edi/GetAllGestipWithoutCr';
    return this.http.get<Edi.GestipLight[]>(this.baseUrl + apiUrl.concat('?maskedOnly=' + maskedOnly, '&getLastWeekOnly=' + getLastWeekOnly));
  }

  getAllOperationEdiGestipWithoutCrOrWithCrRejected(maskedOnly = false, getLastWeekOnly = false): Observable<Edi.OperationEdiGestip[]> {
    const apiUrl = 'api/edi/GetAllOperationEdiGestipWithoutCrOrWithCrRejected';
    return this.http.get<Edi.OperationEdiGestip[]>(this.baseUrl + apiUrl.concat('?maskedOnly=' + maskedOnly, '&getLastWeekOnly=' + getLastWeekOnly));
  }

  getAllOperationEdiGestipFromFluxEdiGestip(fluxEdiId): Observable<Edi.OperationEdiGestip[]> {
    const apiUrl = 'api/edi/GetAllOperationEdiGestipFromFluxEdiGestip';
    return this.http.get<Edi.OperationEdiGestip[]>(this.baseUrl + apiUrl.concat('?fluxEdiId=' + fluxEdiId));
  }

  setStateOfGestipArlAbsentTraite(identification: string, masquage: Edi.Masquage = null): Observable<boolean> {
    const apiUrl = 'api/edi/SetStateOfGestipArlAbsentTraite';
    const body = JSON.stringify({ Identification: identification, Masquage:  masquage});
    const headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<boolean>(this.baseUrl + apiUrl, body, { headers: headerOptions });
  }

  setStateOfGestipArlEnErreurTraite(identification: string, masquage: Edi.Masquage = null): Observable<boolean> {
    const apiUrl = 'api/edi/SetStateOfGestipArlEnErreurTraite';
    const body = JSON.stringify({ Identification: identification, Masquage: masquage });
    const headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<boolean>(this.baseUrl + apiUrl, body, { headers: headerOptions });
  }

  setStateOfGestipCrAbsentTraite(identification: string, masquage: Edi.Masquage = null): Observable<boolean> {
    const apiUrl = 'api/edi/SetStateOfGestipCrAbsentTraite';
    const body = JSON.stringify({ Identification: identification, Masquage: masquage });
    const headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<boolean>(this.baseUrl + apiUrl, body, { headers: headerOptions });
  }

  setStateOfOperationGestipCrAbsentOrCrInvalideTraite(id: string, masquage: Edi.Masquage = null): Observable<boolean> {
    const apiUrl = 'api/edi/SetStateOfOperationGestipCrAbsentOrCrInvalideTraite';
    const body = JSON.stringify({ Identification: id, Masquage: masquage });
    const headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<boolean>(this.baseUrl + apiUrl, body, { headers: headerOptions });
  }

  setStateOfOperationGestipCrAbsentOrCrInvalideTraiteFromGestipId(idGestip: string, masquage: Edi.Masquage = null): Observable<boolean> {
    const apiUrl = 'api/edi/SetStateOfOperationGestipCrAbsentOrCrInvalideTraiteFromGestipId';
    const body = JSON.stringify({ Identification: idGestip, Masquage: masquage });
    const headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<boolean>(this.baseUrl + apiUrl, body, { headers: headerOptions });
  }

}
