import { StatisticsFlowViewModel } from "./statistics-flow-view-model";
import DWH = Models.DataWarehouse;

export abstract class StatisticsGestipArlCrFlowViewModel extends StatisticsFlowViewModel {
    gestipFlows: DWH.GestipFlow[];

    constructor(model: DWH.PeriodicEdiFlowsStatisticsModel) {
        super(model);
        
        this.gestipFlows = model.gestipFlows === null ? [] : model.gestipFlows;
    }
}