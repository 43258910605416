import { Component } from '@angular/core';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
/** loader component*/
export class LoaderComponent {

  loaderDisplayed = true;

    /** loader ctor */
    constructor() {

  }

  hideLoader() {
    this.loaderDisplayed = false;
  }
}
