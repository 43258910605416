import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef, OnDestroy, AfterViewChecked } from "@angular/core";
import { MdbTablePaginationComponent, MdbTableDirective } from "angular-bootstrap-md";
import { Router, ActivatedRoute, RouterEvent, NavigationEnd } from "@angular/router";
import { FluxEdiService } from "../../../_services";
import { merge, of as observableOf, Subject } from "rxjs";
import { startWith, switchMap, map, catchError, filter, takeUntil } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import Edi = Models.Edi;
import HeadElement = Models.HeadElement;
import { Moment } from "moment";
import * as moment from 'moment';
import { CalendarComponent } from "@app/_layers/calendar/calendar.component";
import { CalendarService } from "@app/_layers/calendar/calendar.service";

@Component({
  selector: "app-transfert",
  templateUrl: "./transfert.component.html",
  styleUrls: ["./transfert.component.scss"],
  providers: [CalendarService]
})

/** transfert component*/
export class TransfertComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  @ViewChild(MdbTableDirective, { static: false })
  mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: false })
  mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild("tableFlux", { static: false })
  tableFlux: ElementRef;
  @ViewChild("fromCalendar", { static: false })
  fromCalendar: CalendarComponent;
  @ViewChild("toCalendar", { static: false })
  toCalendar: CalendarComponent;

  readonly TODAY: Moment = CalendarComponent.TODAY;

  dataTable: Edi.EdiFileInfo[] = [];
  modalContent: Edi.EdiFileInfo;
  completeDataTable: Array<Edi.EdiFileInfo> = [];
  filteredDataTable: Array<Edi.EdiFileInfo> = [];

  filterTypeElem = "Tous";
  filterPartenaireElem = "Tous";
  filterStatutElem = "Tous";
  sortingElement = '';
  regExpDate = /^(0[1-9]|1[0-9]|2[0-9]|3[0-1])\/(0[0-9]|1[0-2])\/(\d{4})$/;
  errFrom: string = "";
  errTo: string = "";

  previousTableItems = [];
  maxVisibleItems = 10;

  tableDisplayed = false;
  tableHide = true;
  hasfilterPartenaire = false;
  hasfilterType = false;
  hasfilterStatut = false;
  canRefresh = true;
  btnDateFromUpDisabled = true;
  btnDateFromDownDisabled = false;
  btnDateToUpDisabled = true;
  btnDateToDownDisabled = true;
  displayErrMsg = false;

  headElements: HeadElement[];
  filterParam: any;

  public maxFiles = 10;

  public destroyed = new Subject<any>();

  constructor(public fluxediService: FluxEdiService,
    private router: Router,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private modalService: NgbModal,
    private dateRange: CalendarService) {
    this.headElements = [
      { id: "fileName", head: "Nom fichier ZIP", dataFilter: [], filter: false, sortable: false },
      { id: "partner", head: "Emetteur/destinataire", dataFilter: [], filter: true, sortable: false },
      { id: "documentType", head: "Type", dataFilter: [], filter: true, sortable: false },
      { id: "transferDate", head: "Date de transfert", dataFilter: [], filter: false, sortable: true, toggle: false },
      { id: "transferCftStateOk", head: "Statut", dataFilter: [], filter: true, sortable: false },
      { id: "errorCode", head: "Code Erreur", dataFilter: [], filter: false, sortable: false }
    ];
  }

  ngOnInit() {
    const queryModule = this.route.snapshot.params['module'];

    if (queryModule === 'transfert-prestij') {
      if (this.route.snapshot.params['section']) {
        this.filterParam = JSON.parse(this.route.snapshot.params['filters']);
      }
    }

    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd), takeUntil(this.destroyed)
    ).subscribe(() => {
      this.loadDataTable(this.fromCalendar.date, this.toCalendar.date);
    });
  }

  ngAfterViewInit(): void {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.maxVisibleItems);
    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.mdbTablePagination.ofKeyword = "sur";

    this.dateRange.setCalendars(this.fromCalendar, this.toCalendar);

    this.fromCalendar.date = this.filterParam && this.filterParam.dateFrom ? moment(this.filterParam.dateFrom) : moment(this.TODAY).add(-1, "days");
    this.toCalendar.date = this.filterParam && this.filterParam.dateTo ? moment(this.filterParam.dateTo) : moment(this.TODAY);
    this.loadDataTable(this.fromCalendar.date, this.toCalendar.date);

    this.fromCalendar.dateChange.pipe(takeUntil(this.destroyed)).subscribe(this.loadData.bind(this));
    this.toCalendar.dateChange.pipe(takeUntil(this.destroyed)).subscribe(this.loadData.bind(this));
  }

  ngAfterViewChecked() {
    if (!this.fromCalendar)
      return;
    this.errFrom = this.fromCalendar.error;
    this.errTo = this.toCalendar.error;

    this.cdRef.detectChanges();
  }

  buttonPressed(pressed: boolean) {
    if (pressed)
      this.hasfilterPartenaire = this.hasfilterStatut = this.hasfilterType = false;
  }

  typing(typing: boolean, from: boolean) {
    if (typing)
      from ? this.errFrom = "" : this.errTo = "";
  }

  onFilterClick(filter: string, elem: string, indexFilter: number) {
    this.applyFilter(filter, elem, indexFilter);
    this.mdbTablePagination.firstPage();
  }

  onModalOpenClick(targetModal, data) {
    this.modalContent = data;
    this.modalService.open(targetModal,
      {
        centered: true,
        //css size dans styles/style.scss
        size: "cr-md",
      });
  }

  getStatutClassColor(data: string) {
    if (data === "OK") {
      return "text-valid";
    } else if (data === "KO") {
      return "text-error";
    } else {
      return "";
    }
  }

  getFilterClass(elemId: string) {
    if (elemId === "partner") {
      return this.hasfilterPartenaire ? 'filtering' : 'filter';
    } else if (elemId === "documentType") {
      return this.hasfilterType ? 'filtering' : 'filter';
    } else if (elemId === "transferCftStateOk") {
      return this.hasfilterStatut ? 'filtering' : 'filter';
    }
  }

  getPaginationDisabled() {
    if (this.filteredDataTable.length <= 10) {
      return "pagination-disabled";
    }
  }

  disabledOff(data: any[]) {
    data.forEach(element => {
      element.default = false;
    });
  }

  newDisabledOn(data: any[], indexFilter: number) {
    data[indexFilter].default = true;
  }

  findHeadIndex(elemId: string) {
    const headIndex = this.headElements.findIndex(item => item.id === elemId);
    return headIndex;
  }

  findFilterIndex(headIndex: number, filterId: string) {
    const filterIndex = this.headElements[headIndex].dataFilter.findIndex(item => item.id === filterId);
    return filterIndex;
  }

  applyFilterTypeFromQuery(filter: string) {
    const elemId = this.filterParam.type ? "documentType" : "transferCftStateOk";
    const headIndex = this.findHeadIndex(elemId);
    const filterIndex = this.findFilterIndex(headIndex, filter);
    if ((filter === "BPIJ" && this.dataTable.find(type => type.documentType === "BPIJ")) || filter === "KO" && (this.dataTable.find(state => state.transferCftStateOk === false))) {
      this.applyFilter(filter, elemId, filterIndex);
    }
  }

  applyFilter(filter: string, elemId: string, indexFilter: number) {
    this.filteredDataTable = this.completeDataTable;

    switch (elemId) {
      case "partner":
        {
          this.filterPartenaireElem = filter;
          this.disabledOff(this.headElements[this.findHeadIndex(elemId)].dataFilter);
          this.newDisabledOn(this.headElements[this.findHeadIndex(elemId)].dataFilter, indexFilter);
          this.hasfilterPartenaire = true;
          if (filter === "Tous") {
            this.hasfilterPartenaire = false;
          }
          break;
        }
      case "documentType":
        {
          this.filterTypeElem = filter;
          this.disabledOff(this.headElements[this.findHeadIndex(elemId)].dataFilter);
          this.newDisabledOn(this.headElements[this.findHeadIndex(elemId)].dataFilter, indexFilter);
          this.hasfilterType = true;
          if (filter === "Tous") {
            this.hasfilterType = false;
          }
          break;
        }
      case "transferCftStateOk":
        {
          this.filterStatutElem = filter;
          this.disabledOff(this.headElements[this.findHeadIndex(elemId)].dataFilter);
          this.newDisabledOn(this.headElements[this.findHeadIndex(elemId)].dataFilter, indexFilter);
          this.hasfilterStatut = true;
          if (filter === "Tous") {
            this.hasfilterStatut = false;
          }
          break;
        }
    }

    if (this.filterPartenaireElem !== "Tous") {
      this.filteredDataTable = this.filteredDataTable.filter(item => item.partner === this.filterPartenaireElem);
    }
    if (this.filterTypeElem !== "Tous") {
      this.filteredDataTable = this.filteredDataTable.filter(item => item.documentType === this.filterTypeElem);
    }
    if (this.filterStatutElem !== "Tous") {
      this.filteredDataTable = this.filteredDataTable.filter(item => item.transferCftStateOk === (this.filterStatutElem === "OK" ? true : false));
    }
  }

  setDataTable() {
    this.mdbTable.setDataSource(this.dataTable);
    this.completeDataTable = this.mdbTable.getDataSource();
    this.filteredDataTable = this.completeDataTable;
    this.previousTableItems = this.mdbTable.getDataSource();

    this.loadPartnerFilter(this.completeDataTable);
    this.loadTypeFilter(this.completeDataTable);
    this.loadStateFilter(this.completeDataTable);

    this.canRefresh = false;
    this.tableHide = false;
  }

  loadPartnerFilter(dataTable: Edi.EdiFileInfo[]) {
    const partnerList = [];
    dataTable.forEach(item => {
      if (!partnerList.includes(item.partner)) {
        partnerList.push({ id: item.partner, default: false });
      }
    });

    const data = Array.from(new Set(partnerList.map(a => a.id)))
      .map(id => {
        return partnerList.find(a => a.id === id);
      });
    data.push({ id: "Tous", default: true });
    const index = this.headElements.findIndex(item => item.id === "partner");
    this.headElements[index].dataFilter = data;
  }

  loadTypeFilter(dataTable: Edi.EdiFileInfo[]) {
    const typeList = [];
    dataTable.forEach(item => {
      if (!typeList.includes(item.documentType)) {
        typeList.push({ id: item.documentType, default: false });
      }
    });

    const data = Array.from(new Set(typeList.map(a => a.id)))
      .map(id => {
        return typeList.find(a => a.id === id);
      });
    data.push({ id: "Tous", default: true });
    const index = this.headElements.findIndex(item => item.id === "documentType");
    this.headElements[index].dataFilter = data;
  }

  loadStateFilter(dataTable: Edi.EdiFileInfo[]) {
    const statutList = [];
    dataTable.forEach(item => {
      if (!statutList.includes(item.transferCftStateOk ? "OK" : "KO")) {
        statutList.push({ id: item.transferCftStateOk ? "OK" : "KO", color: this.getStatutClassColor(item.transferCftStateOk ? "OK" : "KO"), default: false });
      }
    });

    const data = Array.from(new Set(statutList.map(a => a.id)))
      .map(id => {
        return statutList.find(a => a.id === id);
      });
    data.push({ id: "Tous", color: this.getStatutClassColor("Tous"), default: true });
    const index = this.headElements.findIndex(item => item.id === "transferCftStateOk");
    this.headElements[index].dataFilter = data;
  }

  loadData() {
    this.resetDataTable();
    if (!this.fromCalendar.date || !this.toCalendar.date)
      return;

    this.hasfilterPartenaire = this.hasfilterStatut = this.hasfilterType = false;
    this.loadDataTable(this.fromCalendar.date, this.toCalendar.date);
  }

  loadDataTable(dateFrom: Moment, dateTo: Moment) {
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.fluxediService.getAllSendedFluxEdiFromDate(dateFrom.toDate().toDateString(), dateTo.toDate().toDateString());
        }),
        map(data => {
          return data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(data => {
        this.dataTable = data;
        this.displayErrMsg = false;
        this.setDataTable();
        this.applyFilterParam();
      });
  }

  applyFilterParam() {
    if (this.filterParam) {
      if (this.filterParam.statut === 'KO') {
        var missingStatutKo = this.dataTable.some(item => item.transferCftStateOk === false);
        missingStatutKo ? this.tableHide = this.displayErrMsg = true : this.tableHide = this.displayErrMsg = false;
      }
      this.filterParam.type ? this.applyFilterTypeFromQuery(this.filterParam.type) : this.applyFilterTypeFromQuery(this.filterParam.statut);
      this.filterParam = null;
    }
  }

  private resetDataTable() {
    this.mdbTablePagination.firstPage();
    this.hasfilterPartenaire = this.hasfilterType = this.hasfilterStatut = false;

  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
