import { Component, ViewChild, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MdbTableDirective, MdbTablePaginationComponent } from 'angular-bootstrap-md';
import HeadElement = Models.HeadElement;
import { AlertService, AuthenticationService } from '../../_services';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { Observable, of as observableOf, throwError, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { NgSelectConfig } from '@ng-select/ng-select';
import PrestiPrev = Models.PrestiPrev;
import { AlertSubscriptionFormModel } from '@app/_core/view-models';

@Component({
  selector: 'app-alertes-prestij',
  templateUrl: './alertes-prestij.component.html',
  styleUrls: ['./alertes-prestij.component.scss']
})
/** alertes-prestij component*/
export class AlertesPrestijComponent implements OnInit, AfterViewInit {
  @ViewChild(MdbTableDirective, { static: false }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: false }) mdbTablePagination: MdbTablePaginationComponent;

  alertSubscriptions = new Array<PrestiPrev.AlertSubscriptionViewModel>();
  headElements = new Array<HeadElement>();
  selectedAlertSubscription: PrestiPrev.AlertSubscriptionViewModel;
  alertSubscriptionFormGroup: FormGroup;
  alertSubscriptionFormModel = new AlertSubscriptionFormModel();
  userId: string = null;
  isEditingForm = false;

  sortingElement = '';

  indexToEdit: number = null;

  previousTableItems = [];
  maxVisibleItems = 10;

  tableHide = true;
  canRefresh = false;
  toggle = false;

  changePage = new Observable<any>();
  private _successAlert = new Subject<string>();
  private _warningAlert = new Subject<string>();
  private _errorAlert = new Subject<string>();
  successMessage = '';
  warningMessage = '';
  errorMessage = '';

  /** alertes-prestij ctor */
  // tslint:disable-next-line: max-line-length
  constructor(public alertService: AlertService, public authService: AuthenticationService, private cdRef: ChangeDetectorRef, private formBuilder: FormBuilder,
    private modalService: NgbModal, public datepipe: DatePipe, public ngSelectConfig: NgSelectConfig) {
    this.loadPrestiPrevEvents();
    this.ngSelectConfig.notFoundText = 'Veuillez sélectionner un évènement';
    this.userId = this.authService.currentUserValue.id;
  }

  ngOnInit() {
    const dueTime = 2500;
    this._successAlert.subscribe(message => this.successMessage = message);
    this._successAlert.pipe(
      debounceTime(dueTime)
    ).subscribe(() => this.successMessage = '');

    this._warningAlert.subscribe(message => this.warningMessage = message);
    this._warningAlert.pipe(
      debounceTime(dueTime)
    ).subscribe(() => this.warningMessage = '');

    this._errorAlert.subscribe(message => this.errorMessage = message);
    this._errorAlert.pipe(
      debounceTime(dueTime)
    ).subscribe(() => this.errorMessage = '');
  }

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.maxVisibleItems);
    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();

    this.cdRef.detectChanges();
    this.loadDataTable();
  }

  initForm(data: PrestiPrev.AlertSubscriptionViewModel) {
    this.selectedAlertSubscription = data;
    if (!this.selectedAlertSubscription) {
      this.alertSubscriptionFormModel.frequencies = [];
    }
    this.alertSubscriptionFormGroup = this.formBuilder.group({
      service: ['Prest\'IJ'],
      alertTemplates: this.selectedAlertSubscription ? [this.selectedAlertSubscription.alertTemplate] : [null],
      frequencies: this.selectedAlertSubscription ? [this.selectedAlertSubscription.frequency] : [null],
      byNotification: this.selectedAlertSubscription ? [this.selectedAlertSubscription.byWebNotification] : [false],
      byEmail: this.selectedAlertSubscription ? [this.selectedAlertSubscription.byEmail] : [false]
    });

    this.alertSubscriptionFormGroup.controls[AlertSubscriptionFormModel.serviceToString].disable();
  }

  onAlertEventChange(event: PrestiPrev.AlertTemplate) {
    this.alertSubscriptionFormModel.frequencies = [];
    this.alertSubscriptionFormGroup.get('frequencies').setValue(null);
    event.availableFrequencyTypes.forEach((frequency) => {
      const data = this.alertSubscriptionFormModel.availableFrequencies.filter(freq => freq.type === frequency);
      this.alertSubscriptionFormModel.frequencies.push(...data);
    });
    /* forcer un choix à l'utilisateur*/
    // this.alertSubscriptionFormGroup.get('frequencies').setValue(this.alertSubscriptionFormModel.frequencies[0]);
  }

  onShowModalClick(alertModal, data: PrestiPrev.AlertSubscriptionViewModel, index: number, isEditingForm: boolean) {
    this.modalService.open(alertModal,
      {
        centered: true,
        // css size dans styles/style.scss
        size: 'alert-md',
      });

    if (isEditingForm) {
      this.isEditingForm = true;
      this.alertSubscriptionFormModel.frequencies = [];
      data.alertTemplate.availableFrequencyTypes.forEach((frequency) => {
        const availableFrequencies = this.alertSubscriptionFormModel.availableFrequencies.filter(freq => freq.type === frequency);
        this.alertSubscriptionFormModel.frequencies.push(...availableFrequencies);
      });
    } else {
      this.isEditingForm = false;
    }

    console.log(this.mdbTablePagination.lastItemIndex);
    console.log(this.mdbTablePagination.allItemsLength);
    this.indexToEdit = index;
    this.initForm(data);
  }

  onSubmitModalAlert() {
    const formValue = this.alertSubscriptionFormGroup.value;
    const selectedTemplate: PrestiPrev.AlertTemplate = formValue[AlertSubscriptionFormModel.alertTemplatesToString];

    if (this.isEditingForm) {
      this.selectedAlertSubscription.alertTemplate.internalCode = selectedTemplate.internalCode;
      this.selectedAlertSubscription.creationDate = new Date();
      this.selectedAlertSubscription.frequency = formValue[AlertSubscriptionFormModel.frequenciesToString];
      this.selectedAlertSubscription.byWebNotification = formValue[AlertSubscriptionFormModel.byNotificationToString];
      this.selectedAlertSubscription.byEmail = formValue[AlertSubscriptionFormModel.byEmailToString];
      this.updateAlertSubscription(this.selectedAlertSubscription);
    } else {
      this.addNewAlertSubscription({
        id: '',
        creationDate: new Date(),
        byEmail: formValue[AlertSubscriptionFormModel.byEmailToString],
        byWebNotification: formValue[AlertSubscriptionFormModel.byNotificationToString],
        alertTemplate: selectedTemplate,
        frequency: formValue[AlertSubscriptionFormModel.frequenciesToString],
        userId: this.userId
      });
    }

    this.isEditingForm = false;
    this.modalService.dismissAll();
  }

  onDeleteAlert(subscriptionId: string) {
    this.deleteAlertSubscription(subscriptionId);
    if (this.mdbTablePagination.activePageNumber > 1 && this.mdbTablePagination.firstItemIndex === this.mdbTablePagination.lastItemIndex) {
      this.mdbTablePagination.previousPage();
    }
  }

  navigateToLastPage() {
    if (this.mdbTablePagination.lastItemIndex <= this.mdbTablePagination.allItemsLength) {
      this.mdbTablePagination.lastPage();
    }
  }

  checkValuesForm() {
    /* bouton valider disabled si options pas sélectionnées */
    if ((this.alertSubscriptionFormGroup.value[AlertSubscriptionFormModel.alertTemplatesToString] === null)
      || (this.alertSubscriptionFormGroup.value[AlertSubscriptionFormModel.frequenciesToString] === null)
      || (this.alertSubscriptionFormGroup.value[AlertSubscriptionFormModel.byNotificationToString] === false
        && this.alertSubscriptionFormGroup.value[AlertSubscriptionFormModel.byEmailToString] === false)) {
      return true;
    }
    return false;
  }

  getPaginationDisabled(length: number): string {
    if (length <= 10) {
      return 'pagination-disabled';
    }
  }

  setDataTable() {
    // Chargement du tableau
    this.mdbTable.setDataSource(this.alertSubscriptions);
    this.previousTableItems = this.mdbTable.getDataSource();
    this.mdbTablePagination.ofKeyword = 'sur';

    this.canRefresh = false;
    this.tableHide = false;
  }

  loadDataTable() {
    this.sortingElement = '';
    this.alertService.getAlertSubscriptions(this.userId)
      .subscribe((data: PrestiPrev.AlertSubscriptionViewModel[]) => {
        this.alertSubscriptions = data;
        this.loadHeadElements();
        this.setDataTable();
      });
  }

  loadPrestiPrevEvents() {
    this.alertService.getAlertTemplates('PRESTIJ')
      .subscribe((data: PrestiPrev.AlertTemplate[]) => {
        this.alertSubscriptionFormModel.alertTemplates = data;
      });
  }

  loadHeadElements() {
    this.headElements = [
      { id: 'alertTemplate.service.label', head: 'Service', dataFilter: [], filter: false, sortable: true, toggle: false },
      { id: 'alertTemplate.label', head: 'Evènement', dataFilter: [], filter: false, sortable: true, toggle: false },
      { id: 'frequency.label', head: 'Fréquence', dataFilter: [], filter: false, sortable: false },
      { id: 'type', head: 'Type(s)', dataFilter: [], filter: false, sortable: false },
    ];
  }

  addNewAlertSubscription(alertSubscription: PrestiPrev.AlertSubscriptionViewModel) {
    this.alertService.addAlertSubscription(alertSubscription).toPromise()
      .then(
        (success) => {
          this._successAlert.next('Alerte flux créée');
        },
        (failed) => {
          this._errorAlert.next('Erreur lors de l\'ajout');
        })
      .then(() => {
        this.alertService.getAlertSubscriptions(this.userId)
          .subscribe((data: PrestiPrev.AlertSubscriptionViewModel[]) => {
            this.alertSubscriptions = data;
            this.loadHeadElements();
            this.setDataTable();
            this.navigateToLastPage();
          });
      });
  }

  updateAlertSubscription(alertSubscription: PrestiPrev.AlertSubscriptionViewModel) {
    this.alertService.updateAlertSubscription(alertSubscription)
      .subscribe(success => {
        if (success) {
          this._successAlert.next('Alerte flux modifiée');
        } else {
          this._errorAlert.next('Erreur lors de la modification');
        }

        this.loadDataTable();
      });
  }

  deleteAlertSubscription(subscriptionId: string) {
    this.alertService.deleteAlertSubscription(subscriptionId)
      .subscribe(success => {
        if (success) {
          this._warningAlert.next('Alerte flux supprimée');
        } else {
          this._errorAlert.next('Erreur à la suppression');
        }

        this.loadDataTable();
      });
  }
}
