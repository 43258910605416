import { Component, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';

/**
 * Dans le composant parent qui appelle le composant générique column chart : exemple :
  component.html :  <app-column-chart [options] = "optionsChart" [series] = "seriesChart" [categories] = "categoriesChart"></app-column-chart>
  component.ts :
  import { OptionsStatEdiService } from '@app/flux-edi/statistics/options-stat-edi.service';
  this.optionsChart = this.OptionsStatEdiService.getColumnChartOptions();
  this.seriesChart = [{data: [5, 3, 4, 7, 2]}, {data: [5, 3, 4, 7, 2]}]; // faire un appel au service de formatage
  this.categoriesChart = ["Apples", "Oranges", "Pears", "Grapes", "Bananas"]; // faire un appel au service periode
  exemple https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/demo/column-stacked
 */

Highcharts.setOptions({
  chart: {
    type: 'column'
  }
});

@Component({
  selector: 'app-column-chart',
  templateUrl: './column-chart.component.html',
  styleUrls: ['./column-chart.component.scss']
})

export class ColumnChartComponent implements OnChanges, OnDestroy {
  @Input() options: Highcharts.Options; // dans un service exemple OptionsStatEdiService. (from '@app/flux-edi/statistics/options-stat-edi.service';)
  @Input() series: Highcharts.SeriesOptionsType[]; // data
  @Input() categories: string[]; // les libellés de l'axe des abscisses

  public chart: Highcharts.Chart;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (this.options) {
      this.initChart();
    }
  }

  private initChart() {
    this.options.series = this.series;
    this.chart = new Highcharts.Chart('container', this.options);
    this.chart.xAxis[0].setCategories(this.categories);
  }

  ngOnDestroy() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }
  }
}
