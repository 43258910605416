import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MdbTablePaginationComponent, MdbTableDirective } from 'angular-bootstrap-md';
import { BaseChartDirective } from 'angular-bootstrap-md';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { FluxEdiService } from '@app/_services';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-surveillance-prestij',
  templateUrl: './surveillance-prestij.component.html',

  styleUrls: ['./surveillance-prestij.component.scss'],
  animations: [
  ],
})

/** surveillance-prestij component*/
export class SurveillancePrestijComponent implements OnInit, AfterViewInit {

  @ViewChild(MdbTableDirective, { static: false }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: false }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild("tableFlux", { static: false }) tableFlux: ElementRef;
  @ViewChild(BaseChartDirective, { static: false }) private _chart;

  nbGestip = 0;
  nbArl = 0;
  nbCr = 0;
  nbBpij = 0;
  nbNoArl = 0;
  nbArlRejected = 0;
  nbNoCr = 0;
  nbCrRejected = 0;
  counterTransit = 0;
  counterNonconformite = 0;
  tabTransitId = 'transit-prestij';
  tabTransfertId = 'transfert-prestij';
  tabNonConformiteId = 'non-conformite-prestij';
  selectedTab = this.tabTransitId;

  /** surveillance-prestij ctor */
  constructor(public fluxediService: FluxEdiService, private router: Router, private route: ActivatedRoute, private cdRef: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    const queryModule = this.route.snapshot.params['module'];
    if (queryModule !== null) {
      switch (queryModule) {
        case this.tabTransitId: {
          this.selectedTab = this.tabTransitId;
          break;
        }
        case this.tabTransfertId: {
          this.selectedTab = this.tabTransfertId;
          break;
        }
        case this.tabNonConformiteId: {
          this.selectedTab = this.tabNonConformiteId;
          break;
        }
      }
    }
  }

  ngAfterViewInit(): void {
  }

  onNbTransitUpdated(length: number) {
    this.counterTransit = length;
  }

  onDataNoArlChange(length: number) {
    this.nbNoArl = length;
    this.refreshCounterNonconformite();
  }

  onDataArlRejectedChange(length: number) {
    this.nbArlRejected = length;
    this.refreshCounterNonconformite();
  }

  onDataNoCrChange(length: number) {
    this.nbNoCr = length;
    this.refreshCounterNonconformite();
  }

  onDataCrRejectedChange(length: number) {
    this.nbCrRejected = length;
    this.refreshCounterNonconformite();
  }

  onSelectFlux(id: string) {
    this.router.navigate(['/edi/' + id]);
  }

  refreshCounterNonconformite() {
    this.counterNonconformite = this.nbNoArl + this.nbArlRejected + this.nbNoCr + this.nbCrRejected;
  }
}


