import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import Edi = Models.Edi;
import PrestiPrev = Models.PrestiPrev;

@Pipe({ name: 'booleanTransform' })
export class BooleanTransformPipe implements PipeTransform {
  transform(value: boolean) {
    return (value ? 'OK' : 'KO');
  }
}

@Pipe({ name: 'StatutFluxEdiTransform' })
export class StatutFluxEdiTransformPipe implements PipeTransform {
  transform(value: Edi.StatutFluxEdi) {
    let toReturn = 'OK';
    switch (value) {
    case Edi.StatutFluxEdi.enErreurTransfert:
    {
      toReturn = 'KO';
      break;
    }
    default:
    {
      toReturn = 'OK';
      break;
    }
    }
    return toReturn;
  }
}

@Pipe({ name: 'statutArlTransform' })
export class StatutArlTransformPipe implements PipeTransform {
  transform(value: string) {
    let toReturn = 'Tout';
    switch (value) {
    case 'A':
    {
      toReturn = 'Accepté';
      break;
    }
    case 'R':
    {
      toReturn = 'Refusé';
      break;
    }
    case 'null':
    {
      toReturn = 'Non reçu';
      break;
    }
    }
    return toReturn;
  }
}

@Pipe({ name: 'dateStringPipe' })
export class DateStringPipe implements PipeTransform {
  constructor(public datepipe: DatePipe) {

  }
  transform(value: Date) {
    return this.datepipe.transform(value, 'dd/MM/yyyy HH:mm');
  }
}

@Pipe({ name: 'nameTransform' })
export class NameTransformPipe implements PipeTransform {
  transform(value: string) {
    return value.slice(0, 1) + '.';
  }
}

@Pipe({ name: 'alertFrequencyTransform' })
export class AlertFrequencyTransformPipe implements PipeTransform {
  transform(value: PrestiPrev.Frequency, availableFrequencies: PrestiPrev.Frequency[]) {
    let toReturn = 'Inconnue';

    if (value.passingDays) {
      toReturn = availableFrequencies.find(item => item.type === value.type && item.passingDays === value.passingDays).label;
    } else if (value.toHour) {
      toReturn = availableFrequencies.find(item => item.type === value.type && item.toHour === value.toHour).label;
    }
    
    return toReturn;
  }
}
