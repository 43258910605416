import { Component } from '@angular/core';

@Component({
    selector: 'app-regimes-complementaires',
    templateUrl: './regimes-complementaires.component.html',
    styleUrls: ['./regimes-complementaires.component.scss']
})
/** regimes-complementaires component*/
export class RegimesComplementairesComponent {
    /** regimes-complementaires ctor */
    constructor() {

    }
}