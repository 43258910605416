import { Component, OnInit, AfterViewInit, ChangeDetectorRef, ViewChild, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CheckboxModule, MdbTablePaginationComponent, MdbTableDirective } from 'angular-bootstrap-md';
import HeadElement = Models.HeadElement;
import PrestiPrev = Models.PrestiPrev;
import { AuthenticationService, NotificationService, SharedEventsService } from '@app/_services';
import { NotificationsFormModel, NotificationsViewModel } from '@app/_core/view-models/notifications/notifications-form-model';
import { ActivatedRoute, RouterEvent, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { catchError, takeUntil, filter } from 'rxjs/operators';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
/** notifications component*/
export class NotificationsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MdbTableDirective, { static: false }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: false }) mdbTablePagination: MdbTablePaginationComponent;

  filteredDataTable: any[] = [];
  userNotifications = new Array<PrestiPrev.UserNotificationViewModel>();
  userNotifFormModel = new NotificationsFormModel();
  headElements = new Array<HeadElement>();
  notificationIds: string[] = [];
  notificationIdsToCheck: string[] = [];
  userId: string = null;
  filtres = new Map();

  sortingElement = '';
  filterSubjectElem = 'Tout';

  previousTableItems = [];
  maxVisibleItems = 10;
  pageIndex = 0;
  itemIndex = 0;
  notifCounter = 0;

  tableHide = true;
  canRefresh = false;
  toggle = false;
  empty = false;
  hasSubjectFilter = false;
  allChecked = false;
  serviceFetchError = false;

  destroyed = new Subject<any>();
  /** notifications ctor */
  constructor(
    public notificationService: NotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
    private sharedEvents: SharedEventsService,
    private cdRef: ChangeDetectorRef,
    public datepipe: DatePipe,
    public checkBox: CheckboxModule) {
    this.userId = this.authService.currentUserValue.id;
    this.sharedEvents.newNotifications.subscribe(() => {
      this.loadDataTable();
    });
  }

  ngOnInit() {
    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd), takeUntil(this.destroyed)
    ).subscribe((event) => {
      this.loadDataTable();
      this.notificationIds = [];
    });
  }

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.maxVisibleItems);
    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();

    this.cdRef.detectChanges();
    this.loadDataTable();
  }

  onMarkSelectedAsRead() {
    this.notificationService.setUserNotificationsRead(this.notificationIds)
      .subscribe(success => {
        if (success) {
          this.notificationIds = [];
          this.loadDataTable();
          this.allChecked = false;
        } else {
          console.log('Erreur au marquage à lu');
        }
      });
  }

  onMarkSelectedAsUnread() {
    this.notificationService.setUserNotificationsUnread(this.notificationIds)
      .subscribe(success => {
        if (success) {
          this.notificationIds = [];
          this.loadDataTable();
          this.allChecked = false;
        }
      });
  }

  onDeleteSelected() {
    this.notificationService.deleteUserNotifications(this.notificationIds)
      .subscribe(success => {
        if (success) {
          this.notificationIds = [];
          this.allChecked = false;
          this.loadDataTable();
          if ((this.mdbTablePagination.activePageNumber > 1 && this.mdbTablePagination.firstItemIndex === this.mdbTablePagination.lastItemIndex)) {
            this.mdbTablePagination.previousPage();
          }
        } else {
          console.log('Erreur à la suppression');
        }
      });
  }

  onDescriptionClick(notification: PrestiPrev.UserNotificationViewModel) {
    this.notificationIds = [notification.id];
    this.notificationService.setUserNotificationsRead(this.notificationIds)
      .subscribe();
  }

  onFilterSubjectClick(filter: string, elem: string, indexFilter: number) {
    this.applyFilterSubject(filter, elem, indexFilter);
    this.mdbTablePagination.firstPage();
  }

  applyFilterSubject(filter: string, elemId: string, indexFilter: number) {
    this.filteredDataTable = this.userNotifFormModel.notifications;

    this.applyFilter(filter, elemId, indexFilter);

    if (filter === "Tous") {
      this.filtres.delete(elemId);
    } else {
      var filterItem;

      filterItem = item => item.subject === filter;

      this.filtres.set(elemId, filterItem);
    }

    this.applyFiltersTable();
  }

  applyFilter(filter: string, elem: string, indexFilter: number) {
    this.filterSubjectElem = filter;
    this.disabledOff(this.headElements[this.findHeadIndex(elem)].dataFilter);
    this.newDisabledOn(this.headElements[this.findHeadIndex(elem)].dataFilter, indexFilter);
    this.hasSubjectFilter = true;
    if (filter === 'Tous') {
      this.hasSubjectFilter = false;
    }
  }

  applyFiltersTable() {
    this.filteredDataTable = this.userNotifFormModel.notifications.filter(item => {
      for (const filter of this.filtres.values()) {
        if (!filter(item.userNotification)) {
          return false;
        }
      }
      return true;
    });
    this.mdbTable.setDataSource(this.filteredDataTable);
  }

  disabledOff(data: any[]) {
    data.forEach(function (element) {
      if (element.default === true) {
        element.default = false;
      }
    });
  }

  newDisabledOn(data: any[], indexFilter: number) {
    data[indexFilter].default = true;
  }

  findHeadIndex(elemId: string): number {
    const headIndex = this.headElements.findIndex(item => item.id === elemId);
    return headIndex;
  }

  findFilterIndex(headIndex: number, elemId: string) {
    const filterIndex = this.headElements[headIndex].dataFilter.findIndex(item => item.id === elemId);
    return filterIndex;
  }

  setAllChecked(checked: boolean) {
    this.allChecked = checked;
    this.pageIndex = this.mdbTablePagination.firstItemIndex;
    this.userNotifFormModel.notifications.forEach((notif, index) => {
      notif.checked = checked;
      notif.checked ? this.notificationIds.push(notif.userNotification.id) : this.notificationIds.splice(this.notificationIds.indexOf(notif.userNotification.id));
    });

    /* début solution si besoin de sélectionner 10 élément de la page active (je vois dans le futur)*/
    //for (let i = this.pageIndex-1; i < this.pageIndex + 9; i++) {
    //  const notif = this.userNotifFormModel.notifications[i];
    //  this.userNotifFormModel.notifications[i].checked = checked;
    //  this.userNotifFormModel.notifications[i].checked ? this.notificationIds.push(notif.userNotification.id) : this.notificationIds.splice(this.notificationIds.indexOf(notif.userNotification.id));
    //}
  }

  setOneChecked(checked: boolean, notification) {
    notification.checked = checked;
    notification.checked ? this.notificationIds.push(notification.userNotification.id) : this.notificationIds.splice(this.notificationIds.indexOf(notification.userNotification.id), 1);

    if (this.mdbTablePagination.lastItemIndex === this.mdbTablePagination.lastVisibleItemIndex) {
      if ((this.mdbTablePagination.activePageNumber > 1 && this.mdbTablePagination.calculateHowManyPagesShouldBe() < this.mdbTablePagination.activePageNumber) || this.notificationIds.length > 1) {
      }
    }
  }

  setDataTable() {
    // Chargement du tableau
    this.mdbTable.setDataSource(this.userNotifFormModel.notifications);
    this.filteredDataTable = this.mdbTable.getDataSource();
    this.previousTableItems = this.mdbTable.getDataSource();
    this.mdbTablePagination.ofKeyword = 'sur';

    if (this.mdbTablePagination.activePageNumber > 1 && this.mdbTablePagination.firstItemIndex > this.userNotifFormModel.notifications.length) {
      this.mdbTablePagination.previousPage();
    }
    this.canRefresh = false;
  }

  getPaginationDisabled(length: number): string {
    if (length <= 10) {
      return 'pagination-disabled';
    }
  }

  loadDataTable() {
    this.serviceFetchError = false;
    this.userNotifFormModel.notifications = [];
    this.notifCounter = 0;
    this.notificationService.getUserNotifications(this.userId)
      .pipe(
        catchError(error => {
          throw error;
        })
      )
      .subscribe((data: PrestiPrev.UserNotificationViewModel[]) => {
        this.userNotifications = data;
        this.userNotifications.forEach((notif) => {
          const notification = new NotificationsViewModel(notif);
          this.userNotifFormModel.notifications.push(notification);
          if (notif.read === false) {
            this.notifCounter += 1;
          }
          this.notificationIdsToCheck.push(notification.userNotification.id);
        });
        this.loadHeadElements();
        if (this.userNotifications.length > 0) {
          this.setDataTable();
          this.loadSubjectFilter(this.userNotifFormModel.notifications);
          this.tableHide = false;
        } else {
          this.tableHide = true;
        }
      },
        (error) => {
          this.serviceFetchError = true;
        }
      );
  }

  loadSubjectFilter(dataTable: any) {
    const datas = [];
    dataTable.forEach(function (item) {
      if (!datas.includes(item.subject)) {
        datas.push({ id: item.userNotification.subject, color: 'normal-level', default: false });
      }
    });

    // Création de l'array filtre final SANS doublons
    const data = Array.from(new Set(datas.map(a => a.id)))
      .map(id => {
        return datas.find(a => a.id === id);
      });
    data.push({ id: 'Tous', default: true });
    const index = this.headElements.findIndex(item => item.id === 'subject');
    this.headElements[index].dataFilter = data;
  }

  loadHeadElements() {
    this.headElements = [
      { id: 'subject', head: 'Objet', dataFilter: [], filter: true, sortable: false, toggle: false },
      { id: 'description', head: 'Description', dataFilter: [], filter: false, sortable: false, toggle: false },
      { id: 'userNotification.date', head: 'Date', dataFilter: [], filter: false, sortable: true },
    ];
  }

  getRouterLink(link: string) {
    if (link.includes('?')) {
      this.getRouterlinkFromUrlWithQueries(link);
    } else {
      return link;
    }
  }

  private getRouterlinkFromUrlWithQueries(link: string) {
    const splittedUrl = link.split('?');
    const params = (new URL(link, location.origin)).searchParams;
    const section = params.get('tile');
    const filters = params.get('filters');
    return [splittedUrl[0] + '/' + section + '/' + filters];
  }

  getHref(link: string) {
    const href = (new URL(link, location.origin)).href;
    return href;
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
