import { NirSirenValidationHelper } from "@app/_core/helpers";
import { HeadElementViewData } from "@app/_core/view-models/common/head-element-view-data";

export class BpijBenefitsTableViewModel {
  private _tableHeader: HeadElementViewData[];
  private _headerTechnicalIds = [
    "declarantIdentity",
    "bpijIdentity",
    "archiveFileLabel",
  ];
  private _bpijBenefits: Models.Edi.BpijBenefitViewModel[];

  constructor() {
    this.initHeader();
  }

  public getHeader(withTechnicalData: boolean): HeadElementViewData[] {
    if (withTechnicalData) return this._tableHeader;

    return this._tableHeader.filter(head => {
      return !(this._headerTechnicalIds.includes(head.id));
    });
  }

  public get dataTable(): Models.Edi.BpijBenefitViewModel[] {
    return this._bpijBenefits;
  }

  public setBpijBenefits(bpijBenefits: Models.Edi.BpijBenefitViewModel[]) {
    this._bpijBenefits = bpijBenefits;
  }

  private initHeader() {
    this._tableHeader = [
      new HeadElementViewData({ id: "receiptDate", head: "Date réception Prest'iPrev", class: "white-space" }),
      new HeadElementViewData({ id: "declaredSiret", head: "SIRET", class: "siret", format: NirSirenValidationHelper.formatSiret }),
      new HeadElementViewData({ id: "declaredBusinessName", head: "Raison sociale", class: "business-name" }),
      new HeadElementViewData({ id: "lastName", head: "Nom", class: "ellipsis" }),
      new HeadElementViewData({ id: "firstName", head: "Prénom", class: "ellipsis" }),
      new HeadElementViewData({ id: "nir", head: "NIR", class: "nir", format: NirSirenValidationHelper.formatNir }),
      new HeadElementViewData({ id: "insuranceNature", head: "Nature assurance", class: "insurance-nature" }),
      new HeadElementViewData({ id: "roBenefitNatureCode", head: "Code nature prestation RO", class: "benefit-nature-code" }),
      new HeadElementViewData({ id: "roBenefitStartingDate", head: "Date début paiement RO", class: "benefit-date" }),
      new HeadElementViewData({ id: "roBenefitEndingDate", head: "Date fin paiement RO", class: "benefit-date" }),
      new HeadElementViewData({ id: "dailyPaymentSubrogation", head: "IJ Subrogé", class: this.bold }),
      new HeadElementViewData({ id: "grossDailyPayment", head: "IJ brut (€)", class: data => data < 0 ? "bold align-right negative" : "bold align-right", format: this.money }),
      new HeadElementViewData({ id: "netDailyPayment", head: "IJ net (€)", class: "daily-payment", format: this.money }),
      new HeadElementViewData({ id: "dailyPaymentNumber", head: "Nb IJ", class: "daily-payment" }),
      new HeadElementViewData({ id: "roBenefitGrossAmount", head: "Montant brut paiement RO (€)", class: "align-right", format: this.money }),
      new HeadElementViewData({ id: "crdAmount", head: "Montant CRDS (€)", class: "align-right", format: this.money }),
      new HeadElementViewData({ id: "csgAmount", head: "Montant CSG (€)", class: "align-right", format: this.money }),
      new HeadElementViewData({ id: "roBenefitNetAmount", head: "Montant net paiement RO (€)", class: "align-right", format: this.money }),
      new HeadElementViewData({ id: "sinisterNumber", head: "NumSinistre", class: "sinister-number" }),
      new HeadElementViewData({ id: "benefitLabel", head: "Libellé paiement", class: "white-space" }),
      new HeadElementViewData({ id: "insuranceNatureCode", head: "Code nature assurance", class: "white-space" }),
      new HeadElementViewData({ id: "insuranceBenefitTotal", head: "Total paiement assurance (€)", class: "align-right", format: this.money }),
      new HeadElementViewData({ id: "employeeTotal", head: "Total salarié (€)", class: "align-right" }),
      new HeadElementViewData({ id: "cpamCode", head: "Code CPAM", class: "white-space" }),
      new HeadElementViewData({ id: "cpamLabel", head: "Libellé CPAM", class: "white-space" }),
      new HeadElementViewData({ id: "accountingDay", head: "Jour comptable", class: "white-space" }),
      new HeadElementViewData({ id: "cpamTotal", head: "Total CPAM (€)", class: "align-right", format: this.money }),
      new HeadElementViewData({ id: "declaredSiret", head: "SIRET déclaré", class: "white-space", format: NirSirenValidationHelper.formatSiret }),
      new HeadElementViewData({ id: "declaredBusinessName", head: "Raison sociale déclaré", class: "white-space" }),
      new HeadElementViewData({ id: "societyTotal", head: "Total entreprise (€)", class: "align-right", format: this.money }),
      new HeadElementViewData({ id: "cnamProcessingDate", head: "Date traitement CNAM", class: "white-space" }),
      new HeadElementViewData({ id: "declarantIdentity", head: "Emetteur / destinataire", class: "" }),
      new HeadElementViewData({ id: "bpijIdentity", head: "Id flux", class: "ellipsis" }),
      new HeadElementViewData({ id: "archiveFileLabel", head: "Id fichier", class: "ellipsis file-id" })
    ];
  }

  private bold(subrogated: boolean): string {
    return subrogated === true ? "bold" : "";
  }

  private money(number: number): string {
    if (number == null) number = 0;
    return number.toLocaleString("fr-FR", { currency: "EUR", maximumFractionDigits: 2 });
  }
}
