import { Injectable } from "@angular/core";
import DWH = Models.DataWarehouse;
import { StatisticsArlReceivedFlowViewModel } from "@app/_core/view-models/datawarehouse/edi-flow/view-models-from-models/statistics-arl-received-flow-view-model";

@Injectable({
  providedIn: 'root'
})

export class ArlReceivedService {
  getViewModel(model: DWH.PeriodicEdiFlowsStatisticsModel[]): StatisticsArlReceivedFlowViewModel[] {
    let vm: StatisticsArlReceivedFlowViewModel[];

    vm = model.map(m => new StatisticsArlReceivedFlowViewModel(m));

    return vm;
  }
}
