// SDU> TODO
export class NameOf {
  static nameof(object: any): string {
    let toReturn = "";

    let objectToString = typeof object;

    return toReturn;
  }

  //static nameof(type: string, property: string): string {
  //  let toReturn = "";

  //  switch (type) {
  //    case "EdiFileInfo":
      
  //  default:
  //  }

  //  return toReturn;
  //}

  static nameofEdiFileInfo(property: string): string {
    let toReturn = "";

    switch (property) {
      case "stateLevel":
        toReturn = "stateLevel"
    default:
    }

    return toReturn;
  }
}
