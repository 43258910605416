import { Component, OnInit, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { CommonVar } from '@app/_core/models/';
import { Router } from '@angular/router';
import { Routes } from '@app/_core/routes'
import { interval, Subscription } from 'rxjs';
import { NotificationService, AuthenticationService, SharedEventsService } from '@app/_services';
import PrestiPrev = Models.PrestiPrev;
import { LoginComponent } from './login/login.component';
declare var $: any;

interface Alert {
  id: string;
  type: string;
  subject: string;
  message: string;
  link: string;
  class: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [LoginComponent]
})
@Injectable()
export class AppComponent implements OnInit {
  title = 'PrestiPrev';
  users: any[];
  userId: string;
  notificationIds: string[] = [];
  opened = true;
  notificationTimer;
  notifications: PrestiPrev.UserNotificationViewModel[] = [];
  alerts: Alert[] = [];
  bellNotifyDisplayed = false;
  hasNew = false;
  timer = 25;
  displayedNotificationInterval = interval(this.timer * 1000);
  subscription: Subscription;

  constructor(
    private global: CommonVar,
    private router: Router,
    private location: Location,
    private notificationService: NotificationService,
    private authService: AuthenticationService,
    private sharedEvents: SharedEventsService) {
    this.userId = this.authService.currentUserValue !== null ? this.authService.currentUserValue.id : "";

    this.sharedEvents.logedIn.subscribe(userId => {
      this.userId = userId;
      this.onLogedIn();
    });
  }

  ngOnInit(): void {
    const currentRoot = this.location.path();
    if (currentRoot === '') {
      this.router.navigate([Routes.Accueil]);
    }

    // Get undisplayed notifications every 25s
    this.displayedNotificationInterval.subscribe(x => {
      this.loadNewNotifications(this.timer);
    });
    // Get has newer notifications
    this.userHasNewNotifications();
  }

  onLogedIn() {
    this.userHasNewNotifications();
  }

  onNotificationPopupClick(notification: Alert) {
    this.notificationIds = [notification.id];
    this.notificationService.setUserNotificationsRead(this.notificationIds)
      .subscribe();
  }

  loadNewNotifications(fromSeconds: number) {
    if (this.userId === '') { return; }
    this.notificationService.getLatestUserNotifications(this.userId, fromSeconds)
      .subscribe((data: PrestiPrev.UserNotificationViewModel[]) => {
        if (data !== null && data.length > 0) {
          this.notifications.push(...data);
          this.hasNew = true;
          this.location.path() === '/' + Routes.Notifications ? this.bellNotifyDisplayed = false : this.bellNotifyDisplayed = true;
          this.displayNewNotification();
          this.sharedEvents.newNotifications.emit(this.hasNew);
        }
      });
  }

  userHasNewNotifications() {
    if (this.userId === '') { return; }
    this.notificationService.userHasNewNotifications(this.userId)
      .subscribe((hasNew: boolean) => {
        if (hasNew) {
          this.hasNew = true;
          this.location.path() === '/' + Routes.Notifications ? this.bellNotifyDisplayed = false : this.bellNotifyDisplayed = true;
        }
      });
  }

  unmarkPushNotificationsAsNew() {
    if (this.userId === '') { return; }
    this.notificationService.unmarkPushNotificationsAsNew(this.userId)
      .subscribe();
  }

  displayNewNotification() {
    const unstacking = this.notifications.splice(0, this.notifications.length);
    unstacking.forEach((notif) => {
      const type = notif.alertSubscription.alertTemplate.level === PrestiPrev.AlertLevel.info ? 'warning' : 'danger';
      this.alerts.push({ id: notif.id, type: type, subject: notif.subject, message: notif.description, link: notif.alertSubscription.alertTemplate.prestiPrevLinkUrl, class: '' });
      this.alerts[0].class = 'alert-first';
    });

    // Remove first displayed notification after 3s
    this.subscription = interval(4000).subscribe(x => {
      if (this.alerts.length > 0) {
        this.alerts.shift();
        if (this.alerts.length > 0) {
          this.alerts[0].class = 'alert-first';
        } else {
          this.subscription.unsubscribe();
        }
      }
    });
  }

  onNotificationReseted() {
    this.hasNew = false;
    this.bellNotifyDisplayed = false;
    this.unmarkPushNotificationsAsNew();
  }

  getRouterLink(link: string) {
    if (link.includes('?')) {
      this.getRouterlinkFromUrlWithQueries(link);
    } else {
      return link;
    }
  }
  private getRouterlinkFromUrlWithQueries(link: string) {
    const splittedUrl = link.split('?');
    const params = (new URL(link, location.origin)).searchParams;
    const section = params.get('tile');
    const filters = params.get('filters');
    return [splittedUrl[0] + '/' + section + '/' + filters];
  }
}
