export class ApiEdiUrlsHelper {
  static readonly getAllFluxEdiTransferredFromDate = 'getAllFluxEdiTransferredFromDate';
  getSendedFluxEdiGestipFromDate: string;
  getReceivedFluxEdiBpijFromDate: string;
  getAllArlInBal: string;
  getAllCrInBal: string;
  getAllBpijInBal: string;
  getUnsendedFluxEdiGestip: string;
  getAllGestipWithoutArl: string;
  getAllGestipWithArlRejected: string;
  getAllGestipWithoutCr: string;
  setStateOfGestipArlAbsentTraite: string;
  setStateOfGestipArlEnErreurTraite: string;
  setStateOfGestipCrAbsentTraite: string;
  getAllOperationEdiGestipWithoutCrOrWithCrRejected: string;
  setStateOfOperationGestipCrAbsentOrCrInvalideTraite: string;
}
