import { Routes } from "./routes";

export class Menu {
  private _links: Array<MenuLinks>;

  constructor() {
    this._links = new Array<MenuLinks>();
    this._links.push(
      {
        id: 'accueil',
        title: 'Accueil',
        linkActive: true,
        class: 'nav-item',
        linkClass: 'nav-link',
        routeurLink: Routes.Accueil,
        icon: 'iconHome',
        description: 'Page d\'accueil Prest\'iPrev',
        hasSubMenu: false,
        menuLinks: [],
        isDisplayed: true
      },
      {
        id: 'clients',
        title: 'Clients',
        linkActive: false,
        routeurLink: Routes.Clients,
        class: 'nav-item',
        linkClass: 'nav-link',
        icon: 'iconClients',
        description: '',
        hasSubMenu: false,
        menuLinks: [],
        isDisplayed: false
      },
      {
        id: 'regimescomp',
        title: 'Régimes Complémentaires',
        linkActive: false,
        routeurLink: Routes.RegimesComplementaires,
        class: 'nav-item',
        linkClass: 'nav-link',
        icon: 'iconRegimesComp',
        description: '',
        hasSubMenu: false,
        menuLinks: [],
        isDisplayed: false
      },
      {
        id: 'contrats',
        title: 'Contrats',
        linkActive: false,
        routeurLink: Routes.Contrats,
        class: 'nav-item',
        linkClass: 'nav-link',
        icon: 'iconContrats',
        description: '',
        hasSubMenu: false,
        menuLinks: [],
        isDisplayed: false
      },
      {
        id: 'porteursrisque',
        title: 'Porteurs de risque',
        linkActive: false,
        routeurLink: Routes.PorteursRisque,
        class: 'nav-item',
        linkClass: 'nav-link',
        icon: 'iconPorteursRisque',
        description: '',
        hasSubMenu: false,
        menuLinks: [],
        isDisplayed: false
      },
      {
        id: 'adminmetier',
        title: 'Administration métier',
        linkActive: false,
        class: 'nav-item',
        linkClass: 'nav-link',
        routeurLink: Routes.AdministrationMetier,
        icon: 'iconAdminMetier',
        description: '',
        hasSubMenu: true,
        isDisplayed: false,
        menuLinks: [
          {
            id: '',
            title: 'Listes de valeurs',
            linkActive: false,
            class: 'subnav-item',
            linkClass: 'subnav-link',
            routeurLink: Routes.AdministrationMetierListesValeurs,
            icon: 'iconListeDeValeurs',
            description: '',
            hasSubMenu: false,
            menuLinks: [],
            isDisplayed: true
          }
        ]
      },
      {
        id: 'prestij',
        title: 'Prest\'IJ',
        linkActive: false,
        class: 'nav-item',
        linkClass: 'nav-link',
        routeurLink: Routes.Prestij,
        icon: 'iconPrestij',
        description: '',
        hasSubMenu: true,
        isDisplayed: true,
        menuLinks: [
          {
            id: '',
            title: 'Données',
            linkActive: false,
            class: 'subnav-item',
            linkClass: 'subnav-link',
            routeurLink: Routes.PrestijDonnees,
            icon: 'iconData',
            description: 'Rechercher et consulter les données contenues dans les flux Prest’IJ',
            hasSubMenu: false,
            menuLinks: [],
            isDisplayed: true
          },
          {
            id: '',
            title: 'Facturation',
            linkActive: false,
            class: 'subnav-item',
            linkClass: 'subnav-link',
            routeurLink: Routes.PrestijFacturations,
            icon: 'iconFacturation',
            description: '',
            hasSubMenu: false,
            menuLinks: [],
            isDisplayed: false
          },
          {
            id: '',
            title: 'Statistiques',
            linkActive: false,
            class: 'subnav-item',
            linkClass: 'subnav-link',
            routeurLink: Routes.PrestijStatistiques,
            icon: 'iconStats1',
            description: '',
            hasSubMenu: false,
            menuLinks: [],
            isDisplayed: false
          }
        ]
      },
      {
        id: 'edi',
        title: 'Flux EDI',
        linkActive: false,
        class: 'nav-item',
        linkClass: 'nav-link',
        routeurLink: Routes.FluxEdi,
        icon: 'iconEdi',
        description: 'Surveiller, alerter et consulter l\’historique des flux EDI',
        hasSubMenu: true,
        isDisplayed: true,
        menuLinks: [
          {
            id: '',
            title: "Surveillance",
            linkActive: false,
            class: 'subnav-item',
            linkClass: 'subnav-link',
            routeurLink: Routes.FluxEdiSurveillance,
            icon: 'iconMonitoring',
            description: 'Surveiller le transit, les transferts de fichier et les non-conformités des flux EDI',
            hasSubMenu: false,
            menuLinks: [],
            isDisplayed: true
          },
          {
            id: '',
            title: "Alertes",
            linkActive: false,
            class: 'subnav-item',
            linkClass: 'subnav-link',
            routeurLink: Routes.FluxEdiAlertes,
            icon: 'iconAlerts',
            description: 'Paramétrer des alertes sur le transit, les transferts de fichier et la non-conformité des flux EDI',
            hasSubMenu: false,
            menuLinks: [],
            isDisplayed: true
          },
          {
            id: '',
            title: "Traçabilité",
            linkActive: false,
            class: 'subnav-item',
            linkClass: 'subnav-link',
            routeurLink: Routes.FluxEdiTracabilite,
            icon: 'iconTrace',
            description: 'Consulter l\'historique des flux EDI',
            hasSubMenu: false,
            menuLinks: [],
            isDisplayed: true
          },
          {
            id: '',
            title: "Statistiques (bêta)",
            linkActive: false,
            class: 'subnav-item',
            linkClass: 'subnav-link',
            routeurLink: Routes.FluxEdiStatistiques,
            icon: 'iconStats2',
            description: 'Statistiques sur des quantités ou délais de flux envoyés et reçus',
            hasSubMenu: false,
            menuLinks: [],
            isDisplayed: true
          }
        ]
      },
      {
        id: 'adminprestiprev',
        title: 'Administration Prest\'iPrev',
        linkActive: false,
        class: 'nav-item',
        linkClass: 'nav-link',
        routeurLink: Routes.AdministrationPrestiPrev,
        icon: 'iconAdminPrestiprev',
        description: '',
        hasSubMenu: true,
        isDisplayed: false,
        menuLinks: [
          {
            id: '',
            title: 'Groupes d\'utilisateurs',
            linkActive: false,
            class: 'subnav-item',
            linkClass: 'subnav-link',
            routeurLink: Routes.AdministrationPrestiPrevGroupesUtilisateurs,
            icon: 'iconGroupsUsers',
            description: '',
            hasSubMenu: false,
            menuLinks: [],
            isDisplayed: true
          },
          {
            id: '',
            title: "Rôles Prest'iPrev",
            linkActive: false,
            class: 'subnav-item',
            linkClass: 'subnav-link',
            routeurLink: Routes.AdministrationPrestiPrevRoles,
            icon: 'iconRoles',
            description: '',
            hasSubMenu: false,
            menuLinks: [],
            isDisplayed: true
          }
        ]
      });
  }

  public getMenuLinks(isEnvProd: boolean): MenuLinks[] {
    if (!isEnvProd) {
      return this._links;
    }

    this._links.forEach((item: MenuLinks) => {
      item.menuLinks = item.menuLinks.filter(subItem => subItem.isDisplayed);
    });
    return this._links.filter(item => item.isDisplayed);
  }
}

export interface MenuLinks {
  id: string;
  title: string;
  linkActive: boolean;
  class: string;
  linkClass: string;
  routeurLink: string;
  icon: string;
  description: string;
  hasSubMenu: boolean;
  menuLinks: MenuLinks[];
  isDisplayed: boolean;
}
