import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import PrestiPrev = Models.PrestiPrev;
import { catchError } from 'rxjs/operators';

@Injectable()
export class AlertService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {

  }

  getAlertSubscriptions(userId): Observable<PrestiPrev.AlertSubscriptionViewModel[]> {
    //Appel du .NetCore API Controller EDI local
    const apiUrl = 'api/alert/GetAlertSubscriptions';
    return this.http.get<PrestiPrev.AlertSubscriptionViewModel[]>(this.baseUrl + apiUrl.concat('?userId=' + userId));
  }

  addAlertSubscription(subscription: PrestiPrev.AlertSubscriptionViewModel): Observable<boolean> {
    //Appel du .NetCore API Controller EDI local
    const apiUrl = 'api/alert/AddAlertSubscription';
    const body = JSON.stringify(subscription);
    const headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<boolean>(this.baseUrl + apiUrl, body, { headers: headerOptions }).pipe(
      catchError(this.handleError<boolean>('addAlertSubscription', false))
    );
  }

  updateAlertSubscription(subscription: PrestiPrev.AlertSubscriptionViewModel): Observable<boolean> {
    //Appel du .NetCore API Controller EDI local
    const apiUrl = 'api/alert/UpdateAlertSubscription';
    const body = JSON.stringify(subscription);
    const headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<boolean>(this.baseUrl + apiUrl, body, { headers: headerOptions }).pipe(
      catchError(this.handleError<boolean>('updateAlertSubscription', false))
    );
  }

  deleteAlertSubscription(subscriptionId: string): Observable<boolean> {
    //Appel du .NetCore API Controller EDI local
    const apiUrl = 'api/alert/DeleteAlertSubscription';
    return this.http.get<boolean>(this.baseUrl + apiUrl.concat('?subscriptionId=' + subscriptionId)).pipe(
      catchError(this.handleError<boolean>('deleteAlertSubscription', false))
    );
  }

  getAlertTemplates(serviceCode: string): Observable<PrestiPrev.AlertTemplate[]> {
    //Appel du .NetCore API Controller EDI local
    const apiUrl = 'api/alert/GetAlertTemplates';
    return this.http.get<PrestiPrev.AlertTemplate[]>(this.baseUrl + apiUrl.concat('?serviceCode=' + serviceCode));
  }

  /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      //console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      //this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
