import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Period } from '@app/_core/view-models/datawarehouse/edi-flow/view-models-from-views/period';
import { GranularityType } from '@app/_core/view-models/datawarehouse/edi-flow/view-models-from-views/granularity-type';

@Injectable({
  providedIn: 'root'
})

/**
 * Service qui permet à partir d'une période donnée, de faire un traitement afin d'afficher l'axe des abscisses sur un graphe
 */
export class PeriodFormattedService {

  getDateRangeFormattedByGranularity(granularity: GranularityType, currentPeriod: Period): string[] {
    let dateRangeFormatted: string[];
    let currentBeginDate: Moment = currentPeriod.beginDate.clone().locale('fr');
    let formatDate: string = granularity === GranularityType.Days ? "DD/MM/YYYY" : "MMMM YYYY";
    let duration = (granularity === GranularityType.Days ? moment.duration(1, 'days') : moment.duration(1, 'month'));

    dateRangeFormatted = [currentBeginDate.format(formatDate)];
    while (currentBeginDate.toDate() < currentPeriod.endDate.toDate()) {
      currentBeginDate = currentBeginDate.add(duration);
      dateRangeFormatted.push(currentBeginDate.format(formatDate));
    }
    return dateRangeFormatted;
  }

}
