import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

/* MATERIAL DESIGN MODULES COMPONENTS*/
import { MatCheckboxModule, MatDatepickerModule, MatMenuModule, MatPaginatorIntl, MatPaginatorModule, MatRadioModule } from '@angular/material';
import { /*MatNativeDateModule, MatRippleModule,*/ MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';

/* MDB MODULES */
import { WavesModule, ChartsModule, TableModule, DropdownModule, IconsModule, CheckboxModule } from 'angular-bootstrap-md';
import { CommonVar } from '@app/_core/models';
import { Routes } from '@app/_core/routes';
import { FluxEdiService, AuthenticationService, AlertService, NotificationService, SharedEventsService } from '@app/_services';
import { ErrorInterceptor, AuthGuard } from './_core/helpers';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { FicheClientComponent } from './fiche-client/fiche-client.component';
import { HeaderMenuComponent } from './header-menu/header-menu.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { RegimesComplementairesComponent } from './regimes-complementaires/regimes-complementaires.component';
import { ContratsComponent } from './contrats/contrats.component';
import { AdministrationMetierComponent } from './administration-metier/administration-metier.component';
import { AdministrationPrestiprevComponent } from './administration-prestiprev/administration-prestiprev.component';
import { SurveillancePrestijComponent } from './flux-edi/surveillance-prestij/surveillance-prestij.component';
import { NonConformiteComponent } from './flux-edi/surveillance-prestij/non-conformite/non-conformite.component';
import { TransitComponent } from './flux-edi/surveillance-prestij/transit/transit.component';
import { FluxEdiComponent } from './flux-edi/flux-edi.component';
import { TransfertComponent } from './flux-edi/surveillance-prestij/transfert/transfert.component';
import { TracabilitePrestijComponent } from './flux-edi/tracabilite-prestij/tracabilite-prestij.component';
import { ModalTableComponent } from './flux-edi/tracabilite-prestij/modal-table/modal-table.component';
import { BooleanTransformPipe, StatutArlTransformPipe, DateStringPipe, StatutFluxEdiTransformPipe, NameTransformPipe, AlertFrequencyTransformPipe } from '@app/_core/pipes/custom.pipes';
import { EllipsifyMeDirective } from '@app/_core/custom_directives/ellipsify-me.directive';
import { LoaderComponent } from '@app/_layers/loader/loader.component';
import { TextMaskModule } from 'angular2-text-mask';
import { registerLocaleData, DatePipe } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { AlertesPrestijComponent } from './flux-edi/alertes-prestij/alertes-prestij.component';
import { PrestijDataComponent } from './prestij/prestij-data/prestij-data.component';
import { CalendarComponent } from '@app/_layers/calendar/calendar.component';
import { BpijService } from '@app/_services/bpij.service';
import { GestipService } from '@app/_services/gestip.service';
import { BpijBenefitsTableComponent } from './prestij/prestij-data/bpij-benefits-table/bpij-benefits-table.component';
import { MatPaginatorIntlExtended } from '@app/_layers/paginator/map-paginator-extended';
import { GestipOperationsTableComponent } from './prestij/prestij-data/gestip-operations-table/gestip-operations-table.component';
import { TooltipSurveillanceDirective } from './_core/custom_directives/tooltipSurveillance.directive';
import { StatisticsFluxEdiComponent } from './flux-edi/statistics/statistics-flux-edi/statistics-flux-edi.component';
import { GestipSentArlCrComponent } from './flux-edi/statistics/statistics-flux-edi/gestip-sent-arl-cr/gestip-sent-arl-cr.component';
import { ArlReceivedComponent } from './flux-edi/statistics/statistics-flux-edi/arl-received/arl-received.component';
import { CrReceivedComponent } from './flux-edi/statistics/statistics-flux-edi/cr-received/cr-received.component';
import { BpijReceivedComponent } from './flux-edi/statistics/statistics-flux-edi/bpij-received/bpij-received.component';
import { PeriodArlReceivedComponent } from './flux-edi/statistics/statistics-flux-edi/period-arl-received/period-arl-received.component';
import { PeriodCrReceivedComponent } from './flux-edi/statistics/statistics-flux-edi/period-cr-received/period-cr-received.component';
import { OptionsStatEdiService } from './flux-edi/statistics/services/options-stat-edi.service';
import { ColumnChartComponent } from '@app/_layers/charts/column-chart/column-chart.component';
import { PeriodByGranularityService } from './flux-edi/statistics/services/period-by-granularity.service';
import { PeriodFormattedService } from './flux-edi/statistics/services/period-formatted.service';
import { DataWarehouseService } from '@app/_services/data-warehouse.service';
import { ManageTransceiverTypeService } from './flux-edi/statistics/services/manage-transceiver-type.service';
import { DataFormattedService } from './flux-edi/statistics/services/data-formatted.service';
import { ModelFormattedService } from './flux-edi/statistics/services/model-formatted.service';

registerLocaleData(localeFr, 'fr-FR');

export const MAT_MOMENT_DATE_FORMATS_FR = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderMenuComponent,
    NavMenuComponent,
    HomeComponent,
    LoginComponent,
    FicheClientComponent,
    RegimesComplementairesComponent,
    ContratsComponent,
    FluxEdiComponent,
    TracabilitePrestijComponent,
    AlertesPrestijComponent,
    NotificationsComponent,
    ModalTableComponent,
    BooleanTransformPipe,
    StatutArlTransformPipe,
    DateStringPipe,
    StatutFluxEdiTransformPipe,
    NameTransformPipe,
    AlertFrequencyTransformPipe,
    SurveillancePrestijComponent,
    TransitComponent,
    NonConformiteComponent,
    TransfertComponent,
    AdministrationMetierComponent,
    AdministrationPrestiprevComponent,
    EllipsifyMeDirective,
    LoaderComponent,
    PrestijDataComponent,
    CalendarComponent,
    BpijBenefitsTableComponent,
    GestipOperationsTableComponent,
    TooltipSurveillanceDirective,
    StatisticsFluxEdiComponent,
    GestipSentArlCrComponent,
    ArlReceivedComponent,
    CrReceivedComponent,
    BpijReceivedComponent,
    PeriodArlReceivedComponent,
    PeriodCrReceivedComponent,
    ColumnChartComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    WavesModule.forRoot(),
    ChartsModule,
    TableModule,
    IconsModule,
    DropdownModule.forRoot(),
    NgbModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDatepickerModule,
    MatMenuModule,
    NgSelectModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule,
    MatPaginatorModule,
    RouterModule.forRoot([
      { path: Routes.Accueil, component: HomeComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: Routes.Login, component: LoginComponent },
      { path: Routes.Clients, component: FicheClientComponent, canActivate: [AuthGuard] },
      { path: Routes.RegimesComplementaires, component: RegimesComplementairesComponent, canActivate: [AuthGuard] },
      { path: Routes.Contrats, component: ContratsComponent, canActivate: [AuthGuard] },
      { path: Routes.PorteursRisque, component: HomeComponent, canActivate: [AuthGuard] },
      { path: Routes.Notifications, component: NotificationsComponent, canActivate: [AuthGuard] },
      { path: Routes.AdministrationMetier, component: HomeComponent, canActivate: [AuthGuard] },
      { path: Routes.AdministrationMetierListesValeurs, component: HomeComponent, canActivate: [AuthGuard] },
      { path: Routes.Prestij, component: HomeComponent, canActivate: [AuthGuard] },
      { path: Routes.PrestijDonnees, component: PrestijDataComponent, canActivate: [AuthGuard] },
      { path: Routes.PrestijFacturations, component: HomeComponent, canActivate: [AuthGuard] },
      { path: Routes.PrestijStatistiques, component: HomeComponent, canActivate: [AuthGuard] },
      { path: Routes.FluxEdi, component: FluxEdiComponent, canActivate: [AuthGuard] },
      { path: Routes.FluxEdiSurveillance, component: SurveillancePrestijComponent, canActivate: [AuthGuard] },
      { path: Routes.FluxEdiSurveillanceWithQueries, component: SurveillancePrestijComponent, canActivate: [AuthGuard] },
      { path: Routes.FluxEdiSurveillanceNonConformite, component: SurveillancePrestijComponent, canActivate: [AuthGuard] },
      { path: Routes.FluxEdiAlertes, component: AlertesPrestijComponent, canActivate: [AuthGuard] },
      { path: Routes.FluxEdiTracabilite, component: TracabilitePrestijComponent, canActivate: [AuthGuard] },
      { path: Routes.FluxEdiStatistiques, component: StatisticsFluxEdiComponent, canActivate: [AuthGuard] },
      { path: Routes.AdministrationPrestiPrev, component: AdministrationPrestiprevComponent, canActivate: [AuthGuard] },
      { path: Routes.AdministrationPrestiPrevGroupesUtilisateurs, component: HomeComponent, canActivate: [AuthGuard] },
      { path: Routes.AdministrationPrestiPrevRoles, component: HomeComponent, canActivate: [AuthGuard] }
    ], {
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule],
  providers: [
    DatePipe,
    CommonVar,
    AuthenticationService,
    FluxEdiService,
    BpijService,
    GestipService,
    AlertService,
    NotificationService,
    SharedEventsService,
    OptionsStatEdiService,
    PeriodByGranularityService,
    PeriodFormattedService,
    DataWarehouseService,
    ManageTransceiverTypeService,
    DataFormattedService,
    ModelFormattedService,
    CheckboxModule,
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS_FR },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlExtended }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
