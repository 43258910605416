export class Routes {
  static readonly Accueil = "Home";
  static readonly Login = "login";
  static readonly Clients = "fiche-client";
  static readonly RegimesComplementaires = "regimes-complementaires";
  static readonly Contrats = "contrats";
  static readonly PorteursRisque = "porteurs-risque";
  static readonly Notifications = "notifications";
  static readonly AdministrationMetier = "administration-metier";
  static readonly AdministrationMetierListesValeurs = "administration-metier-listes-valeurs";
  static readonly Prestij = "prestij";
  static readonly PrestijDonnees = "prestij-donnees";
  static readonly PrestijFacturations = "prestij-facturations";
  static readonly PrestijStatistiques = "prestij-statistiques";
  static readonly FluxEdi = "flux-edi";
  static readonly FluxEdiSurveillance = "flux-edi-surveillance";
  static readonly FluxEdiSurveillanceWithQueries = "flux-edi-surveillance/:module/:section/:filters";
  static readonly FluxEdiSurveillanceNonConformite = "flux-edi-surveillance/non-conformite-prestij"
  static readonly FluxEdiAlertes = "flux-edi-alertes";
  static readonly FluxEdiTracabilite = "flux-edi-tracabilite";
  static readonly FluxEdiStatistiques = "flux-edi-statistiques";
  static readonly AdministrationPrestiPrev = "administration-prestiprev";
  static readonly AdministrationPrestiPrevGroupesUtilisateurs = "administration-prestiprev-groupes-utilisateurs";
  static readonly AdministrationPrestiPrevRoles = "administration-prestiprev-roles";
}
