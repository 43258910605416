import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, Renderer2, OnDestroy, Output, EventEmitter, Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first, takeUntil } from 'rxjs/operators';

import { AuthenticationService, SharedEventsService } from '@app/_services';
import { Subscription, Subject } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
/** Login component*/
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {

  //@ViewChild(ModalDirective, { static: false }) mdbModal: ModalDirective;
  @ViewChild("passwordField", { static: false }) passwordField: ElementRef;
  @ViewChild("usernameField", { static: false }) usernameField: ElementRef;

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = false;
  password = 'password';
  username = 'username';
  private passwordIsVisible = false;
  private unsubscribe = new Subject<void>();

  /** Login ctor */
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private sharedEvents: SharedEventsService,
    private renderer: Renderer2
  ) {

  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.loginForm.get('password').valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        key => {
          if (this.error) {
            this.renderer.removeClass(this.passwordField.nativeElement, 'is-invalid');
            this.renderer.removeClass(this.usernameField.nativeElement, 'is-invalid');
            this.error = false;
          } else if (this.f.password.errors) {
            this.renderer.removeClass(this.passwordField.nativeElement, 'is-invalid');
          }
        }
      );
    this.loginForm.get('username').valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        key => {
          if (this.error) {
            this.renderer.removeClass(this.passwordField.nativeElement, 'is-invalid');
            this.renderer.removeClass(this.usernameField.nativeElement, 'is-invalid');
            this.error = false;
          } else if (this.f.username.errors) {
            this.renderer.removeClass(this.usernameField.nativeElement, 'is-invalid');
          }
        }
      );

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  ngAfterViewInit(): void {
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          this.sharedEvents.logedIn.emit(data.id);
          this.router.navigate([this.returnUrl]);
        },
        error => {
          this.error = true;
          this.loading = false;
        });
  }

  getEyeLogoState() {
    return this.passwordIsVisible ? 'eye-close-logo' : 'eye-open-logo';
  }

  eyeOnClick() {
    this.passwordField.nativeElement.type = this.passwordIsVisible ? "password" : "text";
    this.passwordIsVisible = !this.passwordIsVisible;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
