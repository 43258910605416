import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatRadioChange } from '@angular/material';
import { StatisticsGestipArlCrFlowViewModel } from '@app/_core/view-models/datawarehouse/edi-flow/view-models-from-models/statistics-gestip-arl-cr-flow-view-model';
import { FlowType } from '@app/_core/view-models/datawarehouse/edi-flow/view-models-from-views/flow-type';
import { GranularityType } from '@app/_core/view-models/datawarehouse/edi-flow/view-models-from-views/granularity-type';
import { OptionType } from '@app/_core/view-models/datawarehouse/edi-flow/view-models-from-views/option-type';
import { Period } from '@app/_core/view-models/datawarehouse/edi-flow/view-models-from-views/period';
import { DataWarehouseService } from "@app/_services/data-warehouse.service";
import { Subscription } from 'rxjs';
import { DataFormattedService } from '../../services/data-formatted.service';
import { ManageTransceiverTypeService } from '../../services/manage-transceiver-type.service';
import { ModelFormattedService } from '../../services/model-formatted.service';
import { OptionsStatEdiService } from '../../services/options-stat-edi.service';
import { PeriodByGranularityService } from '../../services/period-by-granularity.service';
import { PeriodFormattedService } from '../../services/period-formatted.service';
import { GestipSentArlCrService } from './gestip-sent-arl-cr.service';
import DWH = Models.DataWarehouse;

@Component({
  selector: 'app-gestip-sent-arl-cr',
  templateUrl: './gestip-sent-arl-cr.component.html',
  styleUrls: ['./gestip-sent-arl-cr.component.scss'],
  providers: [GestipSentArlCrService]
})

export class GestipSentArlCrComponent implements OnInit, OnDestroy {
  model: DWH.PeriodicEdiFlowsStatisticsModel[];
  subscribeModel: Subscription;
  currentVm: StatisticsGestipArlCrFlowViewModel[];

  currentPeriod: Period;
  currentPeriodDays: Period;
  currentPeriodMonths: Period;
  navigationPeriodDisabled: boolean;

  optionsChart: Highcharts.Options;
  seriesChart: any[];
  categoriesChart: any[];

  currentFlowType: FlowType;
  flowTypes: FlowType[];

  currentGranularity: GranularityType;
  granularities: GranularityType[];

  currentTransceiver: string;
  transceivers: string[];

  readonly defaultTransceiverType: string = "Tout émetteur/destinataire";

  constructor(private gestipSentArlCrService: GestipSentArlCrService,
    private periodByGranularityService: PeriodByGranularityService,
    private dataWarehouseService: DataWarehouseService,
    private manageTransceiverTypeService: ManageTransceiverTypeService,
    private dataFormattedService: DataFormattedService,
    private optionsGestipSentArlChartService: OptionsStatEdiService,
    private periodFormattedService: PeriodFormattedService,
    private modelFormattedService: ModelFormattedService) {
  }

  ngOnInit(): void {
    this.currentFlowType = FlowType.GestipSent;
    this.flowTypes = [FlowType.GestipSent, FlowType.Arl, FlowType.Cr];
    this.currentGranularity = GranularityType.Days;
    this.granularities = [GranularityType.Days, GranularityType.Months];
    this.currentTransceiver = this.defaultTransceiverType;

    this.currentPeriodDays = this.periodByGranularityService.getDefaultPeriodByGranularity(GranularityType.Days);
    this.currentPeriodMonths = this.periodByGranularityService.getDefaultPeriodByGranularity(GranularityType.Months);
    this.currentPeriod = this.currentPeriodDays;
    this.navigationPeriodDisabled = true;

    this.setData(this.currentPeriod);

    this.optionsChart = this.optionsGestipSentArlChartService.getColumnChartOptions(OptionType.GESTIP_SENT_ARL_CR, this.currentFlowType);
    this.categoriesChart = this.periodFormattedService.getDateRangeFormattedByGranularity(this.currentGranularity, this.currentPeriod);
  }

  onChangeFlowType(newFlowType: MatRadioChange) {
    this.currentFlowType = newFlowType.value;
    this.currentVm = this.gestipSentArlCrService.getViewModelByFlowType(this.currentFlowType, this.model);

    this.seriesChart = this.dataFormattedService.getSeriesForColumnChart(this.currentTransceiver, this.defaultTransceiverType, this.currentVm);
    this.optionsChart = this.optionsGestipSentArlChartService.getColumnChartOptions(OptionType.GESTIP_SENT_ARL_CR, this.currentFlowType);
  }

  onChangeGranularity(newGranularity: MatRadioChange) {
    this.currentGranularity = newGranularity.value;
    this.currentPeriod = this.periodByGranularityService.getPeriodAfterSwitchGranularity(this.currentGranularity, this.currentPeriodDays, this.currentPeriodMonths);
    this.navigationPeriodDisabled = this.periodByGranularityService.isCurrentPeriodEqualDefaultPeriod(this.currentGranularity, this.currentPeriod);

    this.setData(this.currentPeriod);

    this.categoriesChart = this.periodFormattedService.getDateRangeFormattedByGranularity(this.currentGranularity, this.currentPeriod);
  }

  onChangeTransceivers(newTransceivers: any) {
    this.currentTransceiver = newTransceivers;
    this.seriesChart = this.dataFormattedService.getSeriesForColumnChart(this.currentTransceiver, this.defaultTransceiverType, this.currentVm);
  }

  onPreviousPeriod() {
    this.setCurrentPeriod(
      this.periodByGranularityService.getPreviousPeriodByGranularity(GranularityType.Days, this.currentPeriodDays),
      this.periodByGranularityService.getPreviousPeriodByGranularity(GranularityType.Months, this.currentPeriodMonths)
    );
  }

  onNextPeriod() {
    this.setCurrentPeriod(
      this.periodByGranularityService.getNextPeriodByGranularity(GranularityType.Days, this.currentPeriodDays),
      this.periodByGranularityService.getNextPeriodByGranularity(GranularityType.Months, this.currentPeriodMonths)
    )
  }

  private setData(currentPeriod: Period): void {
    this.subscribeModel = this.dataWarehouseService.getModel(this.currentGranularity, currentPeriod)
      .subscribe(data => {
        this.model = this.modelFormattedService.getPatchPeriodicEdiFlowsStatisticsModel(data, this.currentGranularity, currentPeriod);
        this.currentVm = this.gestipSentArlCrService.getViewModelByFlowType(this.currentFlowType, this.model);
        this.transceivers = this.manageTransceiverTypeService.getTransceiverType(this.currentVm, this.defaultTransceiverType);
        if (this.transceivers.indexOf(this.currentTransceiver) < 0) {
          this.currentTransceiver = this.transceivers[0];
        }
        this.seriesChart = this.dataFormattedService.getSeriesForColumnChart(this.currentTransceiver, this.defaultTransceiverType, this.currentVm);
      });
  }

  private setCurrentPeriod(daysPeriod: Period, monthPeriod: Period) {
    if (this.currentGranularity === GranularityType.Days) {
      this.currentPeriodDays = daysPeriod;
      this.currentPeriod = this.currentPeriodDays;
    } else {
      this.currentPeriodMonths = monthPeriod;
      this.currentPeriod = this.currentPeriodMonths;
    }
    this.navigationPeriodDisabled = this.periodByGranularityService.isCurrentPeriodEqualDefaultPeriod(this.currentGranularity, this.currentPeriod);

    this.setData(this.currentPeriod);

    this.categoriesChart = this.periodFormattedService.getDateRangeFormattedByGranularity(this.currentGranularity, this.currentPeriod);
  }

  ngOnDestroy() {
    this.subscribeModel.unsubscribe();
  }
}
