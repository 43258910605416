import { Injectable, enableProdMode } from '@angular/core';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ConsoleHelper {
    constructor() {
  }

  static log(msg: any) {
    if (!environment.production) {
      console.log(msg);
    }
  }

}
