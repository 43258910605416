import { Component, OnInit, Input, OnChanges, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material';
import { MdbTableDirective } from 'angular-bootstrap-md';
import { GestipOperationsSearchResult, GestipOperationsTableViewModel, HeadElementViewData } from '@app/_core/view-models';

@Component({
  selector: 'app-gestip-operations-table',
  templateUrl: './gestip-operations-table.component.html',
  styleUrls: ['./gestip-operations-table.component.scss']
})
export class GestipOperationsTableComponent implements OnInit, OnChanges, AfterViewInit {

  public gestipOperationsTableViewModel: GestipOperationsTableViewModel;
  public pageSize = 2;
  public rowLength = 0;
  public headElements: HeadElementViewData[];
  public dataTable: Models.Edi.GestipOperationViewModel[];
  public dataResult: GestipOperationsSearchResult = null;
  public displayTechnicalData = false;
  public isHidden = true;

  @ViewChild(MdbTableDirective, { static: false }) mdbTable: MdbTableDirective;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @Output() changePageIndexEvent = new EventEmitter<number>();
  @Output() changePageSizeEvent = new EventEmitter<number>();
  @Input()
  set isTechnicalDataDisplayed(displayed: boolean) {
    this.displayTechnicalData = displayed;
  }

  constructor() {
    this.gestipOperationsTableViewModel = new GestipOperationsTableViewModel();
  }

  ngOnChanges(): void {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  public onPaginationChange(event: PageEvent) {
    if (event.pageIndex != (this.dataResult.metadata.pageNumber - 1)) {
      this.changePageIndexEvent.emit(event.pageIndex + 1);
    } else if (event.pageSize != this.dataResult.metadata.pageSize) {
      this.changePageSizeEvent.emit(event.pageSize);
    }
  }

  public setData(dataResult: GestipOperationsSearchResult, displayTechnicalData: boolean) {
    this.displayTechnicalData = displayTechnicalData;
    this.dataResult = dataResult;
    this.gestipOperationsTableViewModel.setGestipOperations(this.dataResult.payload);
    this.setDataTable();
  }

  private setDataTable() {
    this.rowLength = this.dataResult.metadata.hits;
    this.pageSize = this.dataResult.metadata.pageSize;
    this.paginator.length = this.rowLength;
    this.paginator.pageSize = this.pageSize;

    this.headElements = this.gestipOperationsTableViewModel.getHeader(this.displayTechnicalData);
    this.dataTable = this.gestipOperationsTableViewModel.dataTable;
  }
}
