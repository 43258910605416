import { Component } from '@angular/core';

@Component({
  selector: 'app-statistics-flux-edi',
  templateUrl: './statistics-flux-edi.component.html',
  styleUrls: ['./statistics-flux-edi.component.scss']
})
export class StatisticsFluxEdiComponent{

  constructor() {
  }


}
