import { Injectable } from '@angular/core';
import * as Highcharts from 'highcharts';
import { OptionType } from '@app/_core/view-models/datawarehouse/edi-flow/view-models-from-views/option-type';
import { FlowType } from '../../../_core/view-models/datawarehouse/edi-flow/view-models-from-views/flow-type';

let noData = require('highcharts/modules/no-data-to-display');
noData(Highcharts);

@Injectable({
  providedIn: 'root'
})

export class OptionsStatEdiService {
  readonly STACKING_NORMAL: Highcharts.OptionsStackingValue = "normal";
  readonly NO_STACKING: Highcharts.OptionsStackingValue = undefined;

  shadow: Highcharts.ShadowOptionsObject = {
    color: '#25282B',
    opacity: 0.015,
    offsetX: 0,
    offsetY: 0,
    width: 8
  };

  getColumnChartOptions(type: OptionType, flowType?: FlowType) {
    let optionsChart: Highcharts.Options = this.getBasicChartOptions();
    switch (type) {
      case (OptionType.GESTIP_SENT_ARL_CR):
        if (flowType == FlowType.GestipSent) {
          this.setChartOptions(this.NO_STACKING, optionsChart, type);
        }
        else {
          this.setChartOptions(this.STACKING_NORMAL, optionsChart, type);
        }
        break;
      case (OptionType.ARL_RECEIVED):
        this.setChartOptions(this.STACKING_NORMAL, optionsChart, type);
        break;
      case (OptionType.CR_RECEIVED):
      case (OptionType.BPIJ_RECEIVED):
      case (OptionType.PERIOD_ARL_RECEIVED):
      case (OptionType.PERIOD_CR_RECEIVED):
        this.setChartOptions(this.NO_STACKING, optionsChart, type);
        break;
    }
    return optionsChart;
  }

  setChartOptions(stackingType: Highcharts.OptionsStackingValue, optionsChart: Highcharts.Options, type: OptionType) {
    optionsChart.plotOptions.column.stacking = stackingType;
    if (stackingType !== this.NO_STACKING) {
      this.setChartLegend(optionsChart);
      this.setChartTooltip(stackingType, optionsChart, type);
    } else if (stackingType === this.NO_STACKING) {
      this.setChartTooltip(stackingType, optionsChart, type);
    }
  }

  setChartTooltip(stackingTypeTooltip: Highcharts.OptionsStackingValue, optionsChart: Highcharts.Options, type: OptionType) {
    let timeFormat: Boolean = (type === OptionType.PERIOD_ARL_RECEIVED || type === OptionType.PERIOD_CR_RECEIVED);
    if (stackingTypeTooltip !== this.NO_STACKING) {
      optionsChart.tooltip.headerFormat = '<b>Total: {point.total}</b><table>';
      optionsChart.tooltip.pointFormat = '<tr><td style="color:{series.color};">{series.name}: {point.y} ({point.percentage:.0f}%)</td></tr>';
      optionsChart.tooltip.footerFormat = '</table>';
    } else if (stackingTypeTooltip === this.NO_STACKING && timeFormat === false) {
      optionsChart.tooltip.pointFormat = '<span>{series.name}: {point.y}</span>'
    } else if (timeFormat) {
      optionsChart.tooltip.pointFormat = '<span>{point.name}</span>'
    }
  }

  setChartLegend(optionsChart: Highcharts.Options) {
    let legend: Highcharts.LegendOptions = {
      enabled: true,
      align: 'center',
      verticalAlign: 'top',
      floating: false,
      margin: 34,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || 'white',
      borderColor: '#CCC',
      borderWidth: 0,
      shadow: false,
      itemStyle: {
        cursor: 'default'
      },
      itemHoverStyle: {
        cursor: 'default'
      }
    }
    optionsChart.legend = legend;
  }

  getBasicChartOptions(): Highcharts.Options {
    let options: Highcharts.Options = {
      chart: {
        style: {
          fontFamily: 'Roboto'
        },
        animation: false
      },
      credits: { // pour enlever le highcharts.com
        enabled: false
      },
      title: {
        text: null
      },
      xAxis: {
        showEmpty: true,
        labels: {
          rotation: -70
        },
        categories: [] // attention : toujours vide
      },
      yAxis: {
        minRange: 0.5,
        min: 0,
        title: {
          text: null
        }
      },
      legend: {
        "enabled": false
      },
      tooltip: {
        headerFormat: '',
        pointFormat: '<span>{point.y}</span>',
        shared: true,
        useHTML: true,
        borderRadius: 1,
        borderWidth: 0,
        shadow: this.shadow,
        backgroundColor: 'white',
        style: {
          textAlign: 'center',
          fontWeight: 'bold'
        }
      },
      lang: {
        noData: "Pas de données sur cette période"
      },
      plotOptions: {
        column: {
          stacking: undefined,
          pointWidth: 10
        },
        series: {
          states: {
            inactive: {
              enabled: false
            }
          },
          events: {
            legendItemClick: function () {
              return false;
            }
          }
        }
      },
      series: []  // attention : toujours vide
    }
    return options;
  }
}
