import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, ChangeDetectorRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { BaseChartDirective, MdbTableDirective, MdbTablePaginationComponent } from 'angular-bootstrap-md';
import { FluxEdiService } from '../../../_services';
import { Router, ActivatedRoute, RouterEvent, NavigationEnd } from '@angular/router';
import { merge, of as observableOf, Subject } from 'rxjs';
import { startWith, switchMap, map, catchError, filter, takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import Edi = Models.Edi;
import HeadElement = Models.HeadElement;
import * as Chart from 'chart.js';

@Component({
  selector: 'app-transit',
  templateUrl: './transit.component.html',
  styleUrls: ['./transit.component.scss']
})
/** transit component*/
export class TransitComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MdbTableDirective, { static: false }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: false }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild("tableFlux", { static: false }) tableFlux: ElementRef;
  @ViewChild("GraphFlux", { static: false }) GraphFlux: ElementRef;
  @ViewChild(BaseChartDirective, { static: false }) private _chart;

  nbAllTransit = 0;

  public pipe = new DatePipe('fr-FR'); // Use your own locale
  @Output() dataNbTransitChange = new EventEmitter<number>();

  filteredDataTable = new Array<Edi.EdiFileInfo>();
  completeDataTable = new Array<Edi.EdiFileInfo>();
  dataGestip = new Array<Edi.EdiFileInfo>();
  dataArl = new Array<Edi.EdiFileInfo>();
  dataCr = new Array<Edi.EdiFileInfo>();
  dataBpij = new Array<Edi.EdiFileInfo>();
  gestip = "GESTIP";
  arl = "ARL";
  cr = "CR";
  bpij = "BPIJ";
  sortingElement = '';
  toggle = false;

  previousTableItems = new Array<Edi.EdiFileInfo>();
  maxVisibleItems = 10;

  tableHide = true;
  helpHide = false;
  tableDisplayed = false;
  tableGestipDisplayed = false;
  tableArlDisplayed = false;
  tableCrDisplayed = false;
  tableBpijDisplayed = false;
  cardGestipSelected = false;
  cardArlSelected = false;
  cardCrSelected = false;
  cardBpijSelected = false;
  nbGestip = 0;
  nbArl = 0;
  nbCr = 0;
  nbBpij = 0;

  headElements: HeadElement[];
  headElemId: string;

  filterStatusElem = -1;
  filterPartnerElem = 'Tous';
  hasfilterPartner = false;
  hasfilterState = false;

  public chartType = 'bar';
  public chartDatasetsGestip: Array<any> = [
    { data: [0, 0, 0, 0, 0, 0, 0], label: '' }
  ];
  public chartDatasetsArl: Array<any> = [
    { data: [0, 0, 0, 0, 0, 0, 0], label: '' }
  ];
  public chartDatasetsCr: Array<any> = [
    { data: [0, 0, 0, 0, 0, 0, 0], label: '' }
  ];
  public chartDatasetsBpij: Array<any> = [
    { data: [0, 0, 0, 0, 0, 0, 0], label: '' }
  ];
  public chartLabels: Array<any> = ['...', 'J-5', 'J-4', 'J-3', 'J-2', 'J-1', 'Auj'];

  public chartColorsOrange: Array<any> = [
    {
      backgroundColor: 'rgba(232, 152, 6, 1)',
      borderColor: 'rgba(232, 152, 6, 1)',
      borderWidth: 0,
    }
  ];
  public chartColorsGrey: Array<any> = [
    {
      backgroundColor: 'rgba(232, 232, 232, 1)',
      borderColor: 'rgba(232, 232, 232, 1)',
      borderWidth: 0,
    }
  ];
  public chartColorsGestip: any;
  public chartColorsArl: any;
  public chartColorsCr: any;
  public chartColorsBpij: any;
  public chartLabelsColor = 'rgba(232, 232, 232, 1)';
  public maxFiles = 10;

  public custom: any = this.getPositionChartTooltip();

  public chartOptions: any = {
    responsive: true,
    scales: {
      yAxes: [{
        display: false,
        gridLines: {
          display: false
        },
        ticks: {
          beginAtZero: true,
          //max: this.maxFiles
        }
      }],
      xAxes: [{
        gridLines: {
          display: false
        },
        ticks: {
          fontColor: 'black', // couleur des jours
        },
      }]
    },
    tooltips: {
      position: 'custom',
      enabled: true,
      caretSize: 0,
      custom: function (tooltip) {
        if (!tooltip) return;
        // disable displaying the color box;
        tooltip.displayColors = false;
        tooltip.xPadding = 13;
      },
      callbacks: {
        // use label callback to return the desired label
        label: function (tooltipItem) {
          return tooltipItem.yLabel;
        },
        // remove title
        title: function (tooltipItem) {
          return;
        }
      }
    },
    hover: { mode: null },
  };

  public destroyed = new Subject<any>();

  sectionParam = '';
  filterParam: any;

  /** transit ctor */
  constructor(public fluxediService: FluxEdiService, private router: Router, private route: ActivatedRoute, private cdRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    const queryModule = this.route.snapshot.params['module'];
    const nbSaved = this.nbAllTransit;
    // Query params
    if (queryModule === 'transit-prestij') {
      if (this.route.snapshot.params['section']) {
        this.sectionParam = (this.route.snapshot.params['section'] as string).toUpperCase();
        this.filterParam = JSON.parse(this.route.snapshot.params['filters']);
      }
    }

    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd), takeUntil(this.destroyed)
    ).subscribe(() => {
      this.loadAllData();
      this.nbAllTransit = nbSaved;
    });

    this.chartLabels = ['...', 'J-5', 'J-4', 'J-3', 'J-2', 'J-1', 'Auj'];

    if (queryModule === 'transit-prestij') {
      this.chartColorsGestip = this.chartColorsArl = this.chartColorsCr = this.chartColorsBpij = this.chartColorsGrey;
    } else {
      this.chartColorsGestip = this.chartColorsArl = this.chartColorsCr = this.chartColorsBpij = this.chartColorsOrange;
    }
  }

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.maxVisibleItems);
    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.mdbTablePagination.ofKeyword = "sur";
    this.cdRef.detectChanges();

    this.loadAllData();
  }

  onSelectFlux(id: string) {
    this.router.navigate(['/edi/' + id]);
  }

  onGestipClick() {
    this.toggle = false;
    this.sortingElement = '';
    this.hasfilterState = false;
    this.cardGestipSelected = !this.cardGestipSelected;
    if (this.cardArlSelected || this.cardCrSelected || this.cardBpijSelected) {
      this.cardArlSelected = this.cardCrSelected = this.cardBpijSelected = false;
    }
    this.helpHide = true;
    this.tableFlux.nativeElement.style.marginTop = "25px";
    if (!this.tableDisplayed) {
      this.tableGestipDisplayed = true;
      this.tableDisplayed = true;
      this.setDataTable(this.gestip);
      this.tableHide = false;
    }
    else {
      if (this.tableGestipDisplayed) {
        this.tableGestipDisplayed = false;
        this.tableDisplayed = false;
        this.tableHide = true;
        this.helpHide = false;
      }
      else {
        this.tableGestipDisplayed = true;
        this.tableDisplayed = true;
        this.tableArlDisplayed = false;
        this.tableCrDisplayed = false;
        this.tableBpijDisplayed = false;
        this.setDataTable(this.gestip);
        this.tableHide = false;
      }
    }
    if (this.tableDisplayed) {
      this.chartColorsGestip = this.chartColorsArl = this.chartColorsCr = this.chartColorsBpij = this.chartColorsGrey;
    } else {
      this.chartColorsGestip = this.chartColorsArl = this.chartColorsCr = this.chartColorsBpij = this.chartColorsOrange;
    }
    this.mdbTablePagination.firstPage();
  }

  onArlClick() {
    this.toggle = false;
    this.sortingElement = '';
    this.cardArlSelected = !this.cardArlSelected;
    if (this.cardGestipSelected || this.cardCrSelected || this.cardBpijSelected) {
      this.cardGestipSelected = this.cardCrSelected = this.cardBpijSelected = false;
    }
    this.helpHide = true;
    this.tableFlux.nativeElement.style.marginTop = "25px";
    if (!this.tableDisplayed) {
      this.tableArlDisplayed = true;
      this.tableDisplayed = true;
      this.setDataTable(this.arl);
      this.tableHide = false;
    }
    else {
      if (this.tableArlDisplayed) {
        this.tableArlDisplayed = false;
        this.tableDisplayed = false;
        this.tableHide = true;
        this.helpHide = false;
      }
      else {
        this.tableArlDisplayed = true;
        this.tableDisplayed = true;
        this.tableGestipDisplayed = false;
        this.tableCrDisplayed = false;
        this.tableBpijDisplayed = false;

        this.setDataTable(this.arl);
        this.tableHide = false;
      }
    }
    if (this.tableDisplayed) {
      this.chartColorsGestip = this.chartColorsArl = this.chartColorsCr = this.chartColorsBpij = this.chartColorsGrey;
    } else {
      this.chartColorsGestip = this.chartColorsArl = this.chartColorsCr = this.chartColorsBpij = this.chartColorsOrange;
    }
    this.mdbTablePagination.firstPage();
  }

  onCrClick() {
    this.toggle = false;
    this.sortingElement = '';
    this.cardCrSelected = !this.cardCrSelected;
    if (this.cardGestipSelected || this.cardArlSelected || this.cardBpijSelected) {
      this.cardGestipSelected = this.cardArlSelected = this.cardBpijSelected = false;
    }
    this.helpHide = true;
    this.tableFlux.nativeElement.style.marginTop = "25px";
    if (!this.tableDisplayed) {
      this.tableCrDisplayed = true;
      this.tableDisplayed = true;
      this.setDataTable(this.cr);
      this.tableHide = false;
    }
    else {
      if (this.tableCrDisplayed) {
        this.tableCrDisplayed = false;
        this.tableDisplayed = false;
        this.tableHide = true;
        this.helpHide = false;
      }
      else {
        this.tableCrDisplayed = true;
        this.tableDisplayed = true;
        this.tableGestipDisplayed = false;
        this.tableArlDisplayed = false;
        this.tableBpijDisplayed = false;

        this.setDataTable(this.cr);
        this.tableHide = false;
      }
    }
    if (this.tableDisplayed) {
      this.chartColorsGestip = this.chartColorsArl = this.chartColorsCr = this.chartColorsBpij = this.chartColorsGrey;
    } else {
      this.chartColorsGestip = this.chartColorsArl = this.chartColorsCr = this.chartColorsBpij = this.chartColorsOrange;
    }
    this.mdbTablePagination.firstPage();
  }

  onBpijClick() {
    this.toggle = false;
    this.sortingElement = '';
    this.cardBpijSelected = !this.cardBpijSelected;
    if (this.cardGestipSelected || this.cardArlSelected || this.cardCrSelected) {
      this.cardGestipSelected = this.cardArlSelected = this.cardCrSelected = false;
    }
    this.helpHide = true;
    this.tableFlux.nativeElement.style.marginTop = "25px";
    if (!this.tableDisplayed) {
      this.tableBpijDisplayed = true;
      this.tableDisplayed = true;
      this.setDataTable(this.bpij);
      this.tableHide = false;
    }
    else {
      if (this.tableBpijDisplayed) {
        this.tableBpijDisplayed = false;
        this.tableDisplayed = false;
        this.tableHide = true;
        this.helpHide = false;
      }
      else {
        this.tableBpijDisplayed = true;
        this.tableDisplayed = true;
        this.tableGestipDisplayed = false;
        this.tableArlDisplayed = false;
        this.tableCrDisplayed = false;

        this.setDataTable(this.bpij);
        this.tableHide = false;
      }
    }
    if (this.tableDisplayed) {
      this.chartColorsGestip = this.chartColorsArl = this.chartColorsCr = this.chartColorsBpij = this.chartColorsGrey;
    } else {
      this.chartColorsGestip = this.chartColorsArl = this.chartColorsCr = this.chartColorsBpij = this.chartColorsOrange;
    }
    this.mdbTablePagination.firstPage();
  }

  disabledOff(data: any) {
    data.forEach(element => {
      if (element.default === true) {
        element.default = false;
      }
    });
  }

  newDisabledOn(data: any, indexFilter: number) {
    data[indexFilter].default = true;
  }

  findFilterIndex(headIndex: number, filterId: number) {
    const filterIndex = this.headElements[headIndex].dataFilter.findIndex(item => item.id === filterId);
    return filterIndex;
  }

  findHeadIndex(elemId: string) {
    const headIndex = this.headElements.findIndex(item => item.id === elemId);
    return headIndex;
  }

  onFilterClick(filterId: number, filterValue: string, elemId: string, indexFilter: number) {
    this.applyFilter(filterId, filterValue, elemId, indexFilter);
    this.mdbTablePagination.firstPage();
  }

  applyFilterWithQueries(filter: number, elemId: string) {
    const headIndex = this.findHeadIndex(elemId);
    const filterIndex = this.findFilterIndex(headIndex, filter);
    if ((filter === 1 && this.dataGestip.find(ediFileInfo => ediFileInfo.stateLevel === 1)) || (filter === 2 && this.dataGestip.find(ediFileInfo => ediFileInfo.stateLevel === 2))) {
      this.applyFilter(filter, this.getStateTextFromStateLevel(filter), elemId, filterIndex);
    }
  }

  applyFilter(filterId: number, filterValue: string, elemId: string, indexFilter: number) {
    this.filteredDataTable = this.completeDataTable;

    switch (elemId) {
      case 'partner': {
        this.filterPartnerElem = filterValue;
        this.disabledOff(this.headElements[this.findHeadIndex(elemId)].dataFilter);
        this.newDisabledOn(this.headElements[this.findHeadIndex(elemId)].dataFilter, indexFilter);
        this.hasfilterPartner = true;
        if (filterValue === 'Tous') {
          this.hasfilterPartner = false;
        }
        break;
      }
      case 'stateLevel': {
        this.filterStatusElem = filterId;
        this.disabledOff(this.headElements[this.findHeadIndex(elemId)].dataFilter);
        this.newDisabledOn(this.headElements[this.findHeadIndex(elemId)].dataFilter, indexFilter);
        this.hasfilterState = true;
        if (filterValue === 'Tous') {
          this.hasfilterState = false;
        }
        break;
      }
    }

    if (this.filterPartnerElem !== 'Tous') {
      this.filteredDataTable = this.filteredDataTable.filter(item => item.partner == this.filterPartnerElem);
    }
    if (this.filterStatusElem !== -1) {
      this.filteredDataTable = this.filteredDataTable.filter(item => item.stateLevel == this.filterStatusElem);
    }

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
  }

  getFilterClass(elemId: string) {
    if (elemId === "partner") {
      if (this.hasfilterPartner) {
        return "filtering";
      } else {
        return "filter";
      }
    }
    if (elemId === "stateLevel") {
      if (this.hasfilterState) {
        return "filtering";
      } else {
        return "filter";
      }
    }
  }
  getStatutClassColor(stateLevel: Edi.StateLevel) {
    if (stateLevel === Edi.StateLevel.warning) {
      return 'text-warning';
    }
    if (stateLevel === Edi.StateLevel.error) {
      return 'text-error';
    }
    if (stateLevel === Edi.StateLevel.undefined) {
      return '';
    }
  }

  getPaginationDisabled() {
    if (this.filteredDataTable.length <= 10) {
      return "pagination-disabled";
    }
  }

  getPositionChartTooltip() {
    Chart.Tooltip.positioners.custom = function (elements, eventPosition) {
      /** @type {Chart.Tooltip} */
      var tooltip = this;

      /* ... */

      return {
        x: eventPosition.x,
        y: eventPosition.y
      };
    }
  }

  setDataTable(ediType: string) {
    switch (ediType) {
      case this.gestip:
        {
          this.headElements = [
            { id: 'fileName', head: 'Nom fichier ZIP' },
            { id: 'partner', head: 'Destinataire', dataFilter: [], filter: true },
            { id: 'receiptDate', head: 'Date réception GESTIP', sortable: true, toggle: false },
            { id: 'stateLevel', head: 'Statut', dataFilter: [], filter: true }
          ];
          this.mdbTable.setDataSource(this.dataGestip);
          this.completeDataTable = this.mdbTable.getDataSource();
          this.previousTableItems = this.mdbTable.getDataSource();
          this.loadStateFilter(this.completeDataTable);
          break;
        }
      case this.arl:
        {
          this.headElements = [
            { id: 'fileName', head: 'Nom fichier ZIP' },
            { id: 'partner', head: 'Emetteur', dataFilter: [], filter: true },
            { id: 'receiptDate', head: 'Date réception ARL', sortable: true, toggle: false }
          ];
          this.mdbTable.setDataSource(this.dataArl);
          this.completeDataTable = this.mdbTable.getDataSource();
          this.previousTableItems = this.mdbTable.getDataSource();
          break;
        }
      case this.cr:
        {
          this.headElements = [
            { id: 'fileName', head: 'Nom fichier ZIP' },
            { id: 'partner', head: 'Emetteur', dataFilter: [], filter: true },
            { id: 'receiptDate', head: 'Date réception CR', sortable: true, toggle: false }
          ];
          this.mdbTable.setDataSource(this.dataCr);
          this.completeDataTable = this.mdbTable.getDataSource();
          this.previousTableItems = this.mdbTable.getDataSource();
          break;
        }
      case this.bpij:
        {
          this.headElements = [
            { id: 'fileName', head: 'Nom fichier ZIP' },
            { id: 'partner', head: 'Emetteur', dataFilter: [], filter: true },
            { id: 'receiptDate', head: 'Date réception BPIJ', sortable: true, toggle: false }
          ];
          this.mdbTable.setDataSource(this.dataBpij);
          this.completeDataTable = this.mdbTable.getDataSource();
          this.previousTableItems = this.mdbTable.getDataSource();
          break;
        }
    }

    this.filteredDataTable = this.completeDataTable;
    this.loadPartnerFilter(this.completeDataTable);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
  }

  loadAllData() {
    this.loadDataTableArl();
    this.loadDataTableBpij();
    this.loadDataTableCr();
    this.loadDataTableGestip();
  }

  loadPartnerFilter(dataTable: Edi.EdiFileInfo[]) {
    const datas = [];
    dataTable.forEach(item => {
      if (!datas.includes(item.partner)) {
        datas.push({ id: item.partner, value: item.partner, default: false });
      }
    });

    //création de l'array filtre final SANS doublons
    var data = Array.from(new Set(datas.map(a => a.id)))
      .map(id => {
        return datas.find(a => a.id === id);
      });
    data.push({ id: 'Tous', value: 'Tous', default: true });
    var index = this.headElements.findIndex(item => item.id === "partner");
    this.headElements[index].dataFilter = data;
  }

  loadStateFilter(dataTable: any) {
    const datas = [];
    dataTable.forEach((item: Edi.EdiFileInfo) => {
      if (!datas.includes(item.stateLevel)) {
        const text = this.getStateTextFromStateLevel(item.stateLevel);
        const color = this.getStatutClassColor(item.stateLevel);
        datas.push({ id: item.stateLevel, value: text, color: color, default: false });
      }
    });

    //création de l'array filtre final SANS doublons
    var data = Array.from(new Set(datas.map(a => a.id)))
      .map(id => {
        return datas.find(a => a.id === id);
      });
    data.push({ id: -1, value: 'Tous', color: this.getStatutClassColor(Edi.StateLevel.undefined), default: true });
    var index = this.headElements.findIndex(item => item.id === "stateLevel");
    this.headElements[index].dataFilter = data;
  }

  public getStateTextFromStateLevel(stateLevel: Edi.StateLevel): string {
    let text = "En attente de transfert";
    switch (stateLevel) {
      case Edi.StateLevel.error:
        text = "Erreur Prest'iPrev";
        break;
      case Edi.StateLevel.warning:
        text = "En attente d'émission";
        break;
      default:
        text = "En attente d'émission";
    }
    return text;
  }

  loadDataTableGestip() {
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.fluxediService.getAllGestipInBal();
        }),
        map(data => {
          return data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(data => {
        this.nbGestip = data.length;
        this.nbAllTransit += this.nbGestip;
        this.dataGestip = data;
        this.dataNbTransitChange.emit(this.nbAllTransit);
        this.initDataTableChart(this.gestip);

        (<any>this._chart).refresh();

        const currentHigh = this.chartOptions.scales.yAxes[0].ticks.max;
        if (currentHigh <= this.nbGestip) {
          this.chartOptions.scales.yAxes[0].ticks.max = this.nbGestip * 3 / 2;
          (<any>this._chart).refresh();
        }

        if (this.tableGestipDisplayed) {
          this.setDataTable(this.gestip);
        }

        if (this.sectionParam === 'GESTIP') {
          this.onGestipClick();
        }
        if (this.filterParam && this.tableGestipDisplayed) {
          this.applyFilterWithQueries(this.filterParam.statut, "stateLevel");
        }
      });
  }

  loadDataTableArl() {
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.fluxediService.getAllArlInBal();
        }),
        map(data => {
          return data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(data => {
        this.nbArl = data.length;
        this.nbAllTransit += this.nbArl;
        this.dataArl = data;
        this.dataNbTransitChange.emit(this.nbAllTransit);
        this.initDataTableChart(this.arl);

        (<any>this._chart).refresh();

        const currentHigh = this.chartOptions.scales.yAxes[0].ticks.max;
        if (currentHigh <= this.nbArl) {
          this.chartOptions.scales.yAxes[0].ticks.max = this.nbArl * 3 / 2;
          (<any>this._chart).refresh();
        }

        if (this.tableArlDisplayed) {
          this.setDataTable(this.arl);
        }

        if (this.sectionParam === 'ARL') {
          this.onArlClick();
        }
      });
  }

  loadDataTableCr() {
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.fluxediService.getAllCrInBal();
        }),
        map(data => {
          return data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(data => {
        this.nbCr = data.length;
        this.nbAllTransit += this.nbCr;
        this.dataCr = data;
        this.dataNbTransitChange.emit(this.nbAllTransit);
        this.initDataTableChart(this.cr);

        (<any>this._chart).refresh();

        const currentHigh = this.chartOptions.scales.yAxes[0].ticks.max;
        if (currentHigh <= this.nbCr) {
          this.chartOptions.scales.yAxes[0].ticks.max = this.nbCr * 3 / 2;
          (<any>this._chart).refresh();
        }

        if (this.tableCrDisplayed) {
          this.setDataTable(this.cr);
        }

        if (this.sectionParam === 'CR') {
          this.onCrClick();
        }
      });
  }

  loadDataTableBpij() {
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.fluxediService.getAllBpijInBal();
        }),
        map(data => {
          return data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(data => {
        this.nbBpij = data.length;
        this.nbAllTransit += this.nbBpij;
        this.dataBpij = data;
        this.dataNbTransitChange.emit(this.nbAllTransit);
        this.initDataTableChart(this.bpij);

        (<any>this._chart).refresh();

        const currentHigh = this.chartOptions.scales.yAxes[0].ticks.max;
        if (currentHigh <= this.nbBpij) {
          this.chartOptions.scales.yAxes[0].ticks.max = this.nbBpij * 3 / 2;
          (<any>this._chart).refresh();
        }

        if (this.tableBpijDisplayed) {
          this.setDataTable(this.bpij);
        }

        if (this.sectionParam === 'BPIJ') {
          this.onBpijClick();
        }
      });
  }

  initDataTableChart(dataType: string) {
    var chartTable;

    switch (dataType) {
      case this.gestip:
        chartTable = this.getChartTableFromData(this.dataGestip);
        this.chartDatasetsGestip = [{
          data: chartTable,
          label: '',
          borderWidth: 5
        }];
        break;
      case this.arl:
        chartTable = this.getChartTableFromData(this.dataArl);
        this.chartDatasetsArl = [{
          data: chartTable,
          label: '',
          borderWidth: 5
        }];
        break;
      case this.cr:
        chartTable = this.getChartTableFromData(this.dataCr);
        this.chartDatasetsCr = [{
          data: chartTable,
          label: '',
          borderWidth: 5
        }];
        break;
      case this.bpij:
        chartTable = this.getChartTableFromData(this.dataBpij);
        this.chartDatasetsBpij = [{
          data: chartTable,
          label: '',
          borderWidth: 5
        }];
        break;
      default:
        return;
    }
  }

  getChartTableFromData(data: Edi.EdiFileInfo[]): any[] {
    let mon = 0, tue = 0, wed = 0, thu = 0, thr = 0, sat = 0, sun = 0, older = 0;

    const lastWeekDate = new Date();
    const threeMonthBefore = new Date();
    lastWeekDate.setHours(0, 0, 0);
    lastWeekDate.setDate(lastWeekDate.getDate() - 6);
    threeMonthBefore.setHours(0, 0, 0);
    threeMonthBefore.setDate(threeMonthBefore.getDate() - 120);

    data.forEach(item => {
      const receiptDate = new Date(item.receiptDate);
      receiptDate.setHours(0, 0, 0, 0);
     
      if (receiptDate >= lastWeekDate) {
        const dayOfWeek = receiptDate.getDay();
        switch (dayOfWeek) {
          case 0: { sun++; break; }
          case 1: { mon++; break; }
          case 2: { tue++; break; }
          case 3: { wed++; break; }
          case 4: { thu++; break; }
          case 5: { thr++; break; }
          case 6: { sat++; break; }
        }
      } else {
        if (receiptDate >= threeMonthBefore) {
          older++;
        }
      }
    });

    let chartTable = [];
    const dayOfWeek = new Date().getDay();
    switch (dayOfWeek) {
      case 0: { chartTable = [older, tue, wed, thu, thr, sat, sun]; break; }
      case 1: { chartTable = [older, wed, thu, thr, sat, sun, mon]; break; }
      case 2: { chartTable = [older, thu, thr, sat, sun, mon, tue]; break; }
      case 3: { chartTable = [older, thr, sat, sun, mon, tue, wed]; break; }
      case 4: { chartTable = [older, sat, sun, mon, tue, wed, thu]; break; }
      case 5: { chartTable = [older, sun, mon, tue, wed, thu, thr]; break; }
      case 6: { chartTable = [older, mon, tue, wed, thu, thr, sat]; break; }
    }

    return chartTable;
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
