import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import PrestiPrev = Models.PrestiPrev;
import { catchError } from 'rxjs/operators';

@Injectable()
export class NotificationService {
  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
  }

  getUserNotifications(userId: string): Observable<PrestiPrev.UserNotificationViewModel[]> {
    //Appel du .NetCore API Controller local
    const apiUrl = 'api/notification/GetUserNotifications';
    return this.http.get<PrestiPrev.UserNotificationViewModel[]>(this.baseUrl + apiUrl.concat('?userId=' + userId));
  }

  getLatestUserNotifications(userId: string, pastSeconds: number): Observable<PrestiPrev.UserNotificationViewModel[]> {
    //Appel du .NetCore API Controller local
    const apiUrl = 'api/notification/GetLatestUserNotifications';
    return this.http.get<PrestiPrev.UserNotificationViewModel[]>(this.baseUrl + apiUrl.concat('?userId=' + userId + "&pastSeconds=" + pastSeconds));
  }

  userHasNewNotifications(userId: string): Observable<boolean> {
    //Appel du .NetCore API Controller local
    const apiUrl = 'api/notification/UserHasNewNotifications';
    return this.http.get<boolean>(this.baseUrl + apiUrl.concat('?userId=' + userId));
  }

  unmarkPushNotificationsAsNew(userId: string): Observable<boolean> {
    //Appel du .NetCore API Controller local
    const apiUrl = 'api/notification/user/' + userId+'/unmark-as-new';

    return this.http.post<boolean>(this.baseUrl + apiUrl, null);
  }

  setUserNotificationsRead(userNotificationIds: string[]): Observable<boolean> {
    //Appel du .NetCore API Controller EDI local
    const apiUrl = 'api/notification/SetUserNotificationsRead';
    const body = JSON.stringify(userNotificationIds);
    const headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<boolean>(this.baseUrl + apiUrl, body, { headers: headerOptions });
  }

  setUserNotificationsUnread(userNotificationIds: string[]): Observable<boolean> {
    //Appel du .NetCore API Controller EDI local
    const apiUrl = 'api/notification/SetUserNotificationsUnread';
    const body = JSON.stringify(userNotificationIds);
    const headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<boolean>(this.baseUrl + apiUrl, body, { headers: headerOptions });
  }

  deleteUserNotifications(userNotificationIds: string[]): Observable<boolean> {
    //Appel du .NetCore API Controller EDI local
    const apiUrl = 'api/notification/DeleteUserNotifications';
    const body = JSON.stringify(userNotificationIds);
    const headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<boolean>(this.baseUrl + apiUrl, body, { headers: headerOptions });
  }

  /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      //console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      //this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
