import { Injectable } from "@angular/core";
import DWH = Models.DataWarehouse;
import { StatisticsBpijReceivedFlowViewModel } from "../../../../_core/view-models/datawarehouse/edi-flow/view-models-from-models/statistics-bpij-received-flow-view-model";

@Injectable({
  providedIn: 'root'
})

export class BpijReceivedService {
  getViewModel(model: DWH.PeriodicEdiFlowsStatisticsModel[]): StatisticsBpijReceivedFlowViewModel[] {
    let vm: StatisticsBpijReceivedFlowViewModel[];

    vm = model.map(m => new StatisticsBpijReceivedFlowViewModel(m));

    return vm;
  }
}
