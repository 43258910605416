import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef, OnDestroy, AfterViewChecked } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { MdbTableDirective, MdbTablePaginationComponent } from 'angular-bootstrap-md';
import { FluxEdiService } from '../../_services';
import { merge, of as observableOf, Subject } from 'rxjs';
import { startWith, switchMap, map, catchError, filter, takeUntil } from 'rxjs/operators';
import HeadElement = Models.HeadElement;
import Edi = Models.Edi;
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { Moment } from 'moment';
import * as moment from 'moment';
import { CalendarService } from '@app/_layers/calendar/calendar.service';
import { CalendarComponent } from '@app/_layers/calendar/calendar.component';

@Component({
  selector: 'app-tracabilite-prestij',
  templateUrl: './tracabilite-prestij.component.html',
  styleUrls: ['./tracabilite-prestij.component.scss'],
  providers: [CalendarService]
})
/** tracabilite-prestij component*/
export class TracabilitePrestijComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  @ViewChild(MdbTableDirective, { static: false }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: false }) mdbTablePagination: MdbTablePaginationComponent;

  @ViewChild("fromCalendar", { static: false })
  fromCalendar: CalendarComponent;

  @ViewChild("toCalendar", { static: false })
  toCalendar: CalendarComponent;

  private TODAY: Moment = CalendarComponent.TODAY;

  modalContent: Edi.FluxEdiGestipViewModel;
  dataGestip: Edi.FluxEdiGestipViewModel[] = [];
  dataBpij: Edi.FluxEdiBpijViewModel[] = [];
  completeDataTableGestip = new Array<Edi.FluxEdiGestipViewModel>();
  filteredDataTableGestip = new Array<Edi.FluxEdiGestipViewModel>();
  modalData: Edi.FluxEdiGestipViewModel;
  completeDataTablebpij = new Array<Edi.FluxEdiBpijViewModel>();
  filteredDataTableBpij = new Array<Edi.FluxEdiBpijViewModel>();
  headElements = new Array<HeadElement>();

  filterStatutArlElem = 'Tous';
  filterDestinataireElem = 'Tous';
  filterEmetteurElem = 'Tous';
  filterStatusTransfertElem = 'Tous';
  sortingElement = '';
  dateFormater = 'dd/MM/yyyy HH:mm';
  errFrom: string = "";
  errTo: string = "";

  previousTableItems = [];
  maxVisibleItems = 10;

  fluxGestip = true;
  fluxBpij = false;
  tableGestipHide = false;
  tableBpijHide = false;
  hasfilterDestinataire = false;
  hasfilterStatusArl = false;
  hasfilterEmetteur = false;
  hasfilterStatusTransfer = false;
  canRefresh = false;
  btnDateFromUpDisabled = true;
  btnDateFromDownDisabled = false;
  btnDateToUpDisabled = true;
  btnDateToDownDisabled = true;
  toggle = false;
  loaderDisplayed = false;

  destroyed = new Subject<any>();

  constructor(
    public fluxediService: FluxEdiService, private router: Router, private cdRef: ChangeDetectorRef, private modalService: NgbModal, public datepipe: DatePipe,
    public dateRange: CalendarService) {
  }

  ngOnInit() {
    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd), takeUntil(this.destroyed)
    ).subscribe(() => {
      this.fluxGestip ? this.loadDataTableGestip(this.fromCalendar.date, this.toCalendar.date) : this.loadDataTableBpij(this.fromCalendar.date, this.toCalendar.date);
    });
  }

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.maxVisibleItems);
    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();

    this.dateRange.setCalendars(this.fromCalendar, this.toCalendar);
    this.fromCalendar.date = moment(this.TODAY).add(-1, "days");
    this.toCalendar.date = moment(this.TODAY);
    this.loadDataTableGestip(this.fromCalendar.date, this.toCalendar.date);

    this.fromCalendar.dateChange.pipe(takeUntil(this.destroyed)).subscribe(this.loadData.bind(this));
    this.toCalendar.dateChange.pipe(takeUntil(this.destroyed)).subscribe(this.loadData.bind(this));
  }
  ngAfterViewChecked() {
    if (!this.fromCalendar)
      return;
    this.errFrom = this.fromCalendar.error;
    this.errTo = this.toCalendar.error;

    this.cdRef.detectChanges();
  }

  buttonPressed(pressed: boolean) {
    if (pressed)
      this.hasfilterDestinataire = this.hasfilterEmetteur = this.hasfilterStatusArl = this.hasfilterStatusTransfer = false;
  }

  onFluxGestipClick() {
    if (this.fluxGestip === false) {
      this.fluxGestip = true;
      this.fluxBpij = this.toggle = false;
      this.errFrom = this.errTo = "";
      this.sortingElement = '';
      this.fromCalendar.date = moment(this.TODAY).add(-1, "days");
      this.loadDataTableGestip(this.fromCalendar.date, this.TODAY);
    }
  }

  onFluxBpijClick() {
    if (this.fluxBpij === false) {
      this.fluxBpij = true;
      this.fluxGestip = this.toggle = false;
      this.errFrom = this.errTo = "";
      this.sortingElement = '';
      this.dateRange.setCalendars(this.fromCalendar, this.toCalendar);
      this.fromCalendar.date = moment(this.TODAY).add(-1, "days");
      this.toCalendar.date = moment(this.TODAY);
      this.loadDataTableBpij(this.fromCalendar.date, this.TODAY);
    }
  }

  onFilterGestipClick(filter: string, elem: string, indexFilter: number) {
    this.applyFilterGestip(filter, elem, indexFilter);
    this.mdbTablePagination.firstPage();
  }

  onFilterBpijClick(filter: string, elem: string, indexFilter: number) {
    this.applyFilterBpij(filter, elem, indexFilter);
    this.mdbTablePagination.firstPage();
  }

  onModalOpenClick(targetModal, data, fluxEdiId) {
    this.modalData = fluxEdiId;
    this.modalContent = data;
    this.modalService.open(targetModal,
      {
        centered: true,
        size: 'traca-details-md',
      });
  }

  getColor(elemId: string, data: string, index: number) {
    return elemId === 'statutArl' ? (data === 'A' ? '#2AC769' : '#FB4E4E') : 'black';
  }

  getCftStatutClass(status: Edi.StatutFluxEdi): string {
    return status === Edi.StatutFluxEdi.enErreurTransfert ? 'text-error' : 'text-valid';
  }

  getFilterCftStatutClassColor(data: string) {
    return data === 'OK' ? 'text-valid' : (data === 'KO' ? 'text-error' : '');
  }

  getArlStatutClass(diagnostic: Edi.Diagnostic): string {
    return diagnostic === null ? '' : (diagnostic.etat === 'A' ? 'text-valid' : 'text-error');
  }

  getArlStatutText(diagnostic: Edi.Diagnostic): string {
    return diagnostic === null ? 'Non reçu' : (diagnostic.etat === 'A' ? 'Accepté' : 'Refusé');
  }

  getFilterClass(elemId: string) {
    if (elemId === 'destinataire') {
      return this.hasfilterDestinataire ? 'filtering' : 'filter';
    } else if (elemId === 'statutArl') {
      return this.hasfilterStatusArl ? 'filtering' : 'filter';
    } else if (elemId === 'statutTransferCftOk') {
      return this.hasfilterStatusTransfer ? 'filtering' : 'filter';
    } else if (elemId === 'emetteur') {
      return this.hasfilterEmetteur ? 'filtering' : 'filter';
    } else {
      return 'filter';
    }
  }

  getPaginationDisabled(length: number): string {
    if (length <= 10) {
      return 'pagination-disabled';
    }
  }

  disabledOff(data: any[]) {
    data.forEach(function (element) {
      if (element.default === true) {
        element.default = false;
      }
    });
  }

  newDisabledOn(data: any[], indexFilter: number) {
    data[indexFilter].default = true;
  }

  findHeadIndex(elemId: string): number {
    const headIndex = this.headElements.findIndex(item => item.id === elemId);
    return headIndex;
  }

  findFilterIndex(headIndex: number, elemId: string) {
    const filterIndex = this.headElements[headIndex].dataFilter.findIndex(item => item.id === elemId);
    return filterIndex;
  }

  applyFilterGestip(filter: string, elemId: string, indexFilter: number) {
    this.filteredDataTableGestip = this.completeDataTableGestip;

    this.applyFilter(filter, elemId, indexFilter);

    if (this.filterDestinataireElem !== 'Tous') {
      this.filteredDataTableGestip = this.filteredDataTableGestip.filter(item => item.destinataire.libelle === this.filterDestinataireElem);
    }
    if (this.filterStatusTransfertElem !== 'Tous') {
      if (this.filterStatusTransfertElem === 'OK') {
        this.filteredDataTableGestip = this.filteredDataTableGestip.filter(item => item.statutTransferCftOk === true);
      } else if (this.filterStatusTransfertElem === 'KO') {
        this.filteredDataTableGestip = this.filteredDataTableGestip.filter(item => item.statutTransferCftOk === false);
      }
    }
    if (this.filterStatutArlElem !== 'Tous') {
      if (this.filterStatutArlElem === 'A') {
        this.filteredDataTableGestip = this.filteredDataTableGestip.filter(item => item.diagnostic !== null && item.diagnostic.etat === 'A');
      } else if (this.filterStatutArlElem === 'R') {
        this.filteredDataTableGestip = this.filteredDataTableGestip.filter(item => item.diagnostic !== null && item.diagnostic.etat === 'R');
      } else if (this.filterStatutArlElem === 'null') {
        this.filteredDataTableGestip = this.filteredDataTableGestip.filter(item => item.diagnostic === null);
      }
    }
  }

  applyFilterBpij(filter: string, elemId: string, indexFilter: number) {
    this.filteredDataTableBpij = this.completeDataTablebpij;

    this.applyFilter(filter, elemId, indexFilter);

    if (this.filterEmetteurElem !== 'Tous') {
      this.filteredDataTableBpij = this.filteredDataTableBpij.filter(item => item.emetteur.libelle === this.filterEmetteurElem);
    }
  }

  applyFilter(filter: string, elemId: string, indexFilter: number) {
    switch (elemId) {
      case 'destinataire': {
        this.filterDestinataireElem = filter;
        this.disabledOff(this.headElements[this.findHeadIndex(elemId)].dataFilter);
        this.newDisabledOn(this.headElements[this.findHeadIndex(elemId)].dataFilter, indexFilter);
        this.hasfilterDestinataire = true;
        if (filter === 'Tous') {
          this.hasfilterDestinataire = false;
        }
        break;
      }
      case 'statutTransferCftOk': {
        this.filterStatusTransfertElem = filter;
        this.disabledOff(this.headElements[this.findHeadIndex(elemId)].dataFilter);
        this.newDisabledOn(this.headElements[this.findHeadIndex(elemId)].dataFilter, indexFilter);
        this.hasfilterStatusTransfer = true;
        if (filter === 'Tous') {
          this.hasfilterStatusTransfer = false;
        }
        break;
      }
      case 'statutArl': {
        this.filterStatutArlElem = filter;
        this.disabledOff(this.headElements[this.findHeadIndex(elemId)].dataFilter);
        this.newDisabledOn(this.headElements[this.findHeadIndex(elemId)].dataFilter, indexFilter);
        this.hasfilterStatusArl = true;
        if (filter === 'Tous') {
          this.hasfilterStatusArl = false;
        }
        break;
      }
      case 'emetteur': {
        this.filterEmetteurElem = filter;
        this.disabledOff(this.headElements[this.findHeadIndex(elemId)].dataFilter);
        this.newDisabledOn(this.headElements[this.findHeadIndex(elemId)].dataFilter, indexFilter);
        this.hasfilterEmetteur = true;
        if (filter === 'Tous') {
          this.hasfilterEmetteur = false;
        }
        break;
      }
    }
  }

  setDataTableGestip() {
    this.mdbTable.setDataSource(this.dataGestip);
    this.filteredDataTableGestip = this.mdbTable.getDataSource();
    this.previousTableItems = this.mdbTable.getDataSource();
    this.completeDataTableGestip = this.filteredDataTableGestip;
    this.mdbTablePagination.ofKeyword = 'sur';

    this.canRefresh = false;
  }

  setDataTableBpij() {
    this.mdbTable.setDataSource(this.dataBpij);
    this.filteredDataTableBpij = this.mdbTable.getDataSource();
    this.previousTableItems = this.mdbTable.getDataSource();
    this.completeDataTablebpij = this.filteredDataTableBpij;
    this.mdbTablePagination.ofKeyword = 'sur';
    this.canRefresh = false;
  }

  loadDestinataireFilter(dataTable: Edi.FluxEdiGestipViewModel[]) {
    const datas = [];
    dataTable.forEach(function (item) {
      if (!datas.includes(item.destinataire.libelle)) {
        datas.push({ id: item.destinataire.libelle, default: false });
      }
    });

    //création de l'array filtre final SANS doublons
    const data = Array.from(new Set(datas.map(a => a.id)))
      .map(id => {
        return datas.find(a => a.id === id);
      });
    data.push({ id: 'Tous', default: true });
    const index = this.headElements.findIndex(item => item.id === 'destinataire');
    this.headElements[index].dataFilter = data;
  }

  loadStatutArlFilter(dataTable: Edi.FluxEdiGestipViewModel[]) {
    const datas = [];
    dataTable.forEach(item => {
      if (item.diagnostic === null && !datas.includes('null')) {
        datas.push({ id: 'null', color: this.getArlStatutClass(item.diagnostic), default: false });
      }
      else if (!datas.includes(item.diagnostic.etat)) {
        datas.push({ id: item.diagnostic.etat, color: this.getArlStatutClass(item.diagnostic), default: false });
      }
    });

    const data = Array.from(new Set(datas.map(a => a.id)))
      .map(id => {
        return datas.find(a => a.id === id);
      });
    data.push({ id: 'Tous', default: true });
    const index = this.headElements.findIndex(item => item.id === 'statutArl');
    this.headElements[index].dataFilter = data;
  }

  loadStatutCftFilter(dataTable: Edi.FluxEdiGestipViewModel[]) {
    const statutList = [];
    dataTable.forEach(item => {
      if (!statutList.includes(item.statutTransferCftOk ? 'OK' : 'KO')) {
        statutList.push({ id: item.statutTransferCftOk ? 'OK' : 'KO', color: this.getFilterCftStatutClassColor(item.statutTransferCftOk ? 'OK' : 'KO'), default: false });
      }
    });

    const data = Array.from(new Set(statutList.map(a => a.id)))
      .map(id => {
        return statutList.find(a => a.id === id);
      });
    data.push({ id: 'Tous', color: this.getFilterCftStatutClassColor('Tous'), default: true });
    const index = this.headElements.findIndex(item => item.id === 'statutTransferCftOk');
    this.headElements[index].dataFilter = data;
  }

  loadEmetteurFilter(dataTable: Edi.FluxEdiBpijViewModel[]) {
    const datas = [];
    dataTable.forEach(function (item) {
      if (!datas.includes(item.emetteur.libelle)) {
        datas.push({ id: item.emetteur.libelle, default: false });
      }
    });

    const data = Array.from(new Set(datas.map(a => a.id)))
      .map(id => {
        return datas.find(a => a.id === id);
      });
    data.push({ id: 'Tous', default: true });
    const index = this.headElements.findIndex(item => item.id === 'emetteur');
    this.headElements[index].dataFilter = data;
  }

  loadData() {
    this.resetDataTable();
    this.mdbTablePagination.firstPage();
    if (!this.fromCalendar.date || !this.toCalendar.date)
      return;

    if (this.fluxGestip) {
      this.loadDataTableGestip(this.fromCalendar.date, this.toCalendar.date);
    } else {
      this.loadDataTableBpij(this.fromCalendar.date, this.toCalendar.date);
    }
  }

  loadDataTableGestip(dateFrom: Moment, dateTo: Moment) {
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.fluxediService.getSendedFluxEdiGestipFromDate(dateFrom.toDate().toDateString(), dateTo.toDate().toDateString());
        }),
        map(data => {
          data.forEach((item) => {
            item.dateReceptionArl !== null ? item.dateReceptionArlToString = this.datepipe.transform(item.dateReceptionArl, this.dateFormater) : item.dateReceptionArlToString = 'Non reçu';
            item.documentsEdi[0].dateReceptionCr !== null ? item.dateReceptionCrZeroToString = this.datepipe.transform(item.documentsEdi[0].dateReceptionCr, this.dateFormater) : item.dateReceptionCrZeroToString = 'Non reçu';
          })
          return data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(data => {
        this.dataGestip = data;
        this.loadHeadElementsGestip();
        this.setDataTableGestip();
        this.loadDestinataireFilter(this.dataGestip);
        this.loadStatutArlFilter(this.dataGestip);
        this.loadStatutCftFilter(this.dataGestip);
        this.loaderDisplayed = false;
      });
  }

  loadDataTableBpij(dateFrom: Moment, dateTo: Moment) {
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.fluxediService.getReceivedFluxEdiBpij(dateFrom.format("DD/MM/YYYY"), dateTo.format("DD/MM/YYYY"));
        }),
        map(data => {
          return data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(data => {
        this.dataBpij = data;
        this.loadHeadElementsBpij();
        this.setDataTableBpij();
        this.loadEmetteurFilter(this.dataBpij);
      });
  }

  loadHeadElementsGestip() {
    this.headElements = [
      { id: 'libelleArchive', head: 'Nom fichier ZIP', dataFilter: [], filter: false, sortable: false },
      { id: 'destinataire', head: 'Destinataire', dataFilter: [], filter: true, sortable: false },
      { id: 'dateReception', head: 'Date réception GESTIP', dataFilter: [], filter: false, sortable: true, toggle: false },
      { id: 'dateEmission', head: 'Date transfert', dataFilter: [], filter: false, sortable: true, toggle: false },
      { id: 'statutTransferCftOk', head: 'Statut transfert', dataFilter: [], filter: true, sortable: false },
      { id: 'dateReceptionArlToString', head: 'Date réception ARL', dataFilter: [], filter: false, sortable: true, toggle: false },
      { id: 'statutArl', head: 'Statut ARL', dataFilter: [], filter: true, sortable: false },
      { id: 'dateReceptionCrZeroToString', head: 'Date réception CR', dataFilter: [], filter: false, sortable: true, toggle: false },
      { id: 'nbOperation', head: 'Nb opérations', dataFilter: [], filter: false, sortable: false },
    ];
  }

  loadHeadElementsBpij() {
    this.headElements = [
      { id: 'identification', head: 'Nom fichier ZIP', dataFilter: [], filter: false, sortable: false },
      { id: 'emetteur', head: 'Emetteur', dataFilter: [], filter: true, sortable: false },
      { id: 'dateReception', head: 'Date réception BPIJ', dataFilter: [], filter: false, sortable: true, toggle: false },
      { id: 'nbBpij', head: 'Nombre BPIJ', dataFilter: [], filter: false, sortable: false },
    ];
  }

  private resetDataTable() {
    this.mdbTablePagination.firstPage();
    this.hasfilterDestinataire = this.hasfilterEmetteur = this.hasfilterStatusArl = this.hasfilterStatusTransfer = false;

  }
  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }


}
