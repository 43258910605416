import { Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appTooltipSurveillance]'
})
export class TooltipSurveillanceDirective {
  domElement: any;

  constructor(private elementRef: ElementRef) {
    this.domElement = this.elementRef.nativeElement;
  }

  @HostListener('mousemove', ['$event']) onMouseMove(event) {
    this.xPosition(event.pageX);
    this.yPosition(event.pageY);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.xPosition(null);
    this.yPosition(null);
  }

  private xPosition(xPos: number) {
    if (xPos !== null) {
      this.domElement.children[0].style.left = xPos - 65 + 'px';
    } else {
      this.domElement.children[0].style.left = '';
    }
  }

  private yPosition(yPos: number) {
    if (yPos !== null) {
      this.domElement.children[0].style.top = yPos + 30 + 'px';
    } else {
      this.domElement.children[0].style.top = '';
    }
  }
}
