import { extend } from 'jquery';
import * as moment from 'moment';
import { ColumnData } from '@app/_core/view-models/common/column-data';
import { HeadElementViewData } from '@app/_core/view-models/common/head-element-view-data';
import ModelsEdi = Models.Edi;
import { NirSirenValidationHelper } from '../../../../helpers';

export class GestipOperationsTableViewModel {
  private _gestipOperations: ModelsEdi.GestipOperationViewModel[];
  private _tableHeader: HeadElementViewData[];
  private _headerTechnicalIds = [
    "institutionPrevIdentity",
    "gestipIdentity",
    "archiveFileLabel",
    "arlReceiptDate",
    "arlState",
    "crReceiptDate",
    "crDiagnosticState",
    "libelleDiagnostic"
  ];

  constructor() {
    this.initHeader();
  }

  public setGestipOperations(gestipOperations: ModelsEdi.GestipOperationViewModel[]) {
    this._gestipOperations = gestipOperations;

    this._gestipOperations.forEach(model => {
      model.onError = (model.crReceiptDate !== null && !model.crDiagnosticValidated) || (model.arlReceiptDate !== null && !model.arlValidated);
      model.crDiagnosticState = model.crReceiptDate !== null ? model.crDiagnosticValidated ? "Accepté" : "Refusé" : "";
      model.arlState = model.arlReceiptDate !== null ? model.arlValidated ? "Accepté" : "Refusé" : "";
      if (model.diagnostic != null) {
        model.libelleDiagnostic = model.diagnostic.libelle;
      }
    });
  }

  public get dataTable(): ModelsEdi.GestipOperationViewModel[] {
    return this._gestipOperations;
  }

  public getHeader(withTechnicalData: boolean): HeadElementViewData[] {
    if (withTechnicalData) return this._tableHeader;

    return this._tableHeader.filter(head => {
      return !(this._headerTechnicalIds.includes(head.id));
    });
  }

  private initHeader() {
    this._tableHeader = [
      new HeadElementViewData({ id: "receiptDate", head: "Date réception Prest'iPrev", class: "white-space date" }),
      new HeadElementViewData({ id: 'societyIdentity', head: 'SIREN', class: "siret", format: NirSirenValidationHelper.formatSiret }),
      new HeadElementViewData({ id: 'societyBusinessName', head: 'Raison sociale', class: "business-name" }),
      new HeadElementViewData({ id: 'employeeLastName', head: 'Nom', class: "ellipsis" }),
      new HeadElementViewData({ id: 'employeeFirstName', head: 'Prénom', class: "ellipsis" }),
      new HeadElementViewData({ id: 'nirEmployee', head: 'NIR', class: "nir", format: NirSirenValidationHelper.formatNir }),
      new HeadElementViewData({ id: 'operationType', head: 'Opération', class: "white-space" }),
      new HeadElementViewData({ id: 'coverIdentity', head: 'Id couverture', class: "white-space" }),
      new HeadElementViewData({ id: 'typePe', head: 'TypePE', class: "white-space" }),
      new HeadElementViewData({ id: 'coverBeginDate', head: 'Date début couverture', class: "white-space" }),
      new HeadElementViewData({ id: 'coverEndDate', head: 'Date fin couverture', class: "white-space" }),
      new HeadElementViewData({ id: 'coverBeginDateRetro', head: 'Date rétroactivité', class: "white-space" }),
      new HeadElementViewData({ id: 'institutionPrevIdentity', head: 'Emetteur / destinatire', class: "white-space" }),
      new HeadElementViewData({ id: 'gestipIdentity', head: 'Id flux', class: "white-space ellipsis" }),
      new HeadElementViewData({ id: 'archiveFileLabel', head: 'Id fichier', class: "white-space ellipsis" }),
      new HeadElementViewData({ id: 'arlReceiptDate', head: 'Date réception ARL', class: "white-space date" }),
      new HeadElementViewData({ id: 'arlState', head: 'Statut ARL', class: this.validatedColor }),
      new HeadElementViewData({ id: 'crReceiptDate', head: 'Date réception CR', class: "white-space date" }),
      new HeadElementViewData({ id: 'crDiagnosticState', head: 'Statut CR opération', class: this.validatedColor }),
      new HeadElementViewData({ id: 'libelleDiagnostic', head: 'Libellé cause rejet', class: "white-space label-cause" })
    ];
  }

  private validatedColor(text: string): string {
    return text === "Accepté" ? "text-valid" : "text-error";
  }

  private warning(onError: boolean): string {
    return onError ? "warning icon-warning" : "warning";
  }
}
