import { Injectable } from "@angular/core";
import DWH = Models.DataWarehouse;
import { StatisticsCrAverageDeliveryTimesViewModel } from "../../../../_core/view-models/datawarehouse/edi-flow/view-models-from-models/statistics-cr-average-delivery-times-view-model";

@Injectable({
  providedIn: 'root'
})

export class PeriodCrReceivedService {
  getViewModel(model: DWH.EdiFlowsAverageDeliveryTimesModel[]): StatisticsCrAverageDeliveryTimesViewModel[] {
    let vm: StatisticsCrAverageDeliveryTimesViewModel[];

    vm = model.map(m => new StatisticsCrAverageDeliveryTimesViewModel(m));

    return vm;
  }
}
