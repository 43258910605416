import { Component, Output, EventEmitter, ViewChild, Renderer2, AfterViewInit, ElementRef, HostListener, Input, AfterContentChecked } from '@angular/core';
import { Moment } from 'moment';
import * as moment from 'moment';
import { MatCalendar } from '@angular/material';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements AfterViewInit, AfterContentChecked {
  static readonly TODAY = moment().startOf("days");
  static readonly YESTERDAY = moment().startOf("days").subtract(1, "days");
  static readonly DATE_REQUIRED = "Date requise";
  static readonly DATE_INVALID = "Date invalide";
  static readonly DATE_OVER_MAX = "Date plus grande qu'aujourd'hui";
  static readonly DATE_UNDER_MIN = "Date plus petite que min";

  private mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]

  @ViewChild('calendarChild', { static: false })
  public calendarChild: MatCalendar<Moment>;
  @ViewChild('inputDate', { static: false })
  inputDateRef: ElementRef;
  input: HTMLInputElement;

  @Output('error')
  errorChange: EventEmitter<string> = new EventEmitter();
  @Output()
  dateChange: EventEmitter<Moment> = new EventEmitter();


  private _maxDate: Moment = null;
  private _minDate: Moment = null;
  private _date: Moment = null;

  private _minCalendarDate: Moment = null;
  get minCalendarDate(): Moment {
    return this._calendarRangeBound ? this.minDate : this._minCalendarDate;
  }

  private _maxCalendarDate: Moment = null;
  get maxCalendarDate(): Moment {
    return this._calendarRangeBound ? this.maxDate : this._maxCalendarDate;
  }
  private _calendarRangeBound: boolean = true;

  public btnNextDayDisabled: boolean = false;
  public btnPrevDayDisabled: boolean = false;
  public hideMatCalendar: boolean = true;

  @Input()
  set date(val: Moment) {
    this._date = val; //moment is mutable, create copy
    if (this._date && this.input != null)
      this.input.value = this._date.format("DD/MM/YYYY");

    this.check();
    this.checkArrows();

    this.dateChange.emit(this.date);

    if (this.calendarChild)
      this.calendarChild.activeDate = this._date ? this.date : CalendarComponent.TODAY;
  }
  get date() {
    return this._date;
  }

  @Input()
  set maxDate(val: Moment) {
    this._maxDate = val;
    this.check();
    this.checkArrows();
  }
  get maxDate() {
    return this._maxDate;
  }

  @Input()
  set minDate(val: Moment) {
    this._minDate = val;
    this.check();
    this.checkArrows();
  }
  get minDate() {
    return this._minDate;
  }

  private _isRequired: boolean;
  @Input()
  set isRequired(b: boolean) {
    this._isRequired = b;
    this.check();
  }
  get isRequired(): boolean {
    return this._isRequired;
  }

  @Input()
  error: string = "";

  constructor(private renderer: Renderer2, private eRef: ElementRef) {
  }

  ngAfterViewInit() {
    this.input = this.inputDateRef.nativeElement;
    if (this._date)
      this.input.value = this._date.format("DD/MM/YYYY");

    fromEvent(this.input, 'keyup')
      .pipe(debounceTime(200))
      .subscribe(this.onKey.bind(this));
  }

  ngAfterContentChecked() {
    this.checkArrows();
  }

  private getInput(): string {
    if (this.input)
      return this.input.value;

    return "";
  }

  private dateFromInput(): Moment {
    if (this.getInput().length < 10)
      return null;

    var date = moment(this.getInput(), "DD/MM/YYYY");
    return date.isValid() ? date : null;
  }

  public check(): void {
    var oldErr = this.error;

    this.error = "";
    if (!this._date && this.input !== document.activeElement) {
      var input = this.getInput();
      if (this.isRequired && input.length === 0)
        this.error = CalendarComponent.DATE_REQUIRED;
      else if (input.length > 0)
        this.error = CalendarComponent.DATE_INVALID;
    }
    else if (this._date && this.minDate && this._date < this.minDate)
      this.error = CalendarComponent.DATE_UNDER_MIN;
    else if (this._date && this.maxDate && this._date > this.maxDate)
      this.error = CalendarComponent.DATE_OVER_MAX;

    if (oldErr != this.error)
      this.errorChange.emit(this.error);
  }

  private checkArrows() {
    this.btnNextDayDisabled = this.date == null || (this._maxDate != null && this._date >= this._maxDate);
    this.btnPrevDayDisabled = this.date == null || (this._minDate != null && this._date <= this._minDate);
  }

  public setCalendarBounds(min: Moment, max: Moment) {
    this._calendarRangeBound = false;
    this._minCalendarDate = min;
    this._maxCalendarDate = max;
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (event.target === this.input
      || !this.eRef.nativeElement.contains(event.target))
      this.hideMatCalendar = true;
  }

  onToggle(event) {
    this.hideMatCalendar = !this.hideMatCalendar;
  }

  onKey(event: KeyboardEvent) {
    if (this.getInput().length == 10 || this.getInput() == "")
      this.date = this.dateFromInput();
  }

  onBlur() {
    this.date = this.dateFromInput();
  }

  prevDay() {
    this.date = moment(this.date).add(-1, 'days');
  }

  nextDay() {
    this.date = moment(this.date).add(1, 'days');
  }
}
