import PrestiPrev = Models.PrestiPrev;

export class NotificationsFormModel {
  notifications: NotificationsViewModel[];

  constructor() {
    this.notifications = [];
  }

}

export class NotificationsViewModel {
  userNotification: PrestiPrev.UserNotificationViewModel;
  checked: boolean;

  constructor(userNotification: PrestiPrev.UserNotificationViewModel) {
    this.userNotification = userNotification;
    this.checked = false;
  }
}
